import { useTranslation } from 'react-i18next';
import { useStore } from 'effector-react';

import { Container } from './styles';
import { type ReactFCC } from 'types/react';
import { Modal } from 'ui-kit/components/Modal';
import { addUserModalStore } from 'stores/modals';
import { Select } from 'ui-kit/components/Select/Select';
import { Row } from 'components/wrappers/grid/FlexWrapper';
import { StyledTextInput } from 'components/common/inputs/StyledInput';
import { PrimaryButton, SecondaryButton } from 'components/common/buttons/SimpleButton';

export const AddUserModal: ReactFCC = () => {
    const { modal, closeModal } = addUserModalStore;
    const { t } = useTranslation();

    const [_, { title }] = useStore(modal);

    const handleClose = () => {
        closeModal();
    };

    return (
        <Modal
            visible
            customFooter={
                <Row justifyEnd gap="16px">
                    <SecondaryButton>Cancel</SecondaryButton>
                    <PrimaryButton>Invite user</PrimaryButton>
                </Row>
            }
            title={title}
            onClose={handleClose}
        >
            <Container>
                <StyledTextInput placeholder="Enter first user name" title="First name" />
                <StyledTextInput placeholder="Enter last user name" title="Last name" />
                <StyledTextInput placeholder="example@gmail.com" title="Email" />
                <div>{t('Role')}</div>
                <Select
                    options={[
                        { label: 'Admin', value: 'admin' },
                        { label: 'User', value: 'user' }
                    ]}
                />
            </Container>
        </Modal>
    );
};

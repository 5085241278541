import { sample } from 'effector';
import { stripeEffects } from 'stores/stripe';
import { userEffects } from 'stores/user';

const { payWithIdeal, payWithVisaCard } = stripeEffects;
const { getMe } = userEffects;

sample({
    clock: [payWithIdeal.doneData, payWithVisaCard.doneData],
    target: getMe
});

import type { ReactFCC } from 'types/react';
import { useStore } from 'effector-react';

import { alertsStores } from 'stores/alerts';

import { ContentText, StyledNotification, Wrapper } from 'components/modals/Notification/styles';
import { successImgDiameter } from 'components/modals/Notification/constants';
import { MarginWrapper } from 'components/wrappers/MarginWrapper';
import { CustomImage } from 'components/common/imgComponents/CustomImg/styles';

import errorImg from './static_assets/error_notification_icon.svg';
import successImg from './static_assets/success_notification_icon.svg';

export const NotificationModal: ReactFCC = () => {
    const { type, message } = useStore(alertsStores.alertStore);

    return (
        <Wrapper active>
            <StyledNotification active>
                {type === 'error' ? (
                    <CustomImage height="23px" src={errorImg} width={successImgDiameter} />
                ) : (
                    <CustomImage height="20px" src={successImg} width={successImgDiameter} />
                )}
                <MarginWrapper marginLeft="10px">
                    <ContentText error={type === 'error'}>{message}</ContentText>
                </MarginWrapper>
            </StyledNotification>
        </Wrapper>
    );
};

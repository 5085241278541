import { type ReactNode, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useStore } from 'effector-react';

import copyIcon from './static_assets/copy_icon.svg';
import errorImg from './static_assets/error_small.svg';
import arrowGrayIcon from './static_assets/arrow_gray_icon.svg';

import { errorMessage } from './constants';
import { HOME_LINK } from 'constants/routes';
import { type ReactFCC } from 'types/react';
import { Modal } from 'ui-kit/components/Modal';
import { CopyErrorTextButton, Title, Subtitle } from './styles';
import { P } from 'components/common/typography/titles/P';
import { Row } from 'components/wrappers/grid/FlexWrapper';
import { stripeIdealErrorModalStore } from 'stores/modals';
import { hoveredTextColor } from 'constants/styles/colors';
import { MarginWrapper } from 'components/wrappers/MarginWrapper';
import { ContentWrapper } from 'components/wrappers/ContentWrapper';
import { PrimaryButton } from 'components/common/buttons/SimpleButton';
import { CustomImage } from 'components/common/imgComponents/CustomImg/styles';
import { arrowIconHeight, arrowIconWidth } from 'components/common/Dropdown/constants';

const customHeader: ReactNode = (
    <Row alignCenter>
        <MarginWrapper marginRight="12px">
            <CustomImage height="32px" src={errorImg} width="32px" />
        </MarginWrapper>
        <Title>Something went wrong!</Title>
    </Row>
);

export const StripeIdealErrorModal: ReactFCC = () => {
    const { modal, closeModal } = stripeIdealErrorModalStore;
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { visible } = useStore(modal);

    const [isOpenErrorMessage, setIsOpenErrorMessage] = useState(false);

    const handleCloseModal = () => {
        closeModal();
        setIsOpenErrorMessage(false);
    };

    const handleToggleErrorMessage = () => {
        setIsOpenErrorMessage(!isOpenErrorMessage);
    };

    const goBackToHome = () => {
        navigate(HOME_LINK);
    };

    return (
        <Modal customHeader={customHeader} visible={visible} width="410px" onClose={handleCloseModal}>
            <MarginWrapper marginBottom="8px">
                <Subtitle>{t('stripeIdealErrorModal.subtitle1')}</Subtitle>
            </MarginWrapper>
            <Subtitle>
                {t('stripeIdealErrorModal.subtitle2')}
                {': '}
                <b>support@mail.com</b>
            </Subtitle>
            <Row alignCenter pointer marginTop="16px" width="fit-content" onClick={handleToggleErrorMessage}>
                <MarginWrapper marginRight="6px">
                    <P color={hoveredTextColor}>{t('stripeIdealErrorModal.showFullError')}</P>
                </MarginWrapper>
                <CustomImage
                    alt="dropdown arrow icon"
                    height={arrowIconHeight}
                    rotate={isOpenErrorMessage ? 180 : 0}
                    src={arrowGrayIcon}
                    width={arrowIconWidth}
                />
            </Row>
            {isOpenErrorMessage && (
                <ContentWrapper backgroundColor="#FAE2E2" borderRadius="3px" padding="12px">
                    <P color="#E76C6C">{errorMessage}</P>
                </ContentWrapper>
            )}
            <Row justifyBetween marginTop="30px">
                <CopyErrorTextButton>
                    <MarginWrapper marginRight="8px">
                        <CustomImage height="18px" src={copyIcon} width="14px" />
                    </MarginWrapper>
                    {t('stripeIdealErrorModal.copyErrorText')}
                </CopyErrorTextButton>
                <PrimaryButton onClick={goBackToHome}>{t('back')}</PrimaryButton>
            </Row>
        </Modal>
    );
};

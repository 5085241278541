import { headingsFontFamilyMixin } from 'constants/styles/fonts';
import { disableDefaultHStyleMixin } from 'constants/styles/mixins';
import styled from 'styled-components';
import { primaryBackgroundColor, secondaryGrey } from '../../../constants/styles/colors';

export const Price = styled.div`
    width: fit-content;
    height: fit-content;
    font-family: 'Baloo 2';
    font-weight: 500;
    font-size: 24px;
    color: ${primaryBackgroundColor};
`;

export const InfoLabel = styled.span`
    font-family: Montserrat;
    font-size: 14px;
    color: ${secondaryGrey};
    margin-bottom: 10px;
`;

export const InfoText = styled.span`
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 500;
`;

export const Title = styled.h3`
    ${disableDefaultHStyleMixin};
    ${headingsFontFamilyMixin};
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 44px;
    max-width: 80%;
`;

import { useCallback, useEffect, useMemo } from 'react';
import type { MultiValue, SingleValue } from 'react-select';
import { type SelectValue, Select } from 'ui-kit/components/Select/Select';
import { hideOrShowEvents, hideOrShowStores } from 'stores/builder/hideOrShowItems';
import type { Fields, RadioValue } from 'types/builder/interface';
import { useStore } from 'effector-react';
import { editorStores } from 'stores/builder/editor';
import { selectOptions } from './constants';
import { getDefaultShowOrHideString } from './utils';
import { EditorLocationEnum, FieldTypesEnum } from 'constants/builder/enums';
import { Input } from 'ui-kit/components/inputs/Input';
import { Container, Content, Hr, Item, MultiselectContainer, SelectContainer, Title } from './styles';
import { Answer } from './Answer';
import type { ReactFCC } from 'types/react';

interface Props {
    variationItems: RadioValue[];
    formValues?: { [key: string]: string };
    blockId?: string;
    selectedValue: string;
    clause?: Fields;
}

export const Logic: ReactFCC<Props> = ({ variationItems, formValues, selectedValue, clause }) => {
    const {
        setShowOrHide,
        setMultiSelectValues,
        initializeShowOrHide,
        initializeClauseOrBlock,
        initializeMultiSelectValues
    } = hideOrShowEvents;
    const showOrHide = useStore(hideOrShowStores.showOrHide);
    const location = useStore(editorStores.location);

    const isAddingClauseVariations = useMemo(() => location === EditorLocationEnum.Clause, [location]);

    const handleSelectShowOrHide = (value: string, key: string) => {
        setShowOrHide({ key, value });
    };

    const handleSelectIndex = (
        key: string,
        value: MultiValue<{
            value: string;
            label: string;
        }>
    ) => {
        setMultiSelectValues({ key, value });
    };

    const clauseOptions = useMemo(
        () =>
            clause?.content.map((item, index) => ({
                value: index.toString(),
                label: item.isDefault ? 'Default' : `Alternative ${index}`
            })) || [],
        [clause]
    );

    const initialize = useCallback(() => {
        initializeShowOrHide(
            variationItems.map((item, index) => ({
                key: `radio_${index + 1}_text`,
                value: !isAddingClauseVariations
                    ? item.value.value || (selectedValue === FieldTypesEnum.Radio && index === 0 ? 'show' : 'hide')
                    : item.value.value || 'show'
            }))
        );
        initializeClauseOrBlock(
            variationItems.map((_, index) => ({
                key: `radio_${index + 1}_text`,
                value: 'clause'
            }))
        );
        initializeMultiSelectValues(
            variationItems.map(({ value }, index) => {
                const defaultMultiSelectValues =
                    clauseOptions.filter(option =>
                        value.value
                            .split('-')[0]
                            .split(',')
                            .some(index => option.value === String(index))
                    ) || [];

                return {
                    key: `radio_${index + 1}_text`,
                    value: defaultMultiSelectValues
                };
            })
        );
    }, [
        clauseOptions,
        initializeClauseOrBlock,
        initializeMultiSelectValues,
        initializeShowOrHide,
        isAddingClauseVariations,
        selectedValue,
        variationItems
    ]);

    useEffect(() => {
        initialize();
    }, [initialize]);

    return (
        <Container>
            <Hr />
            {showOrHide.map((item, index) => {
                const showOrHideDefaultValue = getDefaultShowOrHideString(item.value, isAddingClauseVariations);

                const defaultMultiSelectValues = clauseOptions.filter(option =>
                    item.value
                        .split('-')[0]
                        .split(',')
                        .some(index => option.value === String(index))
                );

                return (
                    <Content key={index}>
                        <Item>
                            <Title>
                                <b>IF</b> the answer to:
                            </Title>
                            <Answer text={formValues?.value} />
                        </Item>
                        <Item>
                            <Title>IS:</Title>
                            <Answer text={formValues?.[`radio_${index + 1}_text`]} />
                            <Title>THEN:</Title>
                            <SelectContainer>
                                <Select
                                    defaultValue={showOrHideDefaultValue}
                                    options={selectOptions}
                                    onChange={value => {
                                        const newValue = value as SingleValue<SelectValue>;
                                        handleSelectShowOrHide(newValue?.label || '', `radio_${index + 1}_text`);
                                    }}
                                />
                            </SelectContainer>
                            {isAddingClauseVariations ? (
                                <Input disabled type="text" value="section" />
                            ) : (
                                <Input disabled type="text" value="article" />
                            )}
                            {isAddingClauseVariations && (
                                <MultiselectContainer key={defaultMultiSelectValues.toString()}>
                                    <Select
                                        isMulti
                                        defaultValue={defaultMultiSelectValues}
                                        isClearable={false}
                                        options={clauseOptions}
                                        width="172px"
                                        onChange={value => {
                                            const newValue = value as MultiValue<SelectValue>;
                                            handleSelectIndex(`radio_${index + 1}_text`, newValue);
                                        }}
                                    />
                                </MultiselectContainer>
                            )}
                        </Item>
                    </Content>
                );
            })}
        </Container>
    );
};

import { type Control, type FieldValues, useController } from 'react-hook-form';
import { type ChangeEvent, type RefObject, useCallback } from 'react';

import { type ReactFCC } from 'types/react';
import { type InputProps, Input } from '../Input';

export interface FormInputProps {
    titleColor?: string;
    autoComplete?: string;
    onInputDraftChange?: (e: ChangeEvent<HTMLInputElement>) => void;
    type?: InputProps['type'];
    description?: string;
    defaultValue?: string;
    control: Control<FieldValues>;
    disabled?: boolean;
    name: string;
    placeholder?: string;
    title?: string;
}

export const FormInput: ReactFCC<FormInputProps> = ({
    name,
    defaultValue,
    disabled,
    titleColor,
    autoComplete,
    control,
    placeholder,
    onInputDraftChange,
    ...decorationInputProps
}) => {
    const {
        field: { ref, name: cName, value = '', onChange },
        fieldState: { error, isTouched }
    } = useController({
        name,
        control,
        defaultValue
    });

    const handleChange = useCallback(
        (e: ChangeEvent<HTMLInputElement>) => {
            onChange(e);

            // TODO: remove this logic from here
            onInputDraftChange?.(e);
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [onChange]
    );

    return (
        <Input
            isFormInput
            autoComplete={autoComplete}
            disabled={disabled}
            error={error?.message}
            isTouched={isTouched}
            name={cName}
            placeholder={placeholder}
            refik={ref as unknown as RefObject<any>}
            titleColor={titleColor}
            value={value}
            onChange={handleChange}
            {...decorationInputProps}
        />
    );
};

import { type ChangeEvent, useCallback, useEffect, useState } from 'react';

import { useStore } from 'effector-react';
import { useQuery } from 'hooks/useQuery';
import { type ReactFCC } from 'types/react';
import { useNavigate } from 'react-router-dom';
import { Modal } from 'ui-kit/components/Modal';
import { TEMPLATES_LINK } from 'constants/routes';
import { templateModalStore } from 'stores/modals';
import { Input } from 'ui-kit/components/inputs/Input';
import { Button } from 'ui-kit/components/buttons/Button';
import { Column, Row } from 'components/wrappers/grid/FlexWrapper';
import { ownTemplatesEffects, ownTemplatesEvents, ownTemplatesStores } from 'stores/ownTemplates';
import { blockEvents } from 'stores/builder/block';
import { questionnaireEvents } from 'stores/builder/questionnaire';

export const TemplateModal: ReactFCC = () => {
    const { modal, closeModal } = templateModalStore;
    const [_, { config, documentName }] = useStore(modal);
    const ownTemplate = useStore(ownTemplatesStores.template);
    const [templateName, setTemplateName] = useState('');
    const location = useQuery<{ ownTemplate?: string }>();
    const isUpdatingTemplate = !!location?.ownTemplate;
    const navigate = useNavigate();

    const createConfig = useCallback(async () => {
        const data = {
            documentName,
            templateName,
            templateType: 'Contract',
            templateCategory: 'Hiring',
            templateConfig: config
        };

        if (location?.ownTemplate) {
            return ownTemplatesEffects.updateTemplate({ id: location.ownTemplate, data });
        }

        return ownTemplatesEffects.createTemplate(data);
    }, [config, documentName, templateName, location]);

    useEffect(() => {
        if (ownTemplate) {
            setTemplateName(ownTemplate.templateName);
        }

        return () => {
            setTemplateName('');
        };
    }, [ownTemplate]);

    useEffect(() => {
        ownTemplatesEffects.createTemplate.doneData.watch(() => {
            templateModalStore.closeModal();
            navigate(TEMPLATES_LINK);
        });

        ownTemplatesEffects.updateTemplate.doneData.watch(() => {
            templateModalStore.closeModal();
            navigate(TEMPLATES_LINK);
            ownTemplatesEvents.resetTemplate();
            blockEvents.resetBlocksEvent();
            questionnaireEvents.resetQuestionnaireEvent();
        });
    }, [navigate]);

    const onChange = (event: ChangeEvent<HTMLInputElement>) => {
        setTemplateName(event.target.value);
    };

    return (
        <Modal
            visible
            title={isUpdatingTemplate ? 'Update template' : 'Save as template'}
            width="530px"
            onClose={closeModal}
        >
            <Column gap="24px">
                <Input
                    placeholder="Enter template name here..."
                    title="Template name"
                    value={templateName}
                    onChange={onChange}
                />
                <Row justifyEnd gap="16px" width="100%">
                    <Button variant="secondary" onClick={() => closeModal()}>
                        Cancel
                    </Button>
                    <Button disabled={!templateName} variant="primary" onClick={createConfig}>
                        {isUpdatingTemplate ? 'Update template' : 'Save template'}
                    </Button>
                </Row>
            </Column>
        </Modal>
    );
};

import styled from 'styled-components';
import { backgroundColor } from 'constants/styles/colors';
import type { Sizes } from 'types/styles';

interface Props extends Sizes {}

export const AppLayout = styled.div<Props>`
    position: relative;
    width: 100%;
    ${({ height }) => height && `height: ${height}`};
    background-color: ${backgroundColor};
`;

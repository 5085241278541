import {
    type CSSObject,
    type DefaultTheme,
    type FlattenInterpolation,
    type Interpolation,
    type InterpolationFunction,
    type ThemedStyledProps,
    css
} from 'styled-components';

export const sizes = {
    desktopLg: 1919,
    desktop: 1439,
    tablet: 1279,
    tabletSmall: 1023,
    phone: 767
};

type KeysType = keyof typeof sizes;
type MediaFunction = <P extends object>(
    first: TemplateStringsArray | CSSObject | InterpolationFunction<ThemedStyledProps<P, DefaultTheme>>,
    ...interpolations: Array<Interpolation<ThemedStyledProps<P, DefaultTheme>>>
) => FlattenInterpolation<ThemedStyledProps<P, DefaultTheme>>;

export default (Object.keys(sizes) as KeysType[]).reduce((acc, label) => {
    acc[label] = (first, ...interpolations) => css`
        @media (max-width: ${sizes[label]}px) {
            ${css(first, ...interpolations)}
        }
    `;

    return acc;
}, {} as { [key in KeysType]: MediaFunction });

import { languages } from 'components/grid/Header/constants';
import { useStore } from 'effector-react';
import { useCallback, useMemo } from 'react';
import { languageEvents, languageStores } from 'stores/language';

export const useLanguage = () => {
    const currentLanguageCode = useStore(languageStores.currentLanguageCode);

    const setLanguage = useCallback((value: string) => {
        languageEvents.changeLanguage(value);
    }, []);

    const language = useMemo(() => languages.find(({ value }) => value === currentLanguageCode), [currentLanguageCode]);

    return { language, setLanguage };
};

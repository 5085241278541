export type messageType = JSX.Element | string;
export const noop: (message: messageType) => void = () => {};
export const message = {
    error: noop,
    info: noop,
    success: noop
};
export type alertType = keyof typeof message;

export interface SetAlertParams {
    id: number;
    type?: alertType;
    message?: messageType;
    active?: boolean;
}

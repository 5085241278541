import type { AddContentEditorStateEventProps, BlockState } from '../types';

export const addContentEditorStateReducer = (
    state: BlockState,
    { blockId, clauseId, editorState, contentId }: AddContentEditorStateEventProps
) => ({
    ...state,
    blocks: state.blocks.map(block =>
        block.id === blockId
            ? {
                  ...block,
                  groups: block.groups.map(item => ({
                      ...item,
                      fields: item.fields.map(field =>
                          field.id === clauseId
                              ? {
                                    ...field,
                                    content: field.content.map(item =>
                                        item.id === contentId ? { ...item, editorState } : item
                                    )
                                }
                              : field
                      )
                  }))
              }
            : block
    )
});

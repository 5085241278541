import { API } from 'api';
import { createEffect, createEvent, createStore, restore } from 'effector';
import type jsPDF from 'jspdf';
import { createNotifyingErrorEffect } from 'utils/store';
import { message } from 'stores/alerts';
import type { ConvertToPdfAndCreateContractParams } from './types';
import { convertHTMLtoPdf } from 'utils/convert-HTML-to-pdf';
import { getMollie } from 'api/services/mollie';

export const makePayment = async (data: any) => {
    window.location.href = data._links.checkout.href;
};

// * events
const savePdf = createEvent<{ pdf: jsPDF; nameOfPdf: string }>();
const resetPdf = createEvent();
const resetFileId = createEvent();
const togglePdfGenerationProcessVisible = createEvent();

// * effects
const uploadContractAsPdf = createNotifyingErrorEffect({
    handler: async (formData: FormData) => await API.contracts.createContract(formData)
});

const convertToPdfAndCreateContract = createEffect({
    handler: async ({ pagesIds, nameOfPdf }: ConvertToPdfAndCreateContractParams) => {
        const fileData = await convertHTMLtoPdf({
            targetNodeIds: pagesIds,
            nameOfPdf: `${nameOfPdf}.pdf`
        });

        if (!fileData) {
            message.error('File was not created');
            return null;
        }

        const { file } = fileData;

        const formData = new FormData();
        formData.append('file', file);
        const { id } = await uploadContractAsPdf(formData);

        const data = await getMollie();
        await makePayment(data);

        return id;
    }
});

// * stores
const pdfData = restore(savePdf, null).reset(resetPdf);
const fileId = restore<string | null>(convertToPdfAndCreateContract.doneData, null).reset(resetFileId);
const isPdfGenerationProcessVisible = createStore(false).on(togglePdfGenerationProcessVisible, state => !state);

// * exports
export const pdfEvents = {
    savePdf,
    resetPdf,
    togglePdfGenerationProcessVisible,
    resetFileId
};

export const pdfEffects = {
    uploadContractAsPdf,
    convertToPdfAndCreateContract
};

export const pdfStores = {
    pdfData,
    fileId,
    isPdfGenerationProcessVisible
};

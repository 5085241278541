import { CardElement, IdealBankElement } from '@stripe/react-stripe-js';
import { API } from 'api';
import { createEffect, createEvent, restore } from 'effector';
import { message } from 'stores/alerts';
import type { StripeParams } from 'stores/payment';
import { pdfEvents } from 'stores/pdf';
import { createNotifyingErrorEffect } from 'utils/store';

interface PaymentParams extends StripeParams {
    clientSecret: string;
}

interface GetCustomerCardsParams {
    cardId?: string;
    customerId?: string;
}

const { togglePdfGenerationProcessVisible } = pdfEvents;

// * events
const activateCustomerCardsById = createEvent<string>();

// * effects
const payWithStripe = createNotifyingErrorEffect({
    handler: async (data: API.CreateOrderDto) => await API.stripe.payWithStripe(data)
});

const createContractWithSubscription = createNotifyingErrorEffect({
    handler: async (data: API.CreateContractWithSubscriptionDto) =>
        await API.stripe.createContractWithSubscription(data)
});

const payWithIdeal = createEffect({
    handler: async ({ stripe, userName, elements, clientSecret }: PaymentParams) => {
        const stripeElement = elements.getElement(IdealBankElement);
        if (stripeElement === null) {
            return;
        }
        return await stripe.confirmIdealPayment(clientSecret, {
            payment_method: {
                ideal: stripeElement,
                billing_details: {
                    name: userName
                }
            },
            return_url: `${process.env.REACT_APP_IDEAL_RETURN_URL}payment-answer`
        });
    }
});

const payWithVisaCard = createEffect({
    handler: async ({ stripe, elements, clientSecret }: PaymentParams) => {
        const stripeElement = elements.getElement(CardElement);
        if (stripeElement === null) {
            return;
        }

        return await stripe.confirmCardPayment(clientSecret, {
            payment_method: {
                card: stripeElement
            }
        });
    }
});

const getCustomerCards = createNotifyingErrorEffect({
    handler: async ({ cardId, customerId }: GetCustomerCardsParams) => {
        if (!customerId) {
            message.error('Error');
            return;
        }

        cardId && activateCustomerCardsById(cardId);

        const paymentMethods = await API.stripe.getPaymentMethods(customerId);
        const stripeCustomer = await API.stripe.getStripeCustomer(customerId);

        return paymentMethods?.data.map(({ id, card }) => ({
            id,
            card,
            isActive: id === stripeCustomer?.invoice_settings?.default_payment_method
        }));
    }
});

// * stores
const customerCards = restore(getCustomerCards.doneData, null).on(activateCustomerCardsById, (state, id) =>
    state?.map(card => ({ ...card, isActive: card.id === id }))
);

// * watchers
// payWithStripe.failData.watch(() => {
//     message.error('Payment failed');
//     togglePdfGenerationProcessVisible();
// });

// createContractWithSubscription.doneData.watch(() => {
//     togglePdfGenerationProcessVisible();
// });

// createContractWithSubscription.failData.watch(() => {
//     togglePdfGenerationProcessVisible();
// });

// * exports
export const stripeEvents = {
    activateCustomerCardsById
};

export const stripeEffects = {
    payWithStripe,
    payWithIdeal,
    payWithVisaCard,
    getCustomerCards,
    createContractWithSubscription
};

export const stripeStores = {
    customerCards
};

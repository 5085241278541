import { sample } from 'effector';
import { message } from 'stores/alerts';
import { companyEffects, companyStores } from 'stores/company';
import { form } from 'stores/forms/updateProfileForm';
import { companyUserChangeModalStore } from 'stores/modals';
import { roleStores } from 'stores/role';
import { userEffects, userEvents } from './index';

const { company } = companyStores;
const { getCompanyUsers } = companyEffects;
const { searchRoleId } = roleStores;
const { changeUserRole, updateUser, updateUserPassword, checkEmailForExistence } = userEffects;
const { logout } = userEvents;

updateUserPassword.doneData.watch(() => {
    logout();
});

checkEmailForExistence.fail.watch(() => {
    message.error('Email already exists');
});

changeUserRole.doneData.watch(() => {
    companyUserChangeModalStore.closeModal();
});

sample({
    clock: updateUser.doneData,
    fn: () => {
        form.reset();
    }
});

sample({
    clock: changeUserRole.doneData,
    source: { company, searchRoleId },
    fn: ({ company, searchRoleId }) => ({
        page: 1,
        limit: 5,
        companyId: company?.id,
        roleId: searchRoleId
    }),
    target: getCompanyUsers
});

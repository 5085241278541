import axios from 'api/axios';

export const createContract = (data: FormData) =>
    axios<{ id: string }>({
        url: '/file',
        data,
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    });

export const getContracts = (query: string) =>
    axios<API.GetManyResponseForContract>({
        method: 'GET',
        url: `contracts?${query}`
    });

export const deleteContract = (id: string) =>
    axios({
        method: 'DELETE',
        url: `contracts/${id}`
    });

// * auth
export const AUTH_LINK = '/auth';
export const REGISTER_LINK = '/register';
export const FORGOT_PASSWORD_LINK = '/auth/forgot-password';
export const RESET_PASSWORD_LINK = '/auth/reset-password';
export const JOIN_TO_COMPANY_LINK = '/join-to-company';
export const VERIFY_ACCOUNT_LINK = '/verify-account';
export const CHECK_EMAIL_LINK = '/auth/forgot-password/check-email';
// TODO: type of provider
export const SOCIAL_MEDIA_LINK = '/social-media/:provider/redirect';
export const PASSWORD_RESET = '/auth/password-reset';
export const PASSWORD_CHANGED = '/auth/password-changed';
export const MOLLIE_SUCCESS = 'localhost:3000/mollie-success';

// * public
export const HOME_LINK = '/';
export const PRICES_LINK = '/prices';
export const PRIVACY_POLICY_LINK = '/privacy-policy';
export const TERM_OF_USE_LINK = '/terms-of-use';
export const THANK_YOU_REGISTRATION_LINK = '/register/thank-you';
export const THANK_YOU_CONTACT_LINK = '/contact/thank-you';
export const KIARA_TEMPLATES_LINK = '/kiara-templates';
export const MAGIC_LINK = '/magic';

// * app
export const DASHBOARD_LINK = '/dashboard';
export const DASHBOARD_TAB_LINK = '/dashboard/:tabLink';
export const ACCOUNT_LINK = '/dashboard/profile';

// * dashboard
export const DOCUMENTS_LINK = '/dashboard/documents';
export const DRAFTS_LINK = '/dashboard/drafts';
export const COMPANY_TEMPLATES_LINK = '/dashboard/company-templates';
export const SETTINGS_LINK = '/dashboard/settings';
export const TEMPLATES_LINK = '/dashboard/templates';
export const KIARA_TEMPLATES_TAB_LINK = '/dashboard/templates?tab=Kiara templates';

export const BUILDER_LINK = '/builder';

export const CONSTRUCTOR_LINK = '/constructor';

export const ERROR_404_LINK = '/404';
export const STRIPE_IDEAL_SUCCESS_LINK = '/payment-answer';

// * anchors
export const TEMPLATES_ANCHOR_ID = 'templates';
export const TEMPLATES_ANCHOR_LINK = `${HOME_LINK}#${TEMPLATES_ANCHOR_ID}`;

export const CONTRACT_FORM_ANCHOR_ID = 'contact-form';
export const CONTRACT_FORM_ANCHOR_LINK = `${HOME_LINK}#${CONTRACT_FORM_ANCHOR_ID}`;

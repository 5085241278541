import { v4 as generateId } from 'uuid';
import type { AddParagraphEventProps, BlockState } from '../types';

export const addParagraphReducer = (
    state: BlockState,
    { paragraphs, clauseId, blockId, contentId }: AddParagraphEventProps
) => {
    const newContent = paragraphs
        .flat()
        .map(item => ({
            ...item,
            modifiedData: item.modifiedData.filter((item: any) => !item?.variationsField?.length)
        }))
        .map(item => ({
            isDefault: true,
            paragraph: item.modifiedData,
            id: contentId
        }))
        .flatMap(item => item.paragraph);

    return {
        ...state,
        blocks: state.blocks.map(block => {
            if (block.id === blockId) {
                return {
                    ...block,
                    groups: block.groups.map(group => ({
                        ...group,
                        fields: group.fields.map(field => {
                            if (field.id === clauseId) {
                                return {
                                    ...field,
                                    content: field.content.map(item => {
                                        if (item.id === contentId) {
                                            const previousParagraphs = [...item.paragraph];

                                            const currentParagraphs = newContent;

                                            const isAdding = previousParagraphs.length < currentParagraphs.length;
                                            const isEditing = currentParagraphs.length === previousParagraphs.length;
                                            const isDeleting = currentParagraphs.length < previousParagraphs.length;

                                            if (isAdding) {
                                                let count = 0;

                                                newContent.forEach(content => {
                                                    if (count < previousParagraphs.length) {
                                                        content.fieldId = previousParagraphs[count].fieldId;
                                                    } else {
                                                        content.fieldId = generateId();
                                                    }
                                                    ++count;
                                                });
                                            }

                                            if (isDeleting) {
                                                let count = 0;

                                                newContent.forEach(content => {
                                                    if (count < currentParagraphs.length) {
                                                        content.fieldId = previousParagraphs[count].fieldId;
                                                    }
                                                    ++count;
                                                });
                                            }

                                            if (isEditing) {
                                                let count = 0;

                                                newContent.forEach(content => {
                                                    content.fieldId = previousParagraphs[count].fieldId;
                                                    ++count;
                                                });
                                            }

                                            return {
                                                ...item,
                                                paragraph: [...newContent]
                                            };
                                        }

                                        return item;
                                    })
                                };
                            }

                            return field;
                        })
                    }))
                };
            }

            return block;
        })
    };
};

import { sample, split } from 'effector';
import { message } from 'stores/alerts';
import { signDraftFormStores } from 'stores/forms/signDraftForm';
import { paymentModalStore, stripeModalStore } from 'stores/modals';
import { paymentEvents, paymentStores } from 'stores/payment';
import { pdfEffects, pdfEvents, pdfStores } from 'stores/pdf';
import { stripeEffects } from 'stores/stripe';
import { userStores } from 'stores/user';

const { convertToPdfAndCreateContract } = pdfEffects;
const { createContractWithSubscription, payWithIdeal, payWithStripe, payWithVisaCard } = stripeEffects;
const { resetFileId, togglePdfGenerationProcessVisible } = pdfEvents;

const loadFile = sample({
    clock: convertToPdfAndCreateContract.doneData,
    source: pdfStores.fileId
});

// TODO @ts-ignore
// split({
//     // @ts-ignore
//     source: loadFile,
//     // @ts-ignore
//     match: paymentStores.paymentType.map(({ type }) => type as string),
//     cases: {
//         fromStripe: paymentEvents.confirmStripePayment,
//         fromContract: paymentEvents.buyContract
//     }
// });

sample({
    clock: paymentEvents.confirmStripePayment,
    source: {
        user: userStores.user,
        emails: signDraftFormStores.form.fields.emails.$field,
        signRequestMessage: signDraftFormStores.form.fields.message.$field,
        paymentType: paymentStores.paymentType,
        pdf: pdfStores.pdfData,
        stripe: paymentStores.stripeParams
    },
    fn: async ({ user, emails, signRequestMessage, paymentType, pdf, stripe }, id) => {
        const signers = emails.value.map(email => ({ email }));
        const message = signRequestMessage.value;
        const { isSign, price, templateId, contractName, previewUrl } = paymentType;

        const { client_secret } = await payWithStripe({
            user: user?.user?.id || '',
            template: templateId,
            amount: price,
            status: 'draft',
            receipt_email: user?.user?.email || '',
            payment_method: [stripe?.isIdeal ? 'ideal' : 'card'],
            fileId: id,
            metadata: { isSign, previewUrl },
            name: contractName,
            message: message || '',
            signers
        });

        if (stripe) {
            const { userName, ...rest } = stripe;

            const response = await (rest.isIdeal
                ? payWithIdeal({ userName, clientSecret: client_secret, ...rest })
                : payWithVisaCard({ clientSecret: client_secret, userName, ...rest }));

            if (response?.error) {
                throw new Error(response.error.message);
            }
        }

        if (!stripe?.isSign && pdf) {
            // pdf.save(`contract.pdf`);
        }

        // togglePdfGenerationProcessVisible();
        // TODO: navigate(`${settingsLink}?tab=profile`)
    }
});

sample({
    clock: paymentEvents.buyContract,
    source: {
        user: userStores.user,
        emails: signDraftFormStores.form.fields.emails.$field,
        signRequestMessage: signDraftFormStores.form.fields.message.$field,
        paymentType: paymentStores.paymentType
    },
    fn: ({ user, emails, signRequestMessage, paymentType }, id) => {
        const signers = emails.value.map(email => ({ email }));
        const message = signRequestMessage.value;
        const { isSign, price, templateId, contractName, previewUrl } = paymentType;

        return {
            contractName,
            metadata: { amount: price, isSign, previewUrl },
            subscriptionId: user?.user?.subscription?.id || '',
            pdfId: id,
            message: message || '',
            signers,
            userId: user?.user?.id || '',
            templateId
        };
    },
    target: createContractWithSubscription
});

sample({
    clock: createContractWithSubscription.doneData,
    source: { pdf: pdfStores.pdfData, paymentType: paymentStores.paymentType },
    fn: ({ pdf, paymentType }) => {
        if (!paymentType?.isSign && pdf) {
            // pdf.save(`contract.pdf`);
            // TODO: navigate(`${settingsLink}?tab=profile`)
        }
    }
});

sample({
    clock: [payWithVisaCard.failData, payWithIdeal.failData],
    fn: () => {
        resetFileId();
        paymentEvents.resetStripeParams();
        message.error('Payment failed');
        pdfEvents.resetPdf();
    }
});

sample({
    clock: [payWithVisaCard.doneData, payWithIdeal.doneData],
    fn: () => {
        paymentEvents.resetPaymentType();
        paymentEvents.resetStripeParams();
        message.success('Payment succeeded');
        stripeModalStore.closeModal();
        paymentModalStore.closeModal();
        pdfEvents.resetPdf();
    }
});

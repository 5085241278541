import { LANGUAGE_LOCAL_STORAGE_NAME } from 'constants/local-storage';
import { createEvent, restore } from 'effector';
import connectLocalStorage from 'effector-localstorage';

const languageLocalStorage = connectLocalStorage(LANGUAGE_LOCAL_STORAGE_NAME);

// * events
const changeLanguage = createEvent<string>();

// * stores
const currentLanguageCode = restore<string>(changeLanguage, languageLocalStorage.init('du'));

// * watchers
currentLanguageCode.watch(languageLocalStorage);

// * exports
export const languageEvents = {
    changeLanguage
};

export const languageStores = {
    currentLanguageCode
};

import { EditorLocationEnum, FieldTypesEnum } from 'constants/builder/enums';
import type { Variations } from 'types/builder/interface';
import { v4 } from 'uuid';
import type { GetSelectedValueProps, GetVariationTitleProps } from './types';

export const getVariationTitle = ({
    isAddingBlockVariations,
    isAddingClauseVariations,
    variationsBlock,
    variationsField
}: GetVariationTitleProps) => {
    if (isAddingClauseVariations || isAddingBlockVariations) {
        if (variationsBlock) {
            return variationsBlock[0][0].title;
        }

        if (variationsField) {
            return variationsField[0][0].title;
        }
    }

    return '';
};

export const getSelectedValue = ({
    location,
    selectedField,
    variationsBlock,
    variationsField
}: GetSelectedValueProps) => {
    if (location === EditorLocationEnum.Field) {
        return selectedField?.type || FieldTypesEnum.Text;
    }

    if (variationsBlock) {
        return variationsBlock[0][0].type;
    }

    if (variationsField) {
        return variationsField[0][0].type;
    }

    if (location === EditorLocationEnum.Block || location === EditorLocationEnum.Clause) {
        return FieldTypesEnum.Radio;
    }

    return FieldTypesEnum.Text;
};

export const getVariationItems = (variationsBlock?: Variations, variationsField?: Variations) => {
    if (variationsBlock) {
        return variationsBlock[0][0].value.map(item => ({
            ...item,
            id: v4()
        }));
    }

    if (variationsField) {
        return variationsField[0][0].value.map(item => ({
            ...item,
            id: v4()
        }));
    }

    return [
        {
            text: '',
            isDefault: true,
            id: v4(),
            value: {
                type: '',
                value: ''
            }
        },
        {
            text: '',
            isDefault: true,
            id: v4(),
            value: {
                type: '',
                value: ''
            }
        }
    ];
};

export const reorder = (list: any[], startIndex: number, endIndex: number) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};

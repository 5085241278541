import { loadStripe } from '@stripe/stripe-js';
import { format } from 'date-fns';

export const monthPrice = 29;

export const yearPrice = 290;

export const publishableKey = process.env.REACT_APP_STRIPE_PUBLIC || '';

export const stripePromise = loadStripe(publishableKey);

export const currentDate = format(new Date(), 'dd/MM/yyyy');

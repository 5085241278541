import styled from 'styled-components';

export const Container = styled.header`
    padding: 14px 24px;
    background-color: white;
    border-bottom: 1px solid #f3f3f3;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export const Notification = styled.button`
    width: 48px;
    height: 48px;
    background-color: white;
    border: 1px solid #d0d3d9;
    border-radius: 8px;
    cursor: pointer;
    position: relative;
    flex-shrink: 0;

    display: flex;
    justify-content: center;
    align-items: center;

    :hover {
        border-color: #8cb1ff;
    }
`;

export const ButtonsContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 16px;
`;

export const SelectContainer = styled.div`
    width: 210px;
`;

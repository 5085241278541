import { API } from 'api';
import { createEffect, createEvent, createStore } from 'effector';
import { createNotifyingErrorEffect } from 'utils/store';

// * events
const resetDraft = createEvent();

// * effects
const createDraft = createEffect({
    handler: async (data: KIARA.UserDraftsRequest) => await API.draft.createDraft(data)
});

const getDrafts = createNotifyingErrorEffect({ handler: async () => API.draft.getDrafts() });

const getDraft = createNotifyingErrorEffect({ handler: async (id: string) => API.draft.getDraft(id) });

const deleteDraft = createNotifyingErrorEffect({ handler: async (id: string) => await API.draft.deleteDraft(id) });

const updateDraft = createEffect({
    handler: async ({
        documentName,
        templateCategory,
        templateConfig,
        templateName,
        templateType,
        id
    }: KIARA.UserDraftsRequest) =>
        await API.draft.updateDraft(
            { documentName, templateCategory, templateConfig, templateName, templateType },
            id || ''
        )
});

// * stores
const drafts = createStore<KIARA.UserDraftsResponse[]>([]);
const draft = createStore<KIARA.UserDraftsResponse | null>(null);

drafts.on(getDrafts.doneData, (_, payload) => payload);
draft.on(getDraft.doneData, (_, payload) => payload);
draft.reset(resetDraft);

// * exports
export const draftsEvents = {
    resetDraft
};

export const draftsEffects = {
    createDraft,
    getDrafts,
    getDraft,
    deleteDraft,
    updateDraft
};

export const draftsStores = {
    drafts,
    draft
};

import i18next, { type TFunction } from 'i18next';
import { initReactI18next } from 'react-i18next';

interface Translation {
    [key: string]: string | Translation;
}
interface TranslationsObject {
    [languageCode: string]: Translation;
}
interface Resources {
    [languageCode: string]: {
        translation: Translation;
    };
}

const createI18nResources = (translations: TranslationsObject): Resources =>
    Object.entries(translations).reduce((result, [lng, translation]) => ({ ...result, [lng]: { translation } }), {});

export const createI18nInstance = (translations: TranslationsObject) => {
    const i18n = i18next.createInstance();
    const lng = localStorage.getItem('language') || 'en';
    i18n.use(initReactI18next).init({
        lng,
        fallbackLng: 'en',
        resources: createI18nResources(translations),
        react: {
            useSuspense: false
        }
    });

    const t = (...args: Parameters<TFunction>) => {
        const t = i18n.getFixedT(i18n.language);

        return t(...args);
    };

    return { i18n, t };
};

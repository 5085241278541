import { paginationLimit } from './constants';
import { sample } from 'effector';
import { areYouSureModalStore, companyTemplateNamingModalStore, deleteCompanyUserModalStore } from 'stores/modals';
import { roleStores } from 'stores/role';
import { userEffects, userEvents } from 'stores/user';
import { companyEffects, companyEvents, companyStores } from './index';

const { company } = companyStores;
const {
    deleteCompanyUser,
    getCompanyUsers,
    inviteUser,
    deleteCompanyTemplate,
    getCompanyTemplates,
    createCompanyTemplate
} = companyEffects;
const { inviteUserEvent, resetCompany } = companyEvents;
const { checkEmailForExistence } = userEffects;
const { searchRoleId } = roleStores;
const { logout } = userEvents;

createCompanyTemplate.doneData.watch(() => {
    companyTemplateNamingModalStore.closeModal();
});
deleteCompanyUser.doneData.watch(() => {
    deleteCompanyUserModalStore.closeModal();
});
logout.watch(() => {
    resetCompany();
});

sample({
    clock: deleteCompanyTemplate.doneData,
    fn: async () => {
        await getCompanyTemplates({ page: 1, limit: 10 });
        areYouSureModalStore.closeModal();
    }
});

sample({
    clock: inviteUserEvent,
    fn: ({ email }) => ({ email }),
    target: checkEmailForExistence
});

sample({
    clock: checkEmailForExistence.doneData,
    source: inviteUserEvent,
    fn: inviteUserPayload => inviteUserPayload,
    target: inviteUser
});

sample({
    clock: deleteCompanyUser.doneData,
    source: { company, searchRoleId },
    fn: ({ company, searchRoleId }) => ({
        page: 1,
        limit: paginationLimit,
        companyId: company?.id,
        roleId: searchRoleId
    }),
    target: getCompanyUsers
});

import { v4 as generateId } from 'uuid';

import { DraggableTypesEnum } from 'constants/builder/enums';
import type { QuestionnaireItems } from '../types';

export const addBlockVariationsHelper = (questions: QuestionnaireItems, blockId: string, data: any[]) => {
    const hasBlockVariations = questions.some(
        item => item.blockId === blockId && item.questionType === DraggableTypesEnum.Block
    );

    if (!hasBlockVariations) {
        const modifiedQuestions = questions.map(question => {
            if (question.blockId === blockId) {
                return {
                    ...question,
                    isShown: false
                };
            }

            return question;
        });

        return [
            ...modifiedQuestions,
            {
                title: data[0][0].title,
                id: generateId(),
                blockId,
                type: data[0][0].type,
                questionType: DraggableTypesEnum.Block,
                description: data[0][0].description,
                variationsBlock: data,
                isShown: true
            }
        ];
    }

    return questions.map(item => {
        if (item.blockId === blockId && item.questionType === DraggableTypesEnum.Block) {
            return {
                ...item,
                title: data[0][0].title,
                type: data[0][0].type,
                description: data[0][0].description,
                variationsBlock: data
            };
        }

        return item;
    });
};

import logoutIconSource from './static_assets/logout_icon.svg';
import { CustomImage } from 'components/common/imgComponents/CustomImg/styles';
import { ContentWrapper } from 'components/wrappers/ContentWrapper';
import { Column } from 'components/wrappers/grid/FlexWrapper';
import { MarginWrapper } from 'components/wrappers/MarginWrapper';
import { useStore } from 'effector-react';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { userEvents, userStores } from 'stores/user';
import {
    Avatar,
    AvatarInfo,
    LogoutButton,
    LogoutText,
    StyledAside,
    StyledNavLink,
    LogoContainer,
    UserInfo,
    Content
} from './styles';
import { KiaraLogo } from 'ui-kit/components/links/KiaraLogo';
import { createLinks, getActiveClassName } from './utils';
import type { ReactFCC } from 'types/react';

export const Aside: ReactFCC = () => {
    const { t } = useTranslation();
    const { user = { firstName: 'Name', lastName: 'Surname' } } = useStore(userStores.user);
    const links = useMemo(() => createLinks(t), [t]);

    const { firstName = 'A', lastName = 'B' } = user;
    const firstNameFirstLetter = firstName.slice(0, 1);
    const lastNameFirstLetter = lastName.slice(0, 1);

    const handleLogout = () => {
        userEvents.logout();
    };

    return (
        <StyledAside>
            <LogoContainer>
                <KiaraLogo />
            </LogoContainer>

            <Content>
                <Column>
                    {links.map(({ href, text, icon, disabled }, index) => (
                        <StyledNavLink
                            key={`link-${index}`}
                            className={getActiveClassName}
                            disabled={disabled}
                            to={href}
                        >
                            {icon}
                            {text}
                        </StyledNavLink>
                    ))}
                </Column>
            </Content>

            <UserInfo>
                <Avatar>
                    <AvatarInfo>{`${firstNameFirstLetter}${lastNameFirstLetter}`}</AvatarInfo>
                </Avatar>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    {firstName} {lastName}
                </div>
            </UserInfo>
            <ContentWrapper padding="12px 24px">
                <LogoutButton onClick={handleLogout}>
                    <MarginWrapper marginRight="10px">
                        <CustomImage height="20px" src={logoutIconSource} width="20px" />
                    </MarginWrapper>
                    <LogoutText>{t('logOut')}</LogoutText>
                </LogoutButton>
            </ContentWrapper>
        </StyledAside>
    );
};

import { createRule, yupDefault } from 'constants/yupFields';
import { restore } from 'effector';
import { createForm } from 'effector-forms';
import { userEffects, userStores } from 'stores/user';

const { updateUser } = userEffects;
const { user } = userStores;
const { user: userState } = user.getState();

const $serverError = restore(updateUser.failData, null);

export const form = createForm({
    fields: {
        firstName: {
            init: userState?.firstName ?? '',
            rules: [
                createRule<string>({
                    name: 'firstName',
                    schema: yupDefault
                })
            ]
        },
        lastName: {
            init: userState?.lastName ?? '',
            rules: [
                createRule<string>({
                    name: 'lastName',
                    schema: yupDefault
                })
            ]
        },
        email: {
            init: userState?.email ?? '',
            rules: [
                createRule<string>({
                    name: 'email',
                    schema: yupDefault
                })
            ]
        },
        phone: {
            init: userState?.phone ?? '',
            rules: [
                createRule<string>({
                    name: 'phone',
                    schema: yupDefault
                })
            ]
        }
    },
    validateOn: ['change']
});

user.updates.watch(({ user }) =>
    form.setForm({
        firstName: user?.firstName ?? '',
        lastName: user?.lastName ?? '',
        email: user?.email ?? '',
        phone: user?.phone ?? ''
    })
);

$serverError.reset(form.$values.updates);

export const updateProfileFormStores = { form, $serverError };

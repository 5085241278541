import { headingsFontFamilyMixin } from 'constants/styles/fonts';
import { customPrimaryTextColorMixin, disableDefaultHStyleMixin } from 'constants/styles/mixins';
import styled from 'styled-components';
import type { Color } from 'types/styles';

export const H1 = styled.h1<Color>`
    ${disableDefaultHStyleMixin};
    ${headingsFontFamilyMixin};
    font-size: 28px;
    font-style: normal;
    font-weight: 500;
    line-height: 44px;
    ${customPrimaryTextColorMixin};
`;

export const H2 = styled.h2<Color>`
    ${disableDefaultHStyleMixin};
    ${headingsFontFamilyMixin};
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 38px;
    ${customPrimaryTextColorMixin};
`;

export const H3 = styled.h3<Color>`
    ${disableDefaultHStyleMixin};
    ${headingsFontFamilyMixin};
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px;
    ${customPrimaryTextColorMixin};
`;

export const H4 = styled.h4<Color>`
    ${disableDefaultHStyleMixin};
    ${headingsFontFamilyMixin};
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    ${customPrimaryTextColorMixin};
`;

export const H5 = styled.h5<Color>`
    ${disableDefaultHStyleMixin};
    ${headingsFontFamilyMixin};
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    ${customPrimaryTextColorMixin};
`;

export const H6 = styled.h6<Color>`
    ${disableDefaultHStyleMixin};
    ${headingsFontFamilyMixin};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 17px;
    ${customPrimaryTextColorMixin};
`;

import styled from 'styled-components';
import { Tooltip } from 'react-tooltip';

interface Props {
    bigShadow?: boolean;
}

export const StyledTooltip = styled(Tooltip)<Props>`
    &.example {
        background: white;
        border: 1px solid #e2e5e6;
        box-shadow: ${props =>
            props.bigShadow ? '0 -5px 20px 5px rgb(15 45 84 / 10%);' : '0px 8px 4px rgba(15, 45, 84, 0.1)'};
        border-radius: 4px;
        font-family: 'Quicksand';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        color: #555f61;
        max-width: 200px;
        word-break: break-all;
        padding: 3px 8px;
        opacity: 1 !important;
        z-index: 101;

        &::after,
        &::before {
            border-top-color: white !important;
            border-bottom-color: white !important;
        }
    }
`;

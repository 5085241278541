import leftArrowSource from 'ui-kit/assets/icons/left-arrow.svg';
import { secondaryTextColor } from 'constants/styles/colors';
import { bodyTextFontFamilyMixin } from 'constants/styles/fonts';
import styled, { css } from 'styled-components';

const textStyles = css`
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 19px;
    align-items: center;
`;

// TODO: check colors
export const dataPickerStyles = css`
    .date_picker.full-width {
        width: 100%;
    }
    div > .react-datepicker {
        border: 0;
        border-radius: 7px;
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    }
    .react-datepicker > .react-datepicker__triangle {
        visibility: hidden;
    }
    .react-datepicker__tab-loop > .react-datepicker-popper {
        margin: 0;
    }
    .react-datepicker-popper {
        z-index: 30;
    }
    .react-datepicker__month-container > .react-datepicker__header {
        background-color: white;
        border: 0;
    }
    .react-datepicker__header > .react-datepicker__current-month {
        ${bodyTextFontFamilyMixin};
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 25px;
        letter-spacing: 0em;
        text-align: left;
    }
    .react-datepicker__week > .react-datepicker__day,
    .react-datepicker__day-names > .react-datepicker__day-name {
        border-radius: 8px;
        ${textStyles};
    }
    .react-datepicker__day--weekend {
        color: #d55142;
    }
    .react-datepicker__month-container > .react-datepicker__month {
        margin: 0;
    }
    .react-datepicker__month-container {
        padding: 14px 29px;
        padding-top: 2px;
    }
    .react-datepicker__day--selected,
    .react-datepicker__day:hover,
    .react-datepicker__day--keyboard-selected,
    .react-datepicker__day--in-range {
        background-color: ${secondaryTextColor};
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.17);
        outline: none;
        color: white;
    }
    .react-datepicker__navigation--previous,
    .react-datepicker__navigation--next,
    .react-datepicker__navigation--previous:hover,
    .react-datepicker__navigation--next:hover {
        top: 32px;
        background: url(${leftArrowSource}) no-repeat;
        background-position: center;
        background-size: cover;
        border-right-color: transparent;
        border-left-color: transparent;
        outline: none;
        height: 21px;
        width: 21px;
        margin: 5px;
    }
    .react-datepicker__navigation--next,
    .react-datepicker__navigation--next:hover {
        transform: rotate(180deg);
    }
    .react-datepicker__day--outside-month {
        color: #c4c4c4;
    }
    .react-datepicker__day--in-selecting-range,
    .react-datepicker__month-text--in-selecting-range,
    .react-datepicker__quarter-text--in-selecting-range,
    .react-datepicker__year-text--in-selecting-range {
        background-color: #e1d4f3;
        border-radius: 50%;
    }
    .react-datepicker__month-container {
        ${bodyTextFontFamilyMixin};
    }
`;

export const DatePickerContainer = styled.div`
    display: flex;
    flex-direction: column;
    border: 1px solid var(--gray-60);
    border-radius: 10px;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    padding: 13px 15px;
    height: 48px;
`;

export const PlaceholderSpan = styled.span`
    color: #ced1da;
    font-size: 16px;
`;

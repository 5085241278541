import type { JoinUserData } from 'pages/JoinToCompany/types';
import axios from 'api/axios';

export const getCompany = (id: string) =>
    axios<API.Company>({
        method: 'GET',
        url: `/companies/${id}`
    });

export const inviteUser = (data: API.InviteDto) =>
    axios({
        url: 'companies/invite',
        data
    });

export const joinUser = (data: JoinUserData, companyId: string) =>
    axios<API.SuccessAnswer>({
        method: 'PATCH',
        url: `companies/${companyId}/add-user`,
        data
    });

export const addCompany = (data: API.RegisterCompanyDto) =>
    axios({
        url: 'companies',
        data
    });

export const getCompanyUsers = (query: string) =>
    // TODO: update any when API.GetManyResponseForUser is updated
    axios<any>({
        method: 'GET',
        url: `users?${query}`
    });

export const deleteCompanyUser = (userId: string) =>
    axios<API.SuccessAnswer>({
        method: 'DELETE',
        url: `users/delete-employee/${userId}`
    });

export const updateCompanyInfo = (data: API.UpdateCompanyDto, companyId: string) =>
    axios<API.SuccessAnswer>({
        method: 'PATCH',
        url: `companies/${companyId}/update`,
        data
    });

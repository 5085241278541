import { useTranslation } from 'react-i18next';
import { useStore } from 'effector-react';

import { pdfEvents } from 'stores/pdf';
import { type ReactFCC } from 'types/react';
import { paymentEvents } from 'stores/payment';
import { Modal } from 'ui-kit/components/Modal';
import { Subtitle } from './styles';
import { PaymentTypesEnum } from 'constants/enums';
import { buyContractModalStore } from 'stores/modals';
import { P } from 'components/common/typography/titles/P';
import { AdaptiveGrid } from 'components/wrappers/grid/GridWrapper';
import { PrimaryButton, SecondaryButton } from 'components/common/buttons/SimpleButton';

export const BuyContractModal: ReactFCC = () => {
    const { modal, closeModal } = buyContractModalStore;
    const { t } = useTranslation();
    const [_, { isSign, price, templateId, contractName, previewUrl }] = useStore(modal);

    const createContract = () => {
        paymentEvents.setPaymentType({
            type: PaymentTypesEnum.FromContract,
            isSign,
            price,
            templateId,
            contractName,
            previewUrl
        });
        pdfEvents.togglePdfGenerationProcessVisible();
    };

    return (
        <Modal visible title={t('buyContractModal.title') as string} onClose={closeModal}>
            <Subtitle>{t('buyContractModal.subtitle')}</Subtitle>
            <AdaptiveGrid alignItems="center" marginTop="30px" xxl="1fr auto 1fr">
                <PrimaryButton onClick={() => closeModal()}>{t('no')}</PrimaryButton>
                <P>{t('or')}</P>
                <SecondaryButton onClick={createContract}>{t('yes')}</SecondaryButton>
            </AdaptiveGrid>
        </Modal>
    );
};

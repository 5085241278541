import { useEffect, type ReactNode, useCallback, type MouseEvent } from 'react';
import ReactModal from 'react-modal';

import { type ReactFCC } from 'types/react';
import closeIcon from './static_assets/close.svg';
import { Section } from 'components/wrappers/grid/FlexWrapper';
import { ModalBody, ModalFooter, ModalHeader, Title } from './styles';
import { CustomImage } from 'components/common/imgComponents/CustomImg/styles';
import { borderStyles, closeIconDiameter, content, overlay } from './constants';

interface Props {
    onClose?: () => void;
    title?: string;
    width?: string;
    height?: string;
    visible: boolean;
    customHeader?: ReactNode;
    customFooter?: ReactNode;
    disabledCloseIcon?: boolean;
}

export const Modal: ReactFCC<Props> = ({
    visible,
    onClose,
    children,
    title = '',
    width = '',
    height = '',
    customHeader,
    customFooter
}) => {
    const handleClose = useCallback(
        (event: MouseEvent<HTMLDivElement>) => {
            event.stopPropagation();
            onClose?.();
        },
        [onClose]
    );

    useEffect(() => {
        document.body.style.overflow = 'hidden';
        return () => {
            document.body.style.overflow = 'unset';
        };
    }, []);

    return (
        <ReactModal
            ariaHideApp={false}
            isOpen={visible}
            style={{
                content: { ...content, width: width, height: height },
                overlay: { ...overlay }
            }}
        >
            <ModalHeader borderBottom={customHeader || title ? borderStyles : undefined}>
                <Section justifyBetween noWrap>
                    {customHeader || <Title dangerouslySetInnerHTML={{ __html: title }} />}
                    {onClose && (
                        <CustomImage pointer diameter={closeIconDiameter} src={closeIcon} onClick={handleClose} />
                    )}
                </Section>
            </ModalHeader>
            <ModalBody>{children}</ModalBody>
            {customFooter && <ModalFooter borderTop={customFooter ? borderStyles : ''}>{customFooter}</ModalFooter>}
        </ReactModal>
    );
};

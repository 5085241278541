import { useStore } from 'effector-react';
import { useMemo } from 'react';

import { designStores } from 'stores/builder/design';

const replace = (font: string) => font.replace(', Sans-Serif', '');

export const useDefaultFonts = () => {
    const { documentFonts } = useStore(designStores.coverStyles);

    const fontPairingDefaultValue = useMemo(() => {
        const { body, heading } = documentFonts;

        if (!body || !heading) {
            return undefined;
        }

        return { label: `${replace(heading)} + ${replace(body)}`, value: `${replace(heading)} + ${replace(body)}` };
    }, [documentFonts]);

    const headingDefaultValue = useMemo(() => {
        const { heading } = documentFonts;

        if (!heading) {
            return undefined;
        }

        return { label: replace(heading), value: replace(heading) };
    }, [documentFonts]);

    const bodyDefaultValue = useMemo(() => {
        const { body } = documentFonts;

        if (!body) {
            return undefined;
        }

        return { label: replace(body), value: replace(body) };
    }, [documentFonts]);

    return {
        fontPairingDefaultValue,
        headingDefaultValue,
        bodyDefaultValue
    };
};

import { useState } from 'react';

import { type ReactFCC } from 'types/react';
import { Ball, Container } from './styles';

export interface SwitchProps {
    onChange?: (active: boolean) => void;
    defaultState?: boolean;
}

export const Switch: ReactFCC<SwitchProps> = ({ onChange, defaultState = false }) => {
    const [value, setValue] = useState(defaultState);

    const onClick = () => {
        setValue(!value);
        onChange?.(!value);
    };

    return (
        <Container active={value} onClick={onClick}>
            <Ball active={value} />
        </Container>
    );
};

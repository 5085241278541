import { useNavigate } from 'react-router-dom';
import { useStore } from 'effector-react';
import { type ReactFCC } from 'types/react';
import { Modal } from 'ui-kit/components/Modal';
import { leaveDraftModalStore } from 'stores/modals';
import { DRAFTS_LINK, TEMPLATES_LINK } from 'constants/routes';
import { Buttons, Text } from './styles';
import { Button } from 'ui-kit/components/buttons/Button';
import { draftsEffects, draftsStores } from 'stores/drafts';
import { blockStores } from 'stores/builder/block';
import { questionnaireStores } from 'stores/builder/questionnaire';
import { designStores } from 'stores/builder/design';

export const LeaveDraftModal: ReactFCC = () => {
    const { closeModal } = leaveDraftModalStore;
    const navigate = useNavigate();
    const draft = useStore(draftsStores.draft);
    const blocks = useStore(blockStores.blocks);
    const { questions } = useStore(questionnaireStores.questionnaireItems);
    const coreConfig = useStore(designStores.coverStyles);

    const deleteDraft = () => {
        if (draft) {
            const { id } = draft;
            draftsEffects.deleteDraft(id);
            navigate(TEMPLATES_LINK);
            closeModal();
        }
    };

    const saveDraft = () => {
        if (draft) {
            const {
                draft: { documentName, templateCategory, templateName, templateType },
                id
            } = draft;

            draftsEffects.updateDraft({
                documentName,
                templateCategory,
                templateConfig: {
                    coreConfig,
                    detailsConfig: blocks.blocks,
                    questionnaire: questions
                },
                templateName,
                templateType,
                id
            });
            navigate(DRAFTS_LINK);
            closeModal();
        }
    };

    return (
        <Modal visible title="Leave Draft" onClose={closeModal}>
            <Text>Do you want to save the Draft?</Text>
            <Buttons>
                <Button variant="secondary" onClick={deleteDraft}>
                    Delete
                </Button>
                <Button variant="primary" onClick={saveDraft}>
                    Save
                </Button>
            </Buttons>
        </Modal>
    );
};

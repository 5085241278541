import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';

interface ConvertHTMLtoPdfParams {
    targetNodeIds: string[];
    conversionWidth?: number;
    nameOfPdf: string;
}

export const convertHTMLtoPdf = async ({ targetNodeIds, nameOfPdf }: ConvertHTMLtoPdfParams) => {
    const pdf = new jsPDF('p', 'pt', 'a4', true);
    const length = targetNodeIds.length;
    const lastIndex = length - 1;

    for (let i = 0; i < length; i++) {
        const id = targetNodeIds[i];
        const targetNode = document.getElementById(id);

        if (!targetNode) return;

        const canvas = await html2canvas(targetNode, {
            scale: 3,
            useCORS: true
        });
        const imgData = canvas.toDataURL('image/png');

        pdf.addImage(imgData, 'PNG', 0, 0, 595, 842, undefined, 'FAST');

        i !== lastIndex && pdf.addPage();
    }

    const blob = pdf.output('blob');

    return {
        file: new File([blob], nameOfPdf, { type: 'pdf' }),
        pdf
    };
};

import type { ReactFCC } from 'types/react';
import { useStore } from 'effector-react';

import {
    AddPaymentCardModal,
    AddUserModal,
    AreYouSureModal,
    BuyContractModal,
    ColorPalletModal,
    ComingSoonModal,
    CompanyTemplateNamingModal,
    CookiesModal,
    CopyTemplateModal,
    CreateReminderModal,
    DescriptionModal,
    FontsModal,
    LeaveBuilderModal,
    LeaveDraftModal,
    LogicModal,
    NotAuthorizedModal,
    NotificationModal,
    PaymentModal,
    PreviewModeModal,
    TemplateModal,
    UpdateSubscriptionModal
} from './index';

import { combinedModalsStore } from 'stores/modals';

export const Modal: ReactFCC = () => {
    const {
        isAddPaymentCardModalVisible,
        isAddUserModalVisible,
        isAlertsModalVisible,
        isAreYouSureModalVisible,
        isBuyContractModalVisible,
        isColorPalletModalVisible,
        isComingSoonModalVisible,
        isCompanyTemplateNamingModalVisible,
        isCookiesModalVisible,
        isCopyTemplateModalVisible,
        isCreateReminderModalVisible,
        isDescriptionModalVisible,
        isFontsModalVisible,
        isLeaveBuilderModalVisible,
        isLeaveDraftModalVisible,
        isLogicModalVisible,
        isNotAuthorizedModalVisible,
        isPaymentModalVisible,
        isPreviewModeModalVisible,
        isTemplateModalVisible,
        isUpdateSubscriptionModalVisible
    } = useStore(combinedModalsStore);

    return (
        <>
            {isPreviewModeModalVisible && <PreviewModeModal />}
            {isAlertsModalVisible && <NotificationModal />}
            {isNotAuthorizedModalVisible && <NotAuthorizedModal />}
            {isUpdateSubscriptionModalVisible && <UpdateSubscriptionModal />}
            {isPaymentModalVisible && <PaymentModal />}
            {isCookiesModalVisible && <CookiesModal />}
            {isLogicModalVisible && <LogicModal />}
            {isAreYouSureModalVisible && <AreYouSureModal />}
            {isTemplateModalVisible && <TemplateModal />}
            {isLeaveBuilderModalVisible && <LeaveBuilderModal />}
            {isFontsModalVisible && <FontsModal />}
            {isColorPalletModalVisible && <ColorPalletModal />}
            {isComingSoonModalVisible && <ComingSoonModal />}
            {isBuyContractModalVisible && <BuyContractModal />}
            {isLeaveDraftModalVisible && <LeaveDraftModal />}
            {isCompanyTemplateNamingModalVisible && <CompanyTemplateNamingModal />}
            {isDescriptionModalVisible && <DescriptionModal />}
            {isCreateReminderModalVisible && <CreateReminderModal />}
            {isAddUserModalVisible && <AddUserModal />}
            {isAddPaymentCardModalVisible && <AddPaymentCardModal />}
            {isCopyTemplateModalVisible && <CopyTemplateModal />}
        </>
    );
};

export enum PaymentTypesEnum {
    FromStripe = 'fromStripe',
    FromContract = 'fromContract'
}

export enum CardCreationTabsEnum {
    Cards = 'cards',
    NewCard = 'newCard'
}

export enum AnimatedContractRadioButton {
    Purple = 'purple',
    Green = 'green',
    Orange = 'orange'
}

export enum TemplateTypesEnum {
    Primary = 'primaryTemplate',
    Secondary = 'secondaryTemplate'
}

export enum TemplateContractNamesEnum {
    RentalOffice = 'rentalOffice',
    RentalContract = 'rentalContract'
}

export enum ConstructorStepsEnum {
    Start = 'Start',
    Send = 'Send',
    Fill = 'Fill'
}

export enum TemplateFontKeysEnum {
    FontStyle = 'fontStyle',
    FontWeight = 'fontWeight'
}

export enum TemplateTextTypesEnum {
    Body = 'body',
    Title = 'title'
}

export enum TemplateTextTypesValues {
    Italic = 'italic',
    Bold = 'bold',
    Normal = 'normal'
}

export enum StripeIdealPaymentAnswer {
    Succeeded = 'succeeded'
}

export enum CompanyTemplateEnum {
    companyTemplate = 'company-template'
}

export enum RolesEnum {
    employees = 'employees',
    admin = 'company-admin',
    owner = 'company-owner'
}

export enum TemplateNameEnum {
    FirstTemplate = 'template-1',
    SecondTemplate = 'template-2'
}

export enum ConfigEnum {
    Text = 'Text',
    Title = 'Title',
    Datepicker = 'Date',
    UnorderedList = 'UnorderedList',
    OrderedList = 'OrderedList',
    Radio = 'Radio',
    Select = 'Select',
    MultiSelect = 'MultiSelect',
    InputString = 'InputString',
    InputNumber = 'InputNumber',
    File = 'File'
}

export enum ActiveColorKeyEnum {
    CurrentColors = 'currentColors',
    PrinterFriendlyColors = 'printerFriendlyColors'
}

export enum CoreConfigObjectKeysEnum {
    BodyText = 'bodyText',
    Headings = 'headings'
}

export enum HideOrSHowEnum {
    Hide = 'hide',
    Show = 'show'
}

export enum CSSPositionsEnum {
    Top = 'top',
    Bottom = 'bottom',
    Left = 'left',
    Right = 'right'
}

export enum SocialMediaEnum {
    Google = 'google',
    Facebook = 'facebook',
    LinkedIn = 'linkedIn'
}

export enum SubscriptionStatusEnum {
    Active = 'active',
    Canceled = 'canceled'
}

export enum AccountPageTabNameEnum {
    Profile = 'Profile',
    PersonalAndCompany = 'Personal & Company',
    Admin = 'Admin & Users',
    Payment = 'Payment',
    Security = 'Security',
    Links = 'Links'
}

export enum CompanyTabNameEnum {
    Profile = 'Personal & Company'
}

export enum ContractTypeEnum {
    Letters = 'Letters',
    Hiring = 'Hiring',
    Privacy = 'AVG/PRIVACY'
}

export enum Rates {
    OnDemand = 'onDemand',
    Pro = 'pro',
    Enterprise = 'enterprise'
}

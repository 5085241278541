export const defaultFonts = [
    { first: 'Helvetica', second: '+ Poppins' },
    { first: 'Helvetica', second: '+ Open Sans' },
    { first: 'Helvetica', second: '+ Inter' },
    { first: 'Poppins', second: '+ Open Sans' },
    { first: 'Inter', second: '+ Open Sans' }
];

export const displayedFontFamilies = [
    'Helvetica',
    'Arial',
    'Calibri',
    'Open Sans',
    'Lato',
    'Source Sans Pro',
    'Work Sans',
    'Times New Roman',
    'Libre Baskerville',
    'Crimson Text',
    'EB Garamond',
    'Montserrat',
    'Baloo 2',
    'DM Serif Display',
    'Poppins'
];

export const fontPairingOptions = defaultFonts.map(({ first, second }) => ({
    label: `${first} ${second}`,
    value: `${first} ${second}`
}));

export const fontOptions = displayedFontFamilies.map(item => ({
    label: item,
    value: item
}));

import { API } from 'api';
import { createEffect } from 'effector';

// * effects
const copyTemplate = createEffect({
    handler: async (data: KIARA.CopyTemplateRequest) => await API.copyTemplates.copyTemplate(data)
});

// * exports
export const copyTemplatesEffects = {
    copyTemplate
};

import styled, { css } from 'styled-components';
import { Button } from 'ui-kit/components/buttons/Button';

interface Props {
    withSaveButton?: boolean;
    forDescription?: boolean;
}

export const StyledButton = styled(Button)`
    position: absolute;
    bottom: 10px;
    right: 20px;
    z-index: 99;
    padding: 7px 30px;
`;

export const Container = styled.div<Props>`
    border: 1px solid var(--gray-60);
    border-radius: 8px;
    position: relative;

    .editor {
        cursor: text;
        padding: 12px 16px;
        font-weight: 400;
        font-size: 16px;

        & > div {
            ${({ withSaveButton }) =>
                withSaveButton &&
                css`
                    margin-bottom: 50px;
                `}

            & div {
                margin: 0 !important;
            }

            & span {
                word-break: break-word;
                -webkit-hyphens: auto;
                -moz-hyphens: auto;
                hyphens: auto;
                padding: 0;
                margin: 0;
                font-family: 'Montserrat', sans-serif;
                font-size: 16px;
                line-height: 0;
            }
        }

        ::placeholder {
            color: #d0d3d9;
        }
    }

    .editor-icon {
        width: 30px;
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 4px;
        padding: 0;

        &:hover {
            border-color: #6807f9;
        }
    }

    .editor-link {
        width: 30px;
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 4px;

        &:hover {
            border-color: #6807f9;
        }
    }

    .editor-wrapper {
        overflow: hidden;
        cursor: default;
        font-size: 14px;
    }

    .editor-toolbar {
        border: none;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
        background-color: white;
        border-bottom: 1px solid #d0d3d9;
        padding: 8px 12px;
        margin-bottom: 0;

        div[aria-label='rdw-link-control'] {
            position: unset;
        }

        div[aria-selected='true'] {
            border-color: #6807f9;
        }

        div[class='rdw-link-modal'] {
            left: 266px;
        }

        ${({ withSaveButton, forDescription }) =>
            !withSaveButton &&
            !forDescription &&
            css`
                display: none;
            `}
    }
`;

import { ContentWrapper } from 'components/wrappers/ContentWrapper';
import { headingsFontFamilyMixin } from 'constants/styles/fonts';
import { disableDefaultHStyleMixin } from 'constants/styles/mixins';
import styled from 'styled-components';
import type { BorderProperties } from 'types/styles';

export const Title = styled.h3`
    ${disableDefaultHStyleMixin};
    ${headingsFontFamilyMixin};
    margin-right: 16px;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 26px;
`;

export const ModalHeader = styled.div<BorderProperties>`
    padding: 16px 16px 8px 16px;
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
    border-bottom: ${({ borderBottom }) => borderBottom};
    position: sticky;
    top: 0;
    background-color: white;
    z-index: 100;
`;

export const ModalBody = styled(ContentWrapper)`
    height: 100%;
    overflow-y: auto;
    word-wrap: break-word;
    flex: 1;
    padding: 16px;
`;

export const ModalFooter = styled.div<BorderProperties>`
    padding: 16px;
    border-top: ${({ borderTop }) => borderTop};
    background-color: white;
`;

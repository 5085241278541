import { v4 as generateId } from 'uuid';

import type { BlockInterface, Fields } from 'types/builder/interface';

export const addUpfrontSummarySectionToRowHelper = (
    groups: BlockInterface['groups'],
    // TODO: any
    clause: any,
    currentFields: {
        fields: Fields[];
        id?: string | undefined;
    }
) => {
    if (currentFields.fields.length === 3 && !currentFields.fields.some(item => item.type === 'EMPTY')) {
        return [
            ...groups,
            {
                fields: [
                    { ...clause, title: '' },
                    { type: 'EMPTY', id: generateId() },
                    { type: 'EMPTY', id: generateId() }
                ],
                id: generateId()
            }
        ];
    }

    if (currentFields.fields.filter(item => item.type === 'EMPTY').length === 2 && currentFields.fields.length === 3) {
        return groups.map((item, index) => {
            if (index === groups.length - 1) {
                const newFields = item.fields.map((field, index) => (index === 1 ? { ...clause, title: '' } : field));

                return {
                    ...item,
                    fields: [...newFields]
                };
            }

            return item;
        });
    }

    if (currentFields.fields.filter(item => item.type === 'EMPTY').length === 1 && currentFields.fields.length === 3) {
        return groups.map((item, index) => {
            if (index === groups.length - 1) {
                const newFields = item.fields.filter(item => item.type !== 'EMPTY');

                return {
                    ...item,
                    fields: [...newFields, { ...clause, title: '' }]
                };
            }

            return item;
        });
    }

    if (groups.length === 0) {
        return [
            {
                fields: [
                    {
                        ...clause,
                        title: ''
                    },
                    { type: 'EMPTY', id: generateId() },
                    { type: 'EMPTY', id: generateId() }
                ],
                id: generateId()
            }
        ];
    }

    return groups.map((item, index) =>
        index === groups.length - 1
            ? {
                  ...item,
                  fields: [
                      ...item.fields,
                      {
                          ...clause,
                          title: ''
                      },
                      { type: 'EMPTY', id: generateId() },
                      { type: 'EMPTY', id: generateId() }
                  ]
              }
            : item
    );
};

import { v4 } from 'uuid';

import { type ReactFCC } from 'types/react';
import { StyledCustomImage } from './styles';
import TooltipIcon from './static_assets/tooltip_icon.svg';
import { Tooltip } from 'ui-kit/components/Tooltip';
import { descriptionModalStore } from 'stores/modals';

interface Props {
    description: string;
    backgroundWidth?: string;
}

export const DescriptionBadge: ReactFCC<Props> = ({ description, backgroundWidth }) => {
    const openDescriptionModal = (e: React.MouseEvent<Element, MouseEvent>) => {
        e.stopPropagation();
        descriptionModalStore.openModal({ description, backgroundWidth: backgroundWidth || '50%' });
    };

    const id = v4();

    return (
        <>
            <StyledCustomImage
                pointer
                data-tooltip-id={id}
                height="20px"
                role="button"
                src={TooltipIcon}
                width="20px"
                onClick={e => openDescriptionModal(e)}
            />
            <Tooltip id={id} text={'Show descriptions'} />
        </>
    );
};

import { HOME_LINK } from 'constants/routes';
import { useStore } from 'effector-react';
import { userStores } from 'stores/user';
import type { ReactFCC } from 'types/react';
import { Navigate } from 'react-router-dom';

export const PrivateRoute: ReactFCC = ({ children }) => {
    const user = useStore(userStores.user);

    if (user?.accessToken) return <>{children}</>;
    else return <Navigate to={HOME_LINK} />;
};

// * text
export const primaryTextColor = '#160135';
export const secondaryTextColor = '#6807F9';
export const darkGrey = '#7D828E';
export const primaryGrey = '#A1A8B5';
export const secondaryGrey = '#D0D3D9';
export const inputBorderColor = '#F8F8F8';
export const checkboxPrimaryColor = '#6807F9';
export const footerLinkColor = '#233353';

// * background
export const backgroundColor = 'white';
export const primaryBackgroundColor = '#FF8049';
export const blockColor = '#F5EFFF';

// * notifications
// * open mock
export const infoColor = 'rgb(25, 118, 210)';
// * close mock
export const successColor = '#83E2B5';
export const errorColor = '#FF8170';
// * open mock
export const blockedColor = '#fff1f1';
export const textInfoColor = '#585858';
// * close mock

export const inputFocusColor = '#8cb1ff';
export const hoveredTextColor = '#7D828E';

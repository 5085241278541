import { COVER_ELEMENT_DATA_CLASS, SECTION_CLASS_NAME, UPFRONT_SUMMARY_DATA_CLASS } from 'components/preview/constants';
import { getElementStylePropertyValue } from './get-element-style-property-value';
import { ActivePreviewDesign } from 'types/preview';

const designsPagePadding = {
    [ActivePreviewDesign.First]: 36,
    [ActivePreviewDesign.Second]: 24,
    [ActivePreviewDesign.Third]: 34,
    [ActivePreviewDesign.Fourth]: 34
};

const PAGE_HEIGHT = 842;

export interface Pages {
    [key: number]: HTMLElement[];
}

export const generateTemplatePages = (allBlocks: HTMLElement[], activeDesign: ActivePreviewDesign) => {
    const maxPageHeight = PAGE_HEIGHT - 2 * designsPagePadding[activeDesign];

    const coverPage = document.querySelector(`[data-class=${COVER_ELEMENT_DATA_CLASS}]`) as HTMLElement;
    const upfrontSummaryPage = document.querySelector(`[data-class=${UPFRONT_SUMMARY_DATA_CLASS}]`) as HTMLElement;

    const allBlocksHeights = allBlocks.map(
        block => block.offsetHeight + parseInt(getElementStylePropertyValue(block, 'margin-bottom'))
    );

    let count = 1;
    let sum = 0;

    const pages: Pages = {
        1: []
    };

    if (coverPage && !upfrontSummaryPage) {
        pages[1].push(coverPage);
        count = 2;
        pages[count] = [];
    }

    if (!coverPage && upfrontSummaryPage) {
        pages[1].push(upfrontSummaryPage);
        count = 2;
        pages[count] = [];
    }

    if (coverPage && upfrontSummaryPage) {
        pages[1] = [coverPage];
        pages[2] = [upfrontSummaryPage];
        count = 3;
        pages[count] = [];
    }

    const blockChildren = new Map<number, HTMLElement[]>();

    for (let i = 0; i < allBlocks.length; ++i) {
        const blockHeight = allBlocksHeights[i];

        sum += blockHeight;

        if (sum > maxPageHeight) {
            if (!blockChildren.get(i)) {
                const sections = Array.from(
                    allBlocks[i].querySelectorAll(`[data-class=${SECTION_CLASS_NAME}]`)
                ) as HTMLElement[];

                blockChildren.set(i, sections);
            }

            const prevSum = sum - blockHeight;
            const allowedDifference = maxPageHeight - prevSum;

            const sections = blockChildren.get(i);
            const container = document.createElement('div');

            let sectionSum = 0;

            while (sectionSum < allowedDifference && sections && sections[0]) {
                const height =
                    parseInt(getElementStylePropertyValue(sections[0], 'margin-bottom')) + sections[0].offsetHeight;

                sectionSum += height;

                if (sectionSum > allowedDifference) {
                    sectionSum -= height;
                    const subDifference = allowedDifference - sectionSum;
                    allBlocksHeights[i] += subDifference;

                    break;
                }

                Array.from(allBlocks[i].classList).forEach(className => {
                    container.classList.add(className);
                });
                container.appendChild(sections[0]);

                sections.shift();
            }

            sectionSum = 0;

            if (sections && sections.length > 0) {
                blockChildren.set(i, sections);
            }

            pages[count].push(container);

            const remainingBlockHeight = sections
                ? sections.reduce((acc, current) => {
                      const marginBottom = parseInt(getElementStylePropertyValue(current, 'margin-bottom'));

                      return acc + current.offsetHeight + marginBottom;
                  }, 0)
                : 0;

            allBlocksHeights[i] = remainingBlockHeight;

            sum = 0;
            pages[++count] = [];
            --i;
        } else {
            pages[count].push(allBlocks[i]);
        }
    }

    Object.keys(pages).forEach(key => {
        if (pages[Number(key)].length === 0) {
            delete pages[Number(key)];
        }
    });

    return pages;
};

import type { BlockState, DeleteClauseVariationsProps } from '../types';

export const deleteClauseVariationsReducer = (
    state: BlockState,
    { blockId, clauseId }: DeleteClauseVariationsProps
) => ({
    ...state,
    blocks: state.blocks.map(block =>
        block.id === blockId
            ? {
                  ...block,
                  groups: [
                      {
                          fields: block.groups[0].fields.map(field => {
                              if (field.id === clauseId) {
                                  return {
                                      ...field,
                                      variationsField: undefined,
                                      content: field.content.map(item =>
                                          item.isDefault ? { ...item, show: true } : { ...item, show: false }
                                      )
                                  };
                              }

                              return field;
                          })
                      }
                  ]
              }
            : block
    )
});

import { StyledLogoImg } from './styles';
import { HOME_LINK } from 'constants/routes';
import { KiaraLogoIcon } from 'ui-kit/assets';
import type { ReactFCC } from 'types/react';
import { useNavigate } from 'react-router-dom';

interface Props {
    disabled?: boolean;
}

export const KiaraLogo: ReactFCC<Props> = ({ disabled }) => {
    const navigate = useNavigate();

    return (
        <StyledLogoImg disabled={disabled} role="button" onClick={() => navigate(HOME_LINK)}>
            <KiaraLogoIcon />
        </StyledLogoImg>
    );
};

import type { BlockState, ChangeShowClauseEventProps } from '../types';

export const changeShowClauseReducer = (
    state: BlockState,
    { blockId, clauseId, answer, answerIndex }: ChangeShowClauseEventProps
) => {
    let answersArray: string[] = [];

    if (answerIndex.length > 1) {
        answersArray = answerIndex.split(',');
    }

    return {
        ...state,
        blocks: state.blocks.map(block =>
            block.id === blockId
                ? {
                      ...block,
                      groups: block.groups.map(group => ({
                          ...group,
                          fields: group.fields.map(field =>
                              field.id === clauseId
                                  ? {
                                        ...field,
                                        show: answer === 'show',
                                        content: field.content.map((item, index) => {
                                            if (field.content.length === 1) {
                                                return item;
                                            }

                                            if (answersArray.length > 0) {
                                                if (answersArray.includes(String(index)) && answer === 'show') {
                                                    return { ...item, show: true };
                                                } else if (answersArray.includes(String(index)) && answer === 'hide') {
                                                    return { ...item, show: false };
                                                }

                                                if (!answersArray.includes(String(index)) && answer === 'hide') {
                                                    return { ...item, show: item.show };
                                                }

                                                if (!answersArray.includes(String(index)) && answer === 'show') {
                                                    return { ...item, show: false };
                                                }
                                            }

                                            if (index === Number(answerIndex) && answer === 'show') {
                                                return { ...item, show: true };
                                            } else if (index === Number(answerIndex) && answer === 'hide') {
                                                return { ...item, show: false };
                                            }

                                            if (!answersArray.includes(String(index)) && answer === 'hide') {
                                                return { ...item, show: item.show };
                                            }

                                            if (!answersArray.includes(String(index)) && answer === 'show') {
                                                return { ...item, show: false };
                                            }

                                            return item;
                                        })
                                    }
                                  : field
                          )
                      }))
                  }
                : block
        )
    };
};

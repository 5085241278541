import ReactSelect, {
    type Props,
    type GroupBase,
    type MultiValue,
    type ActionMeta,
    type OptionProps,
    type SingleValue,
    type SingleValueProps
} from 'react-select';

import './index.scss';
import { type ReactFCC } from 'types/react';

export interface SelectValue {
    icon?: JSX.Element;
    label: string;
    value: string;
}

interface SelectProps extends Omit<Props, 'options' | 'onChange'> {
    options: SelectValue[];
    value?: SelectValue | SelectValue[];
    defaultValue?: SelectValue | SelectValue[];
    disabled?: boolean;
    width?: string;
    onChange?: (
        newValue: MultiValue<SelectValue> | SingleValue<SelectValue>,
        actionMeta: ActionMeta<SelectValue>
    ) => void;
}

const CustomOption = ({ innerRef, innerProps, data }: OptionProps<SelectValue, boolean, GroupBase<SelectValue>>) => (
    <div ref={innerRef} className="react-select__option" {...innerProps}>
        {data.icon}
        {data.label}
    </div>
);

const CustomSingleValue = ({ innerProps, data }: SingleValueProps<SelectValue, boolean, GroupBase<SelectValue>>) => (
    <div className="react-select__single-value" {...innerProps}>
        {data.icon}
        {data.label}
    </div>
);

export const Select: ReactFCC<SelectProps> = ({
    isClearable,
    defaultValue,
    isSearchable,
    placeholder,
    onChange,
    disabled,
    options,
    isMulti,
    width,
    value
}) => (
    <ReactSelect
        className="react-select__container"
        classNamePrefix="react-select"
        components={{
            Option: CustomOption,
            SingleValue: CustomSingleValue
        }}
        defaultValue={defaultValue}
        isClearable={isClearable}
        isDisabled={disabled}
        isMulti={isMulti}
        isSearchable={isSearchable}
        menuPlacement="auto"
        menuPortalTarget={document.body}
        options={options}
        placeholder={placeholder}
        styles={{
            menuPortal: baseStyles => ({ ...baseStyles, zIndex: 10000 }),
            control: baseStyles => ({
                ...baseStyles,
                width: width
            })
        }}
        value={value}
        onChange={onChange}
    />
);

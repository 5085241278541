import styled from 'styled-components';

export const Container = styled.div`
    width: 100%;
    position: sticky;
    bottom: 0;
    background-color: white;
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
    border-top: 1px solid var(--gray-20);
    display: flex;
    justify-content: flex-end;
    gap: 16px;
    padding-top: 16px;
`;

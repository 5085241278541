import { type StripeCardElementChangeEvent, type StripeIdealBankElementChangeEvent } from '@stripe/stripe-js';
import { CardElement, IdealBankElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { type FormEvent, useEffect, useState } from 'react';

import { pdfEvents } from 'stores/pdf';
import { message } from 'stores/alerts';
import { type ReactFCC } from 'types/react';
import { paymentEvents } from 'stores/payment';
import { PaymentTypesEnum } from '../../../constants/enums';
import { idealCardStyle, visaCardStyle } from './constants';
import { IdealForm, Input, Label, Span, StripeButton, VisaCardForm } from './styles';

export interface Props {
    price: number;
    isSign: boolean;
    isIdeal: boolean;
    templateId: string;
    previewUrl?: string;
    contractName: string;
}

const { setStripeParams, setPaymentType } = paymentEvents;
const { togglePdfGenerationProcessVisible } = pdfEvents;

export const StripeForm: ReactFCC<Props> = ({ isIdeal, isSign, contractName, templateId, price, previewUrl }) => {
    const stripe = useStripe();
    const elements = useElements();

    const [{ error, succeeded, disabled }, setFormState] = useState({ error: '', succeeded: false, disabled: true });
    const [userName, setUserName] = useState('');

    const handleUsernameChange = (event: FormEvent<HTMLInputElement>) => {
        setUserName(event.currentTarget.value);
    };

    const handleVisaCardChange = ({ empty, error }: StripeCardElementChangeEvent) => {
        setFormState(previousState => ({
            ...previousState,
            disabled: empty,
            error: error?.message || ''
        }));
    };

    const handleSelectBankChange = ({ complete }: StripeIdealBankElementChangeEvent) => {
        setFormState(previousState => ({
            ...previousState,
            disabled: !complete
        }));
    };

    const handleSubmit = (event: FormEvent) => {
        event.preventDefault();

        if (!stripe || !elements) {
            return;
        }

        setPaymentType({ type: PaymentTypesEnum.FromStripe, isSign, templateId, contractName, price, previewUrl });
        setStripeParams({ stripe, elements, isIdeal, userName, isSign });
        togglePdfGenerationProcessVisible();
    };

    const isVisaCardPaymentButtonDisabled = disabled || succeeded;
    const isIdealPaymentButtonDisabled = !stripe || disabled || !userName || succeeded;

    useEffect(() => {
        if (error) {
            message.error(error);
        }
    }, [error]);

    return isIdeal ? (
        <IdealForm onSubmit={handleSubmit}>
            <Label>
                Name
                <Input required placeholder="Name" value={userName} onChange={handleUsernameChange} />
            </Label>
            <Label>
                Bank
                <IdealBankElement options={idealCardStyle} onChange={handleSelectBankChange} />
            </Label>
            <StripeButton disabled={isIdealPaymentButtonDisabled} type="submit">
                <Span>Submit Payment</Span>
            </StripeButton>
        </IdealForm>
    ) : (
        <VisaCardForm id="payment-form" onSubmit={handleSubmit}>
            <CardElement id="card-element" options={visaCardStyle} onChange={handleVisaCardChange} />
            <StripeButton disabled={isVisaCardPaymentButtonDisabled} id="submit">
                <Span>Pay now</Span>
            </StripeButton>
        </VisaCardForm>
    );
};

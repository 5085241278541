import { createEvent, createStore, restore } from 'effector';
import type { Pages } from './helpers/generate-template-pages';

// * events
const setAllBlocks = createEvent<HTMLElement[]>();
const setMounting = createEvent<boolean>();
const setIsLoadingTemplatePages = createEvent<boolean>();
const addElementIntoPage = createEvent<HTMLElement>();
const resetAllBlocks = createEvent();
const resetPages = createEvent();
const resetPagesIds = createEvent();
const resetMounting = createEvent();

// * stores
const allBlocks = restore<HTMLElement[]>(setAllBlocks, []).reset(resetAllBlocks);
const pages = createStore<Pages>({
    1: []
})
    .on(addElementIntoPage, (pages, element) => {
        Object.keys(pages).forEach(key => {
            pages[Number(key)].push(element);
        });

        return pages;
    })
    .reset(resetPages);
const pagesIds = createStore<string[]>(['']).reset(resetPagesIds);
const mounting = restore(setMounting, false).reset(resetMounting);
const isLoadingTemplatePages = restore(setIsLoadingTemplatePages, false);

// * exports
export const templatePagesEvents = {
    setAllBlocks,
    resetAllBlocks,
    setMounting,
    setIsLoadingTemplatePages,
    addElementIntoPage,
    resetPages,
    resetPagesIds,
    resetMounting
};

export const templatePagesStores = {
    allBlocks,
    mounting,
    pages,
    pagesIds,
    isLoadingTemplatePages
};

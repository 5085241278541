import { API } from 'api';
import { createEvent, restore } from 'effector';
import { createNotifyingErrorEffect } from 'utils/store';

// * events
const setSearchRoleId = createEvent<string>();

// * effects
const getRoles = createNotifyingErrorEffect({
    handler: async () => await API.role.getRoles()
});

// * stores
const roles = restore<API.Role[] | null>(getRoles.doneData, null);
const searchRoleId = restore(setSearchRoleId, '');

// * exports
export const roleEvents = {
    setSearchRoleId
};

export const roleStores = {
    roles,
    searchRoleId
};

export const roleEffects = {
    getRoles
};

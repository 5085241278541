import styled from 'styled-components';
import { headingsFontFamilyMixin } from 'constants/styles/fonts';

export const ButtonsContainer = styled.div`
    display: flex;
    justify-content: space-between;
`;

export const BodyContainer = styled.div`
    padding-bottom: 20px;
    max-width: 450px;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const Text = styled.p`
    ${headingsFontFamilyMixin}
    font-size: 16px;
    line-height: 20px;
    color: var(--gray-80);
    margin-top: 10px;
`;

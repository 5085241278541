import styled from 'styled-components';

export const Container = styled.section`
    width: 100%;
    height: 100vh;
    max-height: 100vh;
    background-color: var(--purple-40);
`;

export const Wrapper = styled.div`
    display: flex;
`;

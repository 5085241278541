import { useTranslation } from 'react-i18next';
import { useStore } from 'effector-react';
import { type FormEvent } from 'react';
import emailjs from '@emailjs/browser';

import { message } from 'stores/alerts';
import { loadingEvents } from 'stores/loading';
import { Modal } from 'ui-kit/components/Modal';
import { useFormSchema } from 'hooks/useFormSchema';
import { comingSoonModalStore } from 'stores/modals';
import { primaryGrey } from 'constants/styles/colors';
import { P } from 'components/common/typography/titles/P';
import { Row } from 'components/wrappers/grid/FlexWrapper';
import { FormInput } from 'ui-kit/components/inputs/FormInput';
import { ContentWrapper } from 'components/wrappers/ContentWrapper';
import { PrimaryButton } from 'components/common/buttons/SimpleButton';
import { PUBLIC_KEY, SERVICE_ID, TEMPLATE_ID, yupSchema } from './constants';
import { type ReactFCC } from 'types/react';

export const ComingSoonModal: ReactFCC = () => {
    const { modal, closeModal } = comingSoonModalStore;
    const [_, { contractName }] = useStore(modal);
    const { t } = useTranslation();

    const {
        formState: { isValid },
        control,
        getValues
    } = useFormSchema(yupSchema);

    const sendEmail = async (event: FormEvent) => {
        event.preventDefault();

        try {
            loadingEvents.setIsLoading();

            await emailjs.send(SERVICE_ID, TEMPLATE_ID, { message: contractName, from: getValues().email }, PUBLIC_KEY);
            message.success('Success');
            closeModal();
        } catch (error) {
            // TODO: @ts-ignore
            // @ts-ignore
            message.error(error?.text);
        } finally {
            loadingEvents.resetLoading();
        }
    };

    return (
        <Modal visible title={t('comingSoonModal.title') as string} width="500px" onClose={closeModal}>
            <P>
                {t('comingSoonModal.text1')} {contractName.replaceAll('&shy;', '')} {t('comingSoonModal.text2')}
            </P>
            <form onSubmit={sendEmail}>
                <Row alignEnd justifyBetween marginTop="20px">
                    <ContentWrapper width="70%">
                        <FormInput
                            control={control}
                            name="email"
                            title={t('comingSoonModal.placeholder') as string}
                            titleColor={primaryGrey}
                        />
                    </ContentWrapper>
                    <PrimaryButton disabled={!isValid} type="submit">
                        {t('comingSoonModal.buttonText')}
                    </PrimaryButton>
                </Row>
            </form>
        </Modal>
    );
};

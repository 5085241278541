import { sample } from 'effector';
import { ownTemplatesEffects } from './index';
import { blockEvents } from 'stores/builder/block';

sample({
    clock: [ownTemplatesEffects.deleteTemplate.doneData],
    fn: () => {
        ownTemplatesEffects.getTemplates(null);
        blockEvents.resetBlocksEvent();
    }
});

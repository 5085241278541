import { primaryBackgroundColor } from 'constants/styles/colors';
import { inheritTextStyleMixin } from 'constants/styles/mixins';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import type { Color } from 'types/styles';

export const InternalLink = styled(NavLink)<Color>`
    ${inheritTextStyleMixin};
    color: ${({ color }) => color || primaryBackgroundColor};
`;

export const Anchor = styled.a``;

export enum ActivePreviewDesign {
    First = 0,
    Second = 1,
    Third = 2,
    Fourth = 3
}

export enum ActivePalletIndex {
    First = 0,
    Second = 1,
    Third = 2,
    Fourth = 3
}

export interface Pallet {
    first: string;
    fourth: string;
    second: string;
    third: string;
}

export interface PreviewThemes {
    cover1: Pallet[];
    cover2: Pallet[];
    cover3: Pallet[];
    cover4: Pallet[];
}

export interface PreviewFonts {
    heading: string;
    body: string;
}

export interface PreviewInfo {
    activeDesign: ActivePreviewDesign;
    palletIndex: ActivePalletIndex;
    themes: PreviewThemes;
    documentTheme: Pallet;
    documentFonts: PreviewFonts;
}

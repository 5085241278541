import { Aside } from '../../grid/Aside';
import { Container, Wrapper } from './styles';
import type { ReactFCC } from 'types/react';

export const DashboardLayout: ReactFCC = ({ children }) => (
    <Wrapper>
        <Aside />
        <Container>{children}</Container>
    </Wrapper>
);

import { Modal } from 'ui-kit/components/Modal';
import { TEMPLATES_LINK } from 'constants/routes';
import { useStore } from 'effector-react';
import { leaveBuilderModalStore, templateModalStore } from 'stores/modals';
import { useNavigate } from 'react-router-dom';
import { ButtonsContainer, BodyContainer, Text } from './styles';
import { Button } from 'ui-kit/components/buttons/Button';
import { ownTemplatesEffects, ownTemplatesEvents, ownTemplatesStores } from 'stores/ownTemplates';
import { blockEvents } from 'stores/builder/block';
import { questionnaireEvents } from 'stores/builder/questionnaire';
import { AreYouSureIcon } from '../AreYouSureModal/static_assets';
import type { ReactFCC } from 'types/react';
import { useCallback, useEffect } from 'react';

export const LeaveBuilderModal: ReactFCC = () => {
    const { modal, closeModal } = leaveBuilderModalStore;
    const [_, { config, documentName, isUpdatingTemplate }] = useStore(modal);
    const ownTemplate = useStore(ownTemplatesStores.template);
    const navigate = useNavigate();

    const goBackToDashboardTemplates = () => {
        navigate(TEMPLATES_LINK);
        closeModal();

        blockEvents.resetBlocksEvent();
        questionnaireEvents.resetQuestionnaireEvent();
        ownTemplatesEvents.resetTemplate();
    };

    const handleSaveTemplate = useCallback(async () => {
        if (ownTemplate) {
            const data = {
                documentName,
                templateName: ownTemplate?.templateName,
                templateType: 'Contract',
                templateCategory: 'Hiring',
                templateConfig: config
            };

            await ownTemplatesEffects.updateTemplate({ id: ownTemplate?.id, data });
            closeModal();
        }
    }, [closeModal, config, documentName, ownTemplate]);

    const handleCreateTemplate = () => {
        closeModal();

        templateModalStore.openModal({ config, documentName });
    };

    useEffect(() => {
        ownTemplatesEffects.updateTemplate.doneData.watch(() => {
            navigate(TEMPLATES_LINK);

            blockEvents.resetBlocksEvent();
            questionnaireEvents.resetQuestionnaireEvent();
            ownTemplatesEvents.resetTemplate();
        });
    }, [navigate]);

    return (
        <Modal visible title={'Unsaved changes'} onClose={closeModal}>
            <BodyContainer>
                <AreYouSureIcon />
                <Text>
                    You are about to leave this template without saving. All changes will be lost. Do you really want to
                    discard changes?
                </Text>
            </BodyContainer>
            <ButtonsContainer>
                <Button variant="secondary" onClick={goBackToDashboardTemplates}>
                    Discard changes
                </Button>
                <Button variant="primary" onClick={isUpdatingTemplate ? handleSaveTemplate : handleCreateTemplate}>
                    Save template
                </Button>
            </ButtonsContainer>
        </Modal>
    );
};

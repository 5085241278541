import { type ChangeEvent, useRef } from 'react';
import { v4 } from 'uuid';

import { type ReactFCC } from 'types/react';
import { Tooltip } from 'ui-kit/components/Tooltip';
import { Input } from 'ui-kit/components/inputs/Input';
import { isEllipsisActive } from 'utils/is-ellipsis-active';

interface Props {
    text: string;
    name: string;
    index: number;
    error?: string;
    isDisabledEditInput: boolean;
    handleChange: (event: ChangeEvent<HTMLInputElement>, index: number) => void;
}

export const InputWithTooltip: ReactFCC<Props> = ({ text, handleChange, index, isDisabledEditInput, error, name }) => {
    const id = v4();
    const ref = useRef<HTMLInputElement>(null);

    return (
        <>
            <div data-tooltip-id={id}>
                <Input
                    isEllipsis
                    disabled={!!isDisabledEditInput}
                    error={error}
                    name={name}
                    placeholder="Enter a possible answer here"
                    reference={ref}
                    type="text"
                    value={text}
                    onChange={e => handleChange(e, index)}
                />
            </div>
            {ref.current && isEllipsisActive(ref.current) && <Tooltip id={id} text={text} />}
        </>
    );
};

import axios from 'api/axios';

export const createDraft = (data: KIARA.UserDraftsRequest) =>
    axios<{
        createdAt: string;
        deletedAt: string;
        draft: string;
        id: string;
        isDeleted: boolean;
        updatedAt: string;
        user: string;
    }>({
        url: 'user-drafts',
        data
    });

export const updateDraft = (data: KIARA.UserDraftsRequest, id: string) =>
    axios<API.ErrorResponse>({
        method: 'PATCH',
        url: `user-drafts/${id}`,
        data
    });

export const getDrafts = () =>
    axios<KIARA.UserDraftsResponse[]>({
        method: 'GET',
        url: 'user-drafts/my'
    });

export const getDraft = (id: string) =>
    axios<KIARA.UserDraftsResponse>({
        method: 'GET',
        url: `user-drafts/${id}`
    });

export const deleteDraft = (id: string) =>
    axios<KIARA.SuccessAnswer>({
        method: 'DELETE',
        url: `user-drafts/${id}`
    });

import { type ButtonHTMLAttributes } from 'react';
import { type ReactFCC } from 'types/react';

import { StyledButton } from './styles';

export type ButtonVariant = 'primary' | 'secondary';

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
    variant: ButtonVariant;
    testId?: string;
}

export const Button: ReactFCC<ButtonProps> = ({ children, variant, disabled, testId, ...rest }) => (
    <StyledButton data-testid={testId} disabled={disabled} variant={variant} {...rest}>
        {children}
    </StyledButton>
);

import styled from 'styled-components';
import { hoveredTextColor, inputBorderColor } from 'constants/styles/colors';
import { buttonFontFontFamily } from 'components/common/buttons/SimpleButton/constants';
import { disableDefaultHStyleMixin } from 'constants/styles/mixins';
import { bodyTextFontFamilyMixin, headingsFontFamilyMixin } from 'constants/styles/fonts';

export const CopyErrorTextButton = styled.button`
    display: flex;
    align-items: center;
    padding: 13px 30px;
    background-color: white;
    border: 2px solid ${inputBorderColor};
    border-radius: 8px;
    color: ${hoveredTextColor};
    cursor: pointer;
    font-family: ${buttonFontFontFamily};
`;

export const Title = styled.h3`
    ${disableDefaultHStyleMixin};
    ${headingsFontFamilyMixin};
    margin-right: 16px;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 26px;
`;

export const Subtitle = styled.h4`
    ${disableDefaultHStyleMixin};
    ${bodyTextFontFamilyMixin};
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
`;

import { API } from 'api';
import { USER_LOCAL_STORAGE_NAME } from 'constants/local-storage';
import { createEvent, createStore } from 'effector';
import connectLocalStorage from 'effector-localstorage';
import { createNotifyingErrorEffect, createNotifyingSuccessEffect } from 'utils/store';

// * connect to localstorage
const userLocalStorage = connectLocalStorage(USER_LOCAL_STORAGE_NAME).onError(error => console.error(error));

// * events
const logout = createEvent();
const setUser = createEvent<API.AuthResponseDto>();

// * effects
const loadToken = createNotifyingErrorEffect({
    handler: async (data: API.LoginDto) => await API.user.authenticateUser(data)
});

const verifyAccount = createNotifyingErrorEffect({
    handler: async (token: string) => await API.user.verifyAccount(token)
});

const createUser = createNotifyingErrorEffect({
    handler: async (data: API.SignUpDto) => {
        const user = await API.user.createUser(data);

        return { user };
    }
});

const checkEmailForExistence = createNotifyingErrorEffect({
    handler: async (data: { email: string }) => await API.user.checkEmailForExistence(data)
});

const recoverPassword = createNotifyingErrorEffect({
    handler: async (data: API.ForgotPasswordDto) => await API.user.recoverPassword(data)
});

const updateUser = createNotifyingErrorEffect({
    handler: async (data: API.UpdateUserDto) => await API.user.updateUser(data)
});

const updateUserPassword = createNotifyingSuccessEffect({
    handler: async (data: API.UpdatePasswordDto) => await API.user.updateUserPassword(data)
});

const getMe = createNotifyingErrorEffect({
    handler: async () => await API.user.getMe()
});

const changeUserRole = createNotifyingErrorEffect({
    handler: async (data: { userId: string; role: string }) => await API.user.changeUserRole(data)
});

const changePassword = createNotifyingErrorEffect({
    handler: async (data: { token: string; password: string }) => await API.user.changePassword(data)
});

// * stores
const user = createStore<Partial<API.AuthResponseDto>>(userLocalStorage.init({}))
    .on(loadToken.doneData, (_, user) => user)
    .on(createUser.doneData, (_, user) => user)
    // .on(verifyAccount.doneData, (_, user) => user)
    .on(updateUser.doneData, (store, user) => ({ ...store, user }))
    .on(getMe.doneData, (store, user) => ({ ...store, user }))
    .on(logout, () => ({}))
    .on(setUser, (_, user) => user);
user.watch(userLocalStorage);

const doesEmailExist = createStore(false)
    .on(checkEmailForExistence.fail, (_, _data) => false)
    .on(checkEmailForExistence.done, (_, _data) => true);

// * exports
export const userEvents = { logout, setUser };

export const userEffects = {
    loadToken,
    createUser,
    checkEmailForExistence,
    updateUser,
    recoverPassword,
    updateUserPassword,
    getMe,
    changeUserRole,
    verifyAccount,
    changePassword
};

export const userStores = { user, doesEmailExist };

import styled, { css } from 'styled-components';

interface Props {
    disabled?: boolean;
}

export const StyledLogoImg = styled.div<Props>`
    width: 110px;
    height: 36px;
    cursor: pointer;

    ${({ disabled }) =>
        disabled &&
        css`
            cursor: default;
            pointer-events: none;
        `}
`;

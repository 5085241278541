import { Modal } from 'ui-kit/components/Modal';
import { useStore } from 'effector-react';
import { areYouSureModalStore } from 'stores/modals';
import { Button } from 'ui-kit/components/buttons/Button';
import { BodyContainer, ButtonsContainer, Text, Title } from './styles';
import { AreYouSureIcon } from './static_assets';
import type { ReactFCC } from 'types/react';

export const AreYouSureModal: ReactFCC = () => {
    const { modal, closeModal } = areYouSureModalStore;
    const [_, { title, text, handleSubmit }] = useStore(modal);

    return (
        <Modal visible>
            <BodyContainer>
                <AreYouSureIcon />
                <Title>{title}</Title>
                <Text>{text}</Text>
            </BodyContainer>
            <ButtonsContainer>
                <Button
                    variant="secondary"
                    onClick={() => {
                        closeModal();
                    }}
                >
                    Cancel
                </Button>
                <Button
                    variant="primary"
                    onClick={() => {
                        handleSubmit();
                        closeModal();
                    }}
                >
                    Delete
                </Button>
            </ButtonsContainer>
        </Modal>
    );
};

import { v4 as generateId } from 'uuid';

import { DraggableTypesEnum } from 'constants/builder/enums';
import type { QuestionnaireItems } from '../types';

export const addClauseVariationsHelper = (
    questions: QuestionnaireItems,
    blockId: string,
    clauseId: string,
    data: any[]
) => {
    let modifiedQuestions: QuestionnaireItems;

    const hasBlockVariations = questions.some(
        item => item.blockId === blockId && item.questionType === DraggableTypesEnum.Block
    );

    if (hasBlockVariations) {
        modifiedQuestions = questions.map(question => {
            if (question.clauseId === clauseId) {
                return {
                    ...question,
                    isShown: false
                };
            }

            return question;
        });
    } else {
        modifiedQuestions = questions.map(question => {
            if (question.clauseId === clauseId && question.questionType !== DraggableTypesEnum.Clause) {
                return {
                    ...question,
                    isShown: false
                };
            }

            return question;
        });
    }

    const hasClauseVariations = modifiedQuestions.find(
        item => item.clauseId === clauseId && item.questionType === DraggableTypesEnum.Clause
    );

    if (!hasClauseVariations) {
        return [
            ...modifiedQuestions,
            {
                title: data[0][0].title,
                id: generateId(),
                blockId,
                type: data[0][0].type,
                questionType: DraggableTypesEnum.Clause,
                clauseId,
                description: data[0][0].description,
                variationsField: data,
                isShown: !hasBlockVariations
            }
        ];
    }

    return questions.map(item => {
        if (item.clauseId === clauseId && item.questionType === DraggableTypesEnum.Clause) {
            return {
                ...item,
                title: data[0][0].title,
                type: data[0][0].type,
                description: data[0][0].description,
                variationsField: data
            };
        }

        return item;
    });
};

import { sample } from 'effector';
import { v4 as generateId } from 'uuid';
import { templatePagesStores } from './index';
import { generateTemplatePages } from './helpers/generate-template-pages';
import { designStores } from 'stores/builder/design';

sample({
    source: { allBlocks: templatePagesStores.allBlocks, designInfo: designStores.coverStyles },
    fn: ({ allBlocks, designInfo: { activeDesign } }) => generateTemplatePages(allBlocks, activeDesign),
    target: templatePagesStores.pages
});

sample({
    source: templatePagesStores.pages,
    fn: pages => Object.keys(pages).map(_ => generateId()),
    target: templatePagesStores.pagesIds
});

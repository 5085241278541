import { PrimaryButton, SecondaryButton } from 'components/common/buttons/SimpleButton';
import { P } from 'components/common/typography/titles/P';
import { AdaptiveGrid } from 'components/wrappers/grid/GridWrapper';
import { MarginWrapper } from 'components/wrappers/MarginWrapper';
import { Modal } from 'ui-kit/components/Modal';
import { currentDate } from 'constants/payment';
import { PRICES_LINK } from 'constants/routes';
import { hoveredTextColor } from 'constants/styles/colors';
import { useStore } from 'effector-react';
import { useTranslation } from 'react-i18next';
import { paymentModalStore, updateSubscriptionModalStore } from 'stores/modals';
import { userStores } from 'stores/user';
import { buttonWidth } from './constants';
import { useNavigate } from 'react-router-dom';
import type { ReactFCC } from 'types/react';

export const UpdateSubscriptionModal: ReactFCC = () => {
    const { modal, closeModal } = updateSubscriptionModalStore;
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [_, { price, isFromConstructor, isSign, templateId, contractName, previewUrl }] = useStore(modal);
    const { user } = useStore(userStores.user);

    const updateSubscription = () => {
        closeModal();
        navigate(`${PRICES_LINK}?isFromConstructor=${isFromConstructor}`);
    };

    const buyContract = () => {
        updateSubscriptionModalStore.closeModal();
        paymentModalStore.openModal({
            contractName,
            price,
            firstName: user?.firstName || '',
            lastName: user?.lastName || '',
            date: currentDate,
            userId: user?.id || '',
            isSign,
            templateId,
            previewUrl
        });
    };

    return (
        <Modal
            visible
            title={t('updateSubscriptionModal.updateSubscription') as string}
            width="555px"
            onClose={closeModal}
        >
            {/* TODO: improve */}
            <span>{t('updateSubscriptionModal.subtitle')}</span>

            <MarginWrapper marginTop="30px" />
            <AdaptiveGrid alignItems="center" xxl="1fr auto auto">
                <PrimaryButton width={buttonWidth} onClick={updateSubscription}>
                    {t('updateSubscriptionModal.primaryButton')}
                </PrimaryButton>

                <P color={hoveredTextColor}>or</P>

                <SecondaryButton width={buttonWidth} onClick={buyContract}>
                    {t('updateSubscriptionModal.secondaryButton')} {price}&#8364;
                </SecondaryButton>
            </AdaptiveGrid>
        </Modal>
    );
};

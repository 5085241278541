import axios from 'api/axios';

export const copyTemplate = async (data: KIARA.CopyTemplateRequest) => {
    const { templateName, location, companyId, templateId } = data;

    const newData = {
        location,
        companyId,
        templateName
    };

    return axios(
        {
            method: 'PATCH',
            url: `/templates/copy/${templateId}`,
            data: newData
        },
        true
    );
};

import { API } from 'api';
import { createNotifyingErrorEffect } from 'utils/store';

// * effects
const sendQuestion = createNotifyingErrorEffect({
    handler: async (data: API.FeedbackDto) => API.feedback.sendQuestion(data)
});

// * exports
export const feedbackEffects = {
    sendQuestion
};

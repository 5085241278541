import { companyEffects } from 'stores/company';
import { feedbackEffects } from 'stores/feedback';
import { ownTemplatesEffects } from 'stores/ownTemplates';
import { paymentEffects } from 'stores/payment';
import { pdfEffects } from 'stores/pdf';
import { roleEffects } from 'stores/role';
import { stripeEffects } from 'stores/stripe';
import { userEffects } from 'stores/user';
import { createIsLoadingStore } from 'utils/store';
import { loadingEvents } from './index';
import { copyTemplatesEffects } from 'stores/copyTemplates';
import { draftsEffects } from 'stores/drafts';
import { documentsEffects } from 'stores/documents';

const { createDraft, deleteDraft, getDraft, getDrafts } = draftsEffects;

// * stores
const isLoading = createIsLoadingStore(
    Object.values({
        ...companyEffects,
        ...feedbackEffects,
        ...paymentEffects,
        ...pdfEffects,
        ...roleEffects,
        ...stripeEffects,
        ...userEffects,
        ...ownTemplatesEffects,
        ...copyTemplatesEffects,
        ...documentsEffects,
        createDraft,
        deleteDraft,
        getDraft,
        getDrafts
    })
)
    .on(loadingEvents.setIsLoading, () => true)
    .reset(loadingEvents.resetLoading);

// * exports
export const loadingStores = {
    isLoading
};

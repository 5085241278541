import { secondaryTextColor } from 'constants/styles/colors';
import { headingsFontFamilyMixin } from 'constants/styles/fonts';
import { disableDefaultHStyleMixin } from 'constants/styles/mixins';
import styled from 'styled-components';

export const DescriptionWrapper = styled.div`
    ${disableDefaultHStyleMixin};
    ${headingsFontFamilyMixin};
    font-size: 16px;
    max-height: 485px;
    ${({ color }) => color && `color: ${color}`};

    & a {
        color: ${secondaryTextColor};

        :hover {
            text-decoration: underline;
        }
    }
`;

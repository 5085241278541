import { StyledTooltip } from './styles';
import type { ReactFCC } from 'types/react';
import 'react-tooltip/dist/react-tooltip.css';

export interface TooltipProps {
    text: string;
    bigShadow?: boolean;
    id: string;
    place?: any;
}

export const Tooltip: ReactFCC<TooltipProps> = ({ id, text, bigShadow }) =>
    text ? <StyledTooltip bigShadow={bigShadow} className="example" content={text} id={id} /> : null;

import type { Styles } from 'react-modal';

export const closeIconDiameter = '10px';

export const content: Styles['content'] = {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: '8px',
    maxHeight: '85vh',
    maxWidth: '85vw',
    zIndex: 1000,
    padding: '0',
    overflow: 'none',
    display: 'flex',
    flexDirection: 'column'
};

export const overlay = { backgroundColor: 'rgba(0,0,0,0.6)', zIndex: 1000 };

export const borderStyles = '1px solid #F3F3F3';

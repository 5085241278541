import { headerHeight } from 'constants/styles/sizes';

export const wrapperHeight = '72px';
export const wrapperBorderRadius = '6px';
export const wrapperVerticalPadding = '28px';
export const wrapperHorizontalPadding = '35px';
export const transitionDuration = '0.5s';
export const translateY = parseInt(wrapperHeight) + parseInt(headerHeight) + 16 + 'px';

export const successImgDiameter = '24px';

import { bodyTextFontFamilyMixin } from 'constants/styles/fonts';
import { disableDefaultHStyleMixin } from 'constants/styles/mixins';
import styled from 'styled-components';
import type { Color } from 'types/styles';

export const P = styled.p<Color>`
    ${disableDefaultHStyleMixin};
    ${bodyTextFontFamilyMixin};
    font-size: 16px;
    line-height: 32px;
    ${({ color }) => color && `color: ${color}`};
`;

import { contentWrapperBorderRadius } from 'components/wrappers/ContentWrapper/constants';
import styled from 'styled-components';
import type {
    BackgroundColor,
    BorderProperties,
    BorderRadius,
    BoxShadow,
    Margin,
    MaxSizes,
    MinSizes,
    Overflow,
    Padding,
    Pointer,
    Sizes
} from 'types/styles';

export interface ContentWrapperProps
    extends Sizes,
        Padding,
        MinSizes,
        MaxSizes,
        BackgroundColor,
        BorderRadius,
        Margin,
        BoxShadow,
        Overflow,
        Pointer,
        BorderProperties {
    isRelative?: boolean;
}

export const ContentWrapper = styled.div<ContentWrapperProps>`
    ${({ isRelative }) => isRelative && `position: relative`};
    ${({ height }) => height && `height: ${height}`};
    ${({ width }) => width && `width: ${width}`};
    ${({ minWidth }) => `min-width: ${minWidth}`};
    ${({ maxWidth }) => `max-width: ${maxWidth}`};
    ${({ minHeight }) => minHeight && `min-height: ${minHeight}`};
    ${({ maxHeight }) => maxHeight && `max-height: ${maxHeight}`};
    ${({ boxShadow }) => boxShadow && `box-shadow: ${boxShadow}`};
    border-radius: ${({ borderRadius }) => borderRadius || contentWrapperBorderRadius};
    background-color: ${({ backgroundColor }) => backgroundColor || 'white'};
    ${({ border }) => border && `border: ${border}`};
    ${({ paddingRight }) => paddingRight && `padding-right: ${paddingRight}`};
    ${({ paddingLeft }) => paddingLeft && `padding-left: ${paddingLeft}`};
    ${({ paddingTop }) => paddingTop && `padding-top: ${paddingTop}`};
    ${({ paddingBottom }) => paddingBottom && `padding-bottom: ${paddingBottom}`};
    ${({ padding }) => padding && `padding: ${padding}`};
    ${({ marginRight }) => marginRight && `margin-right: ${marginRight};`};
    ${({ marginBottom }) => marginBottom && `margin-bottom: ${marginBottom};`};
    ${({ marginTop }) => marginTop && `margin-top: ${marginTop};`};
    ${({ pointer }) => pointer && `cursor: pointer;`};
    ${({ overflow }) => overflow && `overflow: ${overflow}`};
`;

import { DescriptionBadge } from 'components/common/DescriptionBadge';
import { getValidDate } from './utils';
import { DatePickerContainer, PlaceholderSpan } from './styles';
import { InputTitle } from 'components/common/typography/Span';
import { Column } from 'components/wrappers/grid/FlexWrapper';
import { format } from 'date-fns';
import { useEffect, useState } from 'react';
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useToggle } from 'react-use';

export interface DatePickerProps {
    dateRange?: [string, string];
    defaultDate?: string;
    onChange?: (date: string) => void;
    titleColor?: string;
    description?: string;
    disabled?: boolean;
    title?: string;
    subtitle?: string;
    placeholder?: string;
}

export const DatePicker = ({
    titleColor,
    dateRange,
    defaultDate = '',
    placeholder = '',
    onChange,
    disabled,
    title,
    description
}: DatePickerProps) => {
    const [isCalendarOpened, updateIsCalendarOpened] = useToggle(false);

    const [date, setDate] = useState<Date | null | undefined>(null);

    const onInputClick = () => updateIsCalendarOpened();
    const onClickOutside = () => updateIsCalendarOpened();

    const onChangeDate = (date: Date) => {
        setDate(date);
        onChange?.(format(date, 'dd/MM/yyyy'));
        updateIsCalendarOpened();
    };

    useEffect(() => {
        if (!defaultDate) {
            return;
        }
        setDate(getValidDate(defaultDate));
    }, [defaultDate]);

    return (
        <Column width="100%">
            <InputTitle color={titleColor}>
                {title} {description && <DescriptionBadge description={description} />}
            </InputTitle>
            <ReactDatePicker
                customInput={
                    <DatePickerContainer placeholder="ALO">
                        {date ? (
                            format(date, 'dd/MM/yyyy')
                        ) : defaultDate ? (
                            defaultDate
                        ) : (
                            <PlaceholderSpan>{placeholder}</PlaceholderSpan>
                        )}
                    </DatePickerContainer>
                }
                disabled={disabled}
                maxDate={dateRange?.[1] ? new Date(dateRange[1]) : undefined}
                minDate={dateRange?.[0] ? new Date(dateRange[0]) : undefined}
                open={!disabled && isCalendarOpened}
                popperPlacement="bottom"
                selected={date}
                wrapperClassName="date_picker full-width"
                onChange={onChangeDate}
                onClickOutside={onClickOutside}
                onInputClick={onInputClick}
            />
        </Column>
    );
};

import type { PreviewThemes } from 'types/preview';

export const previewThemes: PreviewThemes = {
    cover1: [
        { first: '#EF5818', second: '', third: '#000', fourth: '#fff' },
        { first: '#F0F1F8', second: '', third: '#000', fourth: '' }
    ],
    cover2: [
        { first: '#00417D', second: '#D9EDFF', third: '#00417D', fourth: '#fff' },
        { first: '#eff7ff', second: '#D9EDFF', third: '#000000', fourth: '#fff' },
        { first: '#D9EDFF', second: '#00417D', third: '#EF5818', fourth: '#fff' }
    ],
    cover3: [
        { first: '#267667', second: '', third: '#000', fourth: '#FFF' },
        { first: '#f1fffc', second: '', third: '#000', fourth: '#267667' }
    ],
    cover4: [
        { first: '#002D56', second: '', third: '#00417D', fourth: '#FFF' },
        { first: '#e9ebff', second: '', third: '#00417D', fourth: '#00417D' }
    ]
};

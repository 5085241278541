import type { Stripe, StripeElements } from '@stripe/stripe-js';
import { API } from 'api';
import { CardCreationTabsEnum, PaymentTypesEnum, SubscriptionStatusEnum } from 'constants/enums';
import { createEvent, restore } from 'effector';
import { message } from 'stores/alerts';
import { areYouSureModalStore, buyContractModalStore } from 'stores/modals';
import { pdfEvents } from 'stores/pdf';
import { stripeEffects } from 'stores/stripe';
import { userEffects, userStores } from 'stores/user';
import { createNotifyingErrorEffect, createNotifyingSuccessEffect } from 'utils/store';

const { resetFileId } = pdfEvents;
const { createContractWithSubscription } = stripeEffects;

export interface StripeParams {
    elements: StripeElements;
    stripe: Stripe;
    isIdeal: boolean;
    userName: string;
    isSign: boolean;
}

interface PaymentTypes {
    type: PaymentTypesEnum;
    isSign: boolean;
    price: number;
    templateId: string;
    contractName: string;
    previewUrl?: string;
}

// * events
const setStripeParams = createEvent<StripeParams>();
const resetStripeParams = createEvent();
const setPaymentType = createEvent<PaymentTypes>();
const resetPaymentType = createEvent();
const setCardsTab = createEvent<CardCreationTabsEnum>();
const resetCardsTab = createEvent();
const buyContract = createEvent<string>();
const confirmStripePayment = createEvent<string>();

// * effects
const createPaymentMethod = createNotifyingSuccessEffect({
    handler: async (data: API.CreatePaymentMethodDto) => await API.stripe.createPaymentMethod(data)
});

const updateUserDefaultPaymentMethod = createNotifyingErrorEffect({
    handler: async (data: API.UpdateCustomerDto) => {
        const id = userStores.user.getState().user?.stripeCustomerId;

        return await API.stripe.updateUserDefaultPaymentMethod(data, id);
    }
});

const getProductsPrice = createNotifyingErrorEffect({
    handler: async () => await API.stripe.getProductsPrice()
});

const getProSubscriptionInfo = createNotifyingErrorEffect({
    handler: async () => await API.stripe.getProSubscriptionInfo()
});

const createSubscription = createNotifyingErrorEffect({
    handler: async (id: string) => {
        const { user, accessToken } = userStores.user.getState();

        if (!accessToken) {
            // TODO: navigate(authLink)
            return;
        }

        if (!user?.defaultPaymentMethod) {
            message.error('Please add payment method');
            // TODO: navigate(`${settingsLink}?tab=payment`)
            return;
        }

        if (
            !user?.subscription ||
            user?.subscription?.metadata?.subscription?.status === SubscriptionStatusEnum.Canceled
        ) {
            return await API.stripe.createSubscription({
                customerId: user?.stripeCustomerId || '',
                priceId: id,
                userId: user?.id || ''
            });
        }

        return;
    }
});

const updateSubscription = createNotifyingErrorEffect({
    handler: async (id: string) => {
        const { user, accessToken } = userStores.user.getState();

        if (!accessToken) {
            // TODO: navigate(authLink)
            return;
        }

        if (!user?.defaultPaymentMethod) {
            message.error('Please add payment method');
            // TODO: navigate(`${settingsLink}?tab=payment`)
            return;
        }

        if (user?.subscription?.metadata.subscription.id) {
            return await API.stripe.updateSubscription(user?.subscription?.metadata.subscription.id, {
                priceId: id,
                userId: user?.id || ''
            });
        }

        return;
    }
});

const cancelSubscription = createNotifyingErrorEffect({
    handler: async () => {
        const { user } = userStores.user.getState();

        if (user?.subscription?.metadata.subscription.id) {
            return await API.stripe.deleteSubscription(user?.subscription?.metadata.subscription.id);
        }
    }
});

const deletePaymentMethod = createNotifyingSuccessEffect({
    handler: async (id: string) => await API.stripe.deletePaymentMethod(id)
});

// * stores
const productsList = restore<API.ApiProductsList>(getProductsPrice.doneData, null);
const proSubscriptionInfo = restore(getProSubscriptionInfo.doneData, null);

const activeCardTab = restore(setCardsTab, CardCreationTabsEnum.Cards).reset(resetCardsTab);

const paymentType = restore(setPaymentType, {
    type: PaymentTypesEnum.FromStripe,
    isSign: false,
    price: 0,
    templateId: '',
    contractName: ''
}).reset(resetPaymentType);

const stripeParams = restore<StripeParams>(setStripeParams, null).reset(resetStripeParams);

// * watchers
cancelSubscription.doneData.watch(() => {
    userEffects.getMe(null);
    areYouSureModalStore.closeModal();
});

updateSubscription.doneData.watch(() => {
    message.success('Success');
    areYouSureModalStore.closeModal();
    userEffects.getMe(null);
});

createContractWithSubscription.failData.watch(() => {
    resetFileId();
    message.error('Payment failed');
});

createContractWithSubscription.doneData.watch(() => {
    resetFileId();
    buyContractModalStore.closeModal();
    message.success('Payment succeeded');
    // TODO: navigate(`${settingsLink}?tab=payment`)
    userEffects.getMe(null);
    pdfEvents.resetPdf();
});

createSubscription.doneData.watch(data => {
    if (data) {
        message.success('You created subscription');
        userEffects.getMe(null);
    }
});

deletePaymentMethod.doneData.watch(() =>
    stripeEffects.getCustomerCards({ customerId: userStores.user.getState().user?.stripeCustomerId })
);

createPaymentMethod.doneData.watch(() => {
    setCardsTab(CardCreationTabsEnum.Cards);
    userEffects.getMe(null);
    stripeEffects.getCustomerCards({ customerId: userStores.user.getState().user?.stripeCustomerId });
});

updateUserDefaultPaymentMethod.doneData.watch(() => {
    stripeEffects.getCustomerCards({ customerId: userStores.user.getState().user?.stripeCustomerId });
});

export const paymentEvents = {
    setStripeParams,
    resetStripeParams,
    setPaymentType,
    setCardsTab,
    resetCardsTab,
    buyContract,
    confirmStripePayment,
    resetPaymentType
};

export const paymentEffects = {
    createPaymentMethod,
    updateUserDefaultPaymentMethod,
    getProductsPrice,
    createSubscription,
    deletePaymentMethod,
    getProSubscriptionInfo,
    updateSubscription,
    cancelSubscription
};

export const paymentStores = {
    stripeParams,
    productsList,
    activeCardTab,
    proSubscriptionInfo,
    paymentType
};

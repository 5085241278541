import { createEvent, createStore } from 'effector';

import {
    type Pallet,
    type PreviewInfo,
    type PreviewFonts,
    type PreviewThemes,
    ActivePalletIndex,
    ActivePreviewDesign
} from 'types/preview';
import { previewThemes } from 'pages/Magic/Steps/constants';

// * events
const changeActiveDesign = createEvent<number>();
const changeDocumentTheme = createEvent<Pallet>();
const changeThemes = createEvent<PreviewThemes>();
const changeDocumentFonts = createEvent<PreviewFonts>();
const changePalletIndex = createEvent<ActivePalletIndex>();
const resetCoverStyles = createEvent();

// * stores
const coverStyles = createStore<PreviewInfo>({
    activeDesign: ActivePreviewDesign.First,
    themes: previewThemes,
    palletIndex: ActivePalletIndex.First,
    documentTheme: {
        first: '#EF5818',
        second: '#F07540',
        third: '#000',
        fourth: '#fff'
    },
    documentFonts: {
        heading: '',
        body: ''
    }
}).reset(resetCoverStyles);

coverStyles
    .on(changeDocumentTheme, (data: PreviewInfo, payload: Pallet) => ({
        ...data,
        documentTheme: payload
    }))
    .on(changeThemes, (data: PreviewInfo, payload: PreviewThemes) => ({
        ...data,
        themes: payload
    }))
    .on(changePalletIndex, (data: PreviewInfo, payload: ActivePalletIndex) => ({
        ...data,
        palletIndex: payload
    }))
    .on(changeActiveDesign, (data: PreviewInfo, payload: ActivePreviewDesign) => ({
        ...data,
        activeDesign: payload
    }))
    .on(changeDocumentFonts, (data: PreviewInfo, payload: PreviewFonts) => ({
        ...data,
        documentFonts: payload
    }));

// * exports
export const designEvents = {
    changeActiveDesign,
    changeDocumentTheme,
    changeDocumentFonts,
    changeThemes,
    changePalletIndex,
    resetCoverStyles
};

export const designStores = {
    coverStyles
};

import { useStore } from 'effector-react';

import { type ReactFCC } from 'types/react';
import { DescriptionWrapper } from './styles';
import { Modal } from 'ui-kit/components/Modal';
import { descriptionModalStore } from 'stores/modals';

export const DescriptionModal: ReactFCC = () => {
    const { modal, closeModal } = descriptionModalStore;
    const [_, { description }] = useStore(modal);

    return (
        <Modal visible width="700px" onClose={closeModal}>
            <DescriptionWrapper dangerouslySetInnerHTML={{ __html: description }} />
        </Modal>
    );
};

import styled, { css } from 'styled-components';

interface StyledInputProps {
    error?: string;
    touched?: boolean;
    value?: string;
    disabled?: boolean;
    isEllipsis?: boolean;
}

const styledInputMixin = css<StyledInputProps>`
    width: 100%;
    height: 48px;
    border-radius: 10px;
    border: 1px solid var(--gray-60);
    padding: 12px 16px;

    :focus {
        border-color: ${({ error }) => (error ? 'var(--danger)' : 'var(--purple-60)')};
    }

    &::placeholder {
        color: #cdd0d9;
    }

    ${({ touched, error }) => touched && error && `border-color: var(--danger)`};
`;

const textInputMixin = css`
    font-size: 16px;
    font-weight: 400;
`;

export const Container = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
`;

export const Content = styled.div`
    position: relative;
`;

export const StyledInput = styled.input<StyledInputProps>`
    outline: none;
    appearance: none;
    border: none;
    ${styledInputMixin};
    ${textInputMixin};

    ::-webkit-outer-spin-button,
    ::-webkit-inner-spin-button {
        -webkit-appearance: none;
    }

    ${({ isEllipsis }) =>
        isEllipsis &&
        css`
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
        `};
`;

export const ShowPasswordIconContainer = styled.div`
    position: absolute;
    right: 16px;
    top: 16px;
    cursor: pointer;
`;

import axios from 'api/axios';

export const createDocument = (data: KIARA.DocumentRequest) =>
    axios({
        method: 'POST',
        url: 'documents',
        data
    });

export const getDocuments = () =>
    axios<KIARA.DocumentResponse[]>({
        method: 'GET',
        url: 'documents'
    });

export const getSingleDocument = (id: string) =>
    axios({
        method: 'GET',
        url: `documents/${id}`
    });

export const deleteDocument = (id: string) =>
    axios({
        method: 'DELETE',
        url: `documents/${id}`
    });

import { API } from 'api';
import { createEvent, restore } from 'effector';
import type { JoinUserData } from 'pages/JoinToCompany/types';
import queryString from 'qs';
import { createNotifyingErrorEffect } from 'utils/store';
import type { GetCompanyUsersProps } from './types';

// * events
const inviteUserEvent = createEvent<{ email: string; role: 'employees' | 'company-admin'; companyId: string }>();
const resetCompany = createEvent();

// * effects
const getCompany = createNotifyingErrorEffect({
    handler: async (id: string) => await API.company.getCompany(id)
});

const inviteUser = createNotifyingErrorEffect({
    handler: async (data: API.InviteDto) => await API.company.inviteUser(data)
});

const addCompany = createNotifyingErrorEffect({
    handler: async (data: API.RegisterCompanyDto) => await API.company.addCompany(data)
});

const joinUser = createNotifyingErrorEffect({
    handler: async ({ data, companyId }: { data: JoinUserData; companyId: string }) =>
        await API.company.joinUser(data, companyId)
});

const getCompanyTemplates = createNotifyingErrorEffect({
    handler: async (data: { page: number; limit: number }) => {
        const { page, limit } = data;
        const newQuery = queryString.stringify({
            page,
            limit
        });

        return await API.templates.getCompanyTemplates(newQuery);
    }
});

const createCompanyTemplate = createNotifyingErrorEffect({
    handler: async (data: API.CreateCompanyTemplateDto) => await API.templates.createCompanyTemplate(data)
});

const deleteCompanyTemplate = createNotifyingErrorEffect({
    handler: async (id: string) => await API.templates.deleteCompanyTemplate(id)
});

const getCompanyUsers = createNotifyingErrorEffect({
    handler: async ({ companyId, limit, page, roleId, search }: GetCompanyUsersProps) => {
        const newQuery = queryString.stringify({
            page,
            limit,
            companyId,
            roleId,
            search
        });

        return await API.company.getCompanyUsers(newQuery);
    }
});

const deleteCompanyUser = createNotifyingErrorEffect({
    handler: async ({ userId }: { userId: string }) => await API.company.deleteCompanyUser(userId)
});

const editCompanyTemplate = createNotifyingErrorEffect({
    handler: async ({ id, data }: { id: string; data: API.UpdateCompanyTemplateDto }) =>
        await API.templates.editCompanyTemplate(id, data)
});

const updateCompanyInfo = createNotifyingErrorEffect({
    handler: async ({ data, companyId }: { data: API.UpdateCompanyDto; companyId: string }) =>
        await API.company.updateCompanyInfo(data, companyId)
});

// * stores
const company = restore<API.Company>(getCompany.doneData, null).reset(resetCompany);
// TODO: update any when API.GetManyResponseForUser is updated
const companyUsers = restore<any>(getCompanyUsers.doneData, null);
const companyTemplates = restore<API.GetManyResponseForCompanyTemplates>(getCompanyTemplates.doneData, null);

// * exports
export const companyEvents = {
    inviteUserEvent,
    resetCompany
};

export const companyStores = {
    company,
    companyUsers,
    companyTemplates
};

export const companyEffects = {
    getCompany,
    inviteUser,
    joinUser,
    addCompany,
    getCompanyUsers,
    deleteCompanyUser,
    getCompanyTemplates,
    deleteCompanyTemplate,
    createCompanyTemplate,
    updateCompanyInfo,
    editCompanyTemplate
};

import { useStore } from 'effector-react';

import { type ReactFCC } from 'types/react';
import { Modal } from 'ui-kit/components/Modal';
import { stripeModalStore } from 'stores/modals';
import { StripeForm } from 'components/forms/StripeForm';

const { closeModal, modal } = stripeModalStore;

export const StripeModal: ReactFCC = () => {
    const [visible, { isIdeal, isSign, templateId, contractName, price, previewUrl }] = useStore(modal);

    return (
        <Modal title={isIdeal ? 'Ideal payment' : 'Visa card payment'} visible={visible} onClose={closeModal}>
            <StripeForm
                contractName={contractName}
                isIdeal={isIdeal}
                isSign={isSign}
                previewUrl={previewUrl}
                price={price}
                templateId={templateId}
            />
        </Modal>
    );
};

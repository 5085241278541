import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
`;

export const Hr = styled.hr`
    width: 100%;
    position: relative;
    left: -18px;
    height: 1px;
    margin-bottom: 18px;
    border-top: 1px solid var(--gray-60);
`;

export const Content = styled.div`
    &:last-child {
        padding-bottom: 16px;
    }
`;

export const Item = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 12px;
    gap: 8px;
`;

export const Title = styled.span`
    white-space: nowrap;
    font-size: 14px;
`;

export const Answer = styled.div`
    width: 100%;

    & > div {
        width: 100%;
    }
`;

export const SelectContainer = styled.div`
    width: 100px;
    flex-shrink: 0;
`;

export const MultiselectContainer = styled.div`
    width: 100%;
`;

import {
    transitionDuration,
    translateY,
    wrapperBorderRadius,
    wrapperHeight,
    wrapperHorizontalPadding,
    wrapperVerticalPadding
} from 'components/modals/Notification/constants';
import { errorColor } from 'constants/styles/colors';
import { headingsFontFamilyMixin } from 'constants/styles/fonts';
import { flexCenter, primaryShadowMixin } from 'constants/styles/mixins';
import styled from 'styled-components';

interface Props {
    active?: boolean;
}

export const Wrapper = styled.div<Props>`
    position: fixed;
    top: -${wrapperHeight};
    right: ${wrapperHorizontalPadding};
    transition-duration: ${transitionDuration};
    transition-property: transform, box-shadow, opacity;
    z-index: 1001;

    ${({ active }) =>
        active &&
        `
            transform: translateY(${translateY});
        `}
`;

export const StyledNotification = styled.div<Props>`
    ${flexCenter};
    flex-wrap: nowrap;
    flex-direction: row;
    height: ${wrapperHeight};
    border-radius: ${wrapperBorderRadius};
    background-color: white;
    padding: ${wrapperVerticalPadding} ${wrapperHorizontalPadding};
    opacity: 0;
    transition-duration: ${transitionDuration};
    transition-property: transform, box-shadow, opacity;

    ${({ active }) => active && `${primaryShadowMixin}; opacity: 1;`};
`;

interface ContentTextProp {
    error?: boolean;
}

export const ContentText = styled.span<ContentTextProp>`
    ${headingsFontFamilyMixin};
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    ${({ error }) => error && `color: ${errorColor}`};
`;

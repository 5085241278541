import { type EditorProps, Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { type RefObject, type MouseEventHandler } from 'react';

import { Container, StyledButton } from './styles';
import { type ReactFCC } from 'types/react';

interface Props extends EditorProps {
    forDescription?: boolean;
    onSave?: MouseEventHandler<HTMLButtonElement>;
    refik?: RefObject<Editor>;
}

export const WysiwygEditor: ReactFCC<Props> = ({
    editorState,
    onEditorStateChange,
    onContentStateChange,
    onSave,
    refik,
    placeholder,
    readOnly,
    forDescription,
    ...rest
}) => {
    const withSaveButton = onSave && !readOnly;

    return (
        <Container forDescription={forDescription} withSaveButton={withSaveButton}>
            {withSaveButton && (
                <StyledButton variant="primary" onClick={onSave}>
                    Save
                </StyledButton>
            )}
            <Editor
                ref={refik}
                editorClassName="editor"
                editorState={editorState}
                placeholder={placeholder || 'Enter a text...'}
                readOnly={readOnly}
                toolbar={{
                    options: ['inline', 'link'],
                    inline: {
                        bold: {
                            className: 'editor-icon'
                        },
                        italic: { className: 'editor-icon' },
                        underline: { className: 'editor-icon' },
                        strikethrough: { className: 'editor-icon' },
                        monospace: { className: 'editor-icon' },
                        superscript: { className: 'editor-icon' },
                        subscript: { className: 'editor-icon' }
                    },
                    link: {
                        link: { className: 'editor-link' },
                        unlink: { className: 'editor-link' }
                    }
                }}
                toolbarClassName="editor-toolbar"
                wrapperClassName="editor-wrapper"
                onContentStateChange={onContentStateChange}
                onEditorStateChange={onEditorStateChange}
                {...rest}
            />
        </Container>
    );
};

import type { AddClauseVariationsProps, BlockState } from '../types';

export const addClauseVariationsReducer = (
    state: BlockState,
    { data, blockId, clauseId }: AddClauseVariationsProps
) => ({
    ...state,
    blocks: state.blocks.map(block =>
        block.id === blockId
            ? {
                  ...block,
                  groups: [
                      {
                          fields: block.groups[0].fields.map(field =>
                              field.id === clauseId
                                  ? {
                                        ...field,
                                        variationsField: { ...data },
                                        content: field.content.map(item => ({ ...item, show: false })),
                                        show: false
                                    }
                                  : field
                          )
                      }
                  ]
              }
            : block
    )
});

import queryString from 'qs';
import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

export const useQuery = <T>() => {
    const location = useLocation();
    const query = useMemo(
        () => queryString.parse(location.search, { ignoreQueryPrefix: true }) as unknown as T,
        [location.search]
    );

    return query;
};

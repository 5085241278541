import { API } from 'api';
import { createEffect, createEvent, createStore } from 'effector';
import { createNotifyingErrorEffect, createNotifyingSuccessEffect } from 'utils/store';

// * events
const resetTemplates = createEvent();

const resetTemplate = createEvent();

// * effects
const getTemplates = createNotifyingErrorEffect({ handler: async () => await API.ownTemplates.getTemplates() });

const createTemplate = createEffect({
    handler: async (data: KIARA.TemplateRequest) => await API.ownTemplates.createTemplate(data)
});

const updateTemplate = createNotifyingSuccessEffect({
    handler: async ({ data, id }: { id: string; data: KIARA.TemplateRequest }) =>
        await API.ownTemplates.updateTemplate(id, data)
});

const deleteTemplate = createNotifyingSuccessEffect({
    handler: async (id: string) => await API.ownTemplates.deleteTemplate(id)
});

const getTemplate = createNotifyingErrorEffect({
    handler: async (id: string) => await API.ownTemplates.getTemplate(id)
});

// * stores
const templates = createStore<KIARA.TemplateResponse[]>([])
    .on(getTemplates.doneData, (_, payload) => payload)
    .reset(resetTemplates);

const template = createStore<KIARA.TemplateResponse | null>(null)
    .on(getTemplate.doneData, (_, payload) => payload)
    .reset(resetTemplate);

// * exports
export const ownTemplatesEvents = {
    resetTemplate,
    resetTemplates
};

export const ownTemplatesEffects = {
    getTemplate,
    getTemplates,
    deleteTemplate,
    updateTemplate,
    createTemplate
};

export const ownTemplatesStores = {
    templates,
    template
};

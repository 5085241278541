import englishFlagSrc from './static_assets/english.svg';
import dutchFlagSrc from './static_assets/netherlands.svg';
import { CustomImage } from 'components/common/imgComponents/CustomImg/styles';

export const headerButtonWidth = '120px';
export const headerPaddingTop = '22px';
export const headerWidth = '420px';
export const headerHeight = '80px';

export const languages = [
    {
        label: 'English',
        icon: <CustomImage height="18px" src={englishFlagSrc} width="18px" />,
        value: 'en'
    },
    {
        label: 'Nederlands',
        icon: <CustomImage height="18px" src={dutchFlagSrc} width="18px" />,
        value: 'du'
    }
];

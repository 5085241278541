import { useEffect, useRef, useState } from 'react';
import { stateFromHTML } from 'draft-js-import-html';
import { stateToHTML } from 'draft-js-export-html';
import { useStore } from 'effector-react';
import { v4 as generateId } from 'uuid';
import { EditorState } from 'draft-js';

import { Footer } from './Footer';
import { Edit } from './tabs/Edit';
import { type ReactFCC } from 'types/react';
import { WysiwygEditor } from '../WysiwygEditor';
import { blockEvents } from 'stores/builder/block';
import { serializeForm } from 'utils/serialize-form';
import { getDefaultShowOrHideString } from './tabs/Logic/utils';
import { fieldEvents, fieldStores } from 'stores/builder/field';
import { editorEvents, editorStores } from 'stores/builder/editor';
import { Container, Content, EditorContainer, Form } from './styles';
import { EditorLocationEnum, FieldTypesEnum } from 'constants/builder/enums';
import { getSelectedValue, getVariationItems, getVariationTitle } from './utils';
import { type FieldItem, type FormFieldsErrors, type VariationItems } from './types';
import { type BlockInterface, type Fields } from 'types/builder/interface';
import { hideOrShowEvents, hideOrShowStores } from 'stores/builder/hideOrShowItems';
import { SelectMultiSelectRadioEditor } from './editors/SelectMultiSelectRadioEditor';
import { type AddOrRemoveButtonProps, VariationsEditor } from './editors/VariationsEditor';

interface Props {
    onClose: () => void;
    clause?: Fields;
    textId?: string;
    blockId?: string;
    clauseId?: string;
    blockTitle?: string;
    selectedField?: any;
    variationsField?: Fields['variationsField'];
    variationsBlock?: BlockInterface['variationsBlock'];
}

export interface RadioItem {
    text: string;
    value: string;
    type: FieldTypesEnum;
}

export const RichComponent: ReactFCC<Props> = ({
    clause,
    onClose,
    blockTitle,
    textId = '',
    blockId = '',
    clauseId = '',
    selectedField,
    variationsBlock,
    variationsField
}) => {
    const showOrHide = useStore(hideOrShowStores.showOrHide);
    const multiSelectValues = useStore(hideOrShowStores.multiSelectValues);
    const location = useStore(editorStores.location);
    const editorState = useStore(editorStores.editorState);
    const { setData } = fieldEvents;
    const prevState = useStore(fieldStores.data);

    const [activeTab, setActiveTab] = useState('edit');

    const [selectedValue, setSelectedValue] = useState<FieldTypesEnum>(() =>
        getSelectedValue({
            location,
            selectedField,
            variationsBlock,
            variationsField
        })
    );
    const [variationItems, setVariationItems] = useState<VariationItems[]>(() =>
        getVariationItems(variationsBlock, variationsField)
    );
    const [multiSelectItems, setMultiSelectItems] = useState<FieldItem[]>(
        selectedField?.multiSelectItems || [{ text: '', value: '', type: FieldTypesEnum.Text, id: generateId() }]
    );
    const [singleSelectItems, setSingleSelectItems] = useState<FieldItem[]>(
        selectedField?.singleSelectItems || [{ text: '', value: '', type: FieldTypesEnum.Text, id: generateId() }]
    );
    const [radioItems, setRadioItems] = useState<FieldItem[]>(
        selectedField?.singleRadioItems || [{ text: '', value: '', type: FieldTypesEnum.Text, id: generateId() }]
    );

    const formRef = useRef<HTMLFormElement>(null);
    const [errors, setErrors] = useState<FormFieldsErrors[] | []>([]);
    const [formValues, setFormValues] = useState<{ [key: string]: string }>();
    const [variationsBlockDescription] = useState(() => {
        if (variationsBlock) {
            return variationsBlock[0][0].description || '';
        }

        return '';
    });
    const [variationsFieldDescription] = useState(() => {
        if (variationsField) {
            return variationsField[0][0].description || '';
        }

        return '';
    });

    const isAddingBlockVariations = location === EditorLocationEnum.Block;
    const isAddingClauseVariations = location === EditorLocationEnum.Clause;

    const handleGetFormValues = () => {
        if (formRef.current) {
            const data = serializeForm(formRef.current);
            setFormValues(data);
            const errors: FormFieldsErrors[] = [];
            Object.keys(data).forEach(key => {
                if (!data[key]) {
                    errors.push({ name: key, error: 'Field is required' });
                }
            });

            setErrors(errors);

            return errors;
        }
    };

    const onTabChange = (tab: string) => {
        switch (tab) {
            case 'logic':
                const errors = handleGetFormValues();
                if (errors?.length) {
                    return setActiveTab('edit');
                }
                return setActiveTab('logic');
            default:
                setErrors([]);
                setActiveTab(tab);
        }
    };

    const handleSave = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const descriptionHtml = stateToHTML(editorState.getCurrentContent());
        const plainText = editorState.getCurrentContent().getPlainText();
        const description = plainText ? descriptionHtml : '';
        const data: { [key: string]: string } =
            location !== EditorLocationEnum.Field ? formValues || {} : serializeForm(event.currentTarget);

        if (isAddingBlockVariations) {
            const generateRadios = Object.keys(data)
                .filter(item => item.startsWith('radio') && item.endsWith('text'))
                .map((radioItem, index) => ({
                    text: data[radioItem],
                    isDefault: false,
                    value: {
                        type: FieldTypesEnum.Text,
                        value: showOrHide[index]?.value
                    }
                }));

            blockEvents.addBlockVariationsEvent({
                blockId,
                blockTitle: blockTitle || '',
                data: [
                    [
                        {
                            title: data.value,
                            id: '',
                            name: '',
                            type: selectedValue,
                            value: generateRadios,
                            description
                        }
                    ]
                ]
            });

            return onClose();
        }

        if (isAddingClauseVariations) {
            const generateRadios = Object.keys(data)
                .filter(item => item.startsWith('radio') && item.endsWith('text'))
                .map((radioItem, index) => {
                    const multiSelectedValue = multiSelectValues[index].value;

                    const showOrHideString = getDefaultShowOrHideString(
                        showOrHide[index].value,
                        isAddingClauseVariations
                    );

                    if (multiSelectedValue.length > 0) {
                        let str = '';
                        multiSelectedValue.forEach(({ value }, index) => {
                            str += value;
                            if (index >= 0 && index < multiSelectedValue.length - 1) {
                                str += ',';
                            }
                        });

                        str += `-${showOrHideString.value}`;

                        return {
                            text: data[radioItem],
                            isDefault: false,
                            value: {
                                type: FieldTypesEnum.Text,
                                value: str
                            }
                        };
                    }

                    return {
                        text: data[radioItem],
                        isDefault: false,
                        value: {
                            type: 'Text',
                            value: showOrHide[index]?.value
                        }
                    };
                });

            blockEvents.addClauseVariationsEvent({
                blockId,
                clauseId,
                blockTitle: blockTitle || '',
                data: [
                    [
                        {
                            title: data.value,
                            id: '',
                            name: 'radio',
                            type: selectedValue,
                            description,
                            value: generateRadios,
                            blockId
                        }
                    ]
                ]
            });

            return onClose();
        }

        if (selectedValue === FieldTypesEnum.MultiSelect) {
            const generatedMultiSelectItems = multiSelectItems.map(item => ({
                text: item.text,
                value: item.value,
                type: 'Text',
                id: generateId()
            }));

            setData(
                selectedField
                    ? prevState.map(item =>
                          item.id === selectedField.id
                              ? {
                                    value: data.value,
                                    multiSelectItems: generatedMultiSelectItems,
                                    id: textId,
                                    type: selectedValue,
                                    description
                                }
                              : item
                      )
                    : [
                          ...prevState,
                          {
                              value: data['value'],
                              id: textId,
                              type: selectedValue,
                              multiSelectItems: generatedMultiSelectItems,
                              description
                          }
                      ]
            );

            return onClose();
        }

        if (selectedValue === FieldTypesEnum.Select) {
            const generatedSingleSelectItems = singleSelectItems.map(item => ({
                text: item.text,
                value: item.value,
                type: 'Text',
                id: generateId()
            }));

            setData(
                selectedField
                    ? prevState.map(item =>
                          item.id === selectedField.id
                              ? {
                                    value: data.value,
                                    id: textId,
                                    type: FieldTypesEnum.Select,
                                    singleSelectItems: generatedSingleSelectItems,
                                    description
                                }
                              : item
                      )
                    : [
                          ...prevState,
                          {
                              value: data.value,
                              id: textId,
                              type: FieldTypesEnum.Select,
                              singleSelectItems: generatedSingleSelectItems,
                              description
                          }
                      ]
            );

            return onClose();
        }

        if (selectedValue === FieldTypesEnum.Radio) {
            const generatedSingleRadioItems = radioItems.map(item => ({
                text: item.text,
                value: item.value,
                type: 'Text',
                id: generateId()
            }));

            setData(
                selectedField
                    ? prevState.map(item =>
                          item.id === selectedField.id
                              ? {
                                    value: data.value,
                                    id: textId,
                                    type: FieldTypesEnum.Radio,
                                    singleRadioItems: generatedSingleRadioItems,
                                    description
                                }
                              : item
                      )
                    : [
                          ...prevState,
                          {
                              value: data.value,
                              id: textId,
                              type: FieldTypesEnum.Radio,
                              singleRadioItems: generatedSingleRadioItems,
                              description
                          }
                      ]
            );

            return onClose();
        }

        setData(
            selectedField
                ? prevState.map(item =>
                      item.id === selectedField.id
                          ? {
                                placeholder: textId,
                                id: textId,
                                type: selectedValue,
                                description,
                                value: '',
                                question: data.value
                            }
                          : item
                  )
                : [
                      ...prevState,
                      {
                          value: '',
                          question: data.value,
                          placeholder: textId,
                          id: textId,
                          type: selectedValue,
                          description
                      }
                  ]
        );

        return onClose();
    };

    const handleSelectChange = (value: string) => {
        if ((value === FieldTypesEnum.Text || value === FieldTypesEnum.Date) && activeTab === 'logic') {
            setActiveTab('edit');
        }
        setSelectedValue(value as FieldTypesEnum);
    };

    const handleAddOrRemoveRadioOrMultiSelect = ({
        itemType = 'radio',
        type = 'add',
        index
    }: AddOrRemoveButtonProps) => {
        switch (itemType) {
            case 'radio':
                if (type === 'remove') {
                    return setVariationItems(prevState => {
                        const copyOfState = [...prevState];
                        copyOfState.splice(index, 1);
                        return [...copyOfState];
                    });
                }

                return setVariationItems(prevState => {
                    const copyOfState = [...prevState];
                    copyOfState.splice(index + 1, 0, {
                        text: '',
                        isDefault: true,
                        id: generateId(),
                        value: {
                            type: '',
                            value: ''
                        }
                    });

                    return [...copyOfState];
                });
            case 'radioItem':
                if (type === 'remove') {
                    return setRadioItems(prevState => {
                        const copyOfState = [...prevState];
                        copyOfState.splice(index, 1);

                        return [...copyOfState];
                    });
                }

                return setRadioItems(prevState => {
                    const copyOfState = [...prevState];

                    copyOfState.splice(index + 1, 0, {
                        text: '',
                        value: '',
                        id: generateId(),
                        type: FieldTypesEnum.Text
                    });

                    return [...copyOfState];
                });
            case 'select':
                if (type === 'remove') {
                    return setSingleSelectItems(prevState => {
                        const copyOfState = [...prevState];
                        copyOfState.splice(index, 1);

                        return [...copyOfState];
                    });
                }

                return setSingleSelectItems(prevState => {
                    const copyOfState = [...prevState];
                    copyOfState.splice(index + 1, 0, {
                        text: '',
                        value: '',
                        id: generateId(),
                        type: FieldTypesEnum.Text
                    });

                    return [...copyOfState];
                });
            case 'multiSelect':
                if (type === 'remove') {
                    return setMultiSelectItems(prevState => {
                        const copyOfState = [...prevState];
                        copyOfState.splice(index, 1);

                        return [...copyOfState];
                    });
                }

                return setMultiSelectItems(prevState => {
                    const copyOfState = [...prevState];
                    copyOfState.splice(index + 1, 0, {
                        text: '',
                        value: '',
                        id: generateId(),
                        type: FieldTypesEnum.Text
                    });

                    return [...copyOfState];
                });
        }
    };

    const isDisabledEditInput = activeTab === 'logic';

    useEffect(
        () => () => {
            const { resetFieldOrClauseOrBlock, resetMultiSelectValues, resetShowOrHide } = hideOrShowEvents;

            resetFieldOrClauseOrBlock();
            resetMultiSelectValues();
            resetShowOrHide();
        },
        []
    );

    useEffect(() => {
        if (isAddingBlockVariations) {
            const contentState = stateFromHTML(variationsBlockDescription);

            editorEvents.setEditorState(EditorState.createWithContent(contentState));
        }
        if (isAddingClauseVariations) {
            const contentState = stateFromHTML(variationsFieldDescription);

            editorEvents.setEditorState(EditorState.createWithContent(contentState));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAddingBlockVariations, isAddingClauseVariations]);

    const onEditorStateChange = (editorState: EditorState) => {
        editorEvents.setEditorState(editorState);
    };

    return (
        <Container>
            <Form ref={formRef} onSubmit={handleSave}>
                <Content>
                    <Edit
                        errors={errors}
                        handleSelectChange={handleSelectChange}
                        isDisabledEditInput={isDisabledEditInput}
                        selectedField={selectedField}
                        selectedValue={selectedValue}
                        variationTitle={getVariationTitle({
                            isAddingBlockVariations,
                            isAddingClauseVariations,
                            variationsBlock,
                            variationsField
                        })}
                    />
                    {(selectedValue === FieldTypesEnum.Radio || selectedValue === FieldTypesEnum.Select) &&
                        (isAddingBlockVariations || isAddingClauseVariations) && (
                            <VariationsEditor
                                blockId={blockId}
                                clause={clause}
                                errors={errors}
                                formValues={formValues}
                                handleAddOrRemoveRadioOrMultiSelect={handleAddOrRemoveRadioOrMultiSelect}
                                isDisabledEditInput={isDisabledEditInput}
                                isShownLogicTab={activeTab === 'logic'}
                                selectedValue={selectedValue}
                                setVariationItems={setVariationItems}
                                variationItems={variationItems}
                            />
                        )}
                    {selectedValue === FieldTypesEnum.Select &&
                        !(isAddingBlockVariations || isAddingClauseVariations) && (
                            <SelectMultiSelectRadioEditor
                                fieldType="select"
                                handleAddOrRemoveRadioOrMultiSelect={handleAddOrRemoveRadioOrMultiSelect}
                                itemType="single"
                                items={singleSelectItems}
                                setItems={setSingleSelectItems}
                            />
                        )}
                    {selectedValue === FieldTypesEnum.Radio &&
                        !(isAddingBlockVariations || isAddingClauseVariations) && (
                            <SelectMultiSelectRadioEditor
                                fieldType="radioItem"
                                handleAddOrRemoveRadioOrMultiSelect={handleAddOrRemoveRadioOrMultiSelect}
                                itemType="single"
                                items={radioItems}
                                setItems={setRadioItems}
                            />
                        )}
                    {selectedValue === FieldTypesEnum.MultiSelect && (
                        <SelectMultiSelectRadioEditor
                            fieldType="multiSelect"
                            handleAddOrRemoveRadioOrMultiSelect={handleAddOrRemoveRadioOrMultiSelect}
                            itemType="multi"
                            items={multiSelectItems}
                            setItems={setMultiSelectItems}
                        />
                    )}
                    {activeTab !== 'logic' && (
                        <EditorContainer>
                            <WysiwygEditor
                                forDescription
                                editorState={editorState}
                                placeholder="Enter an optional explanation..."
                                onEditorStateChange={onEditorStateChange}
                            />
                        </EditorContainer>
                    )}
                </Content>
                <Footer
                    activeTab={activeTab}
                    isAddingBlockVariations={isAddingBlockVariations}
                    isAddingClauseVariations={isAddingClauseVariations}
                    onTabChange={onTabChange}
                />
            </Form>
        </Container>
    );
};

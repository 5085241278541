import { createEvent, createStore } from 'effector';
import type { Data } from './types';

// * events
const setData = createEvent<Data[]>();
const updateField = createEvent<{
    clauseId: string;
    fieldId: string;
    blockId: string;
    contentId: string;
    // TODO: any
    newField: any;
}>();

// * stores
const data = createStore<Data[]>([]).on(setData, (_, payload) => payload);

// * exports
export const fieldEvents = {
    setData,
    updateField
};

export const fieldStores = {
    data
};

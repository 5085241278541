import { DraggableTypesEnum } from 'constants/builder/enums';
import type { QuestionnaireItem } from '../types';

interface Params {
    question: QuestionnaireItem;
    fieldId: string;
    value: string | string[];
    clauseId?: string;
    answer?: string;
    contentIds?: string[];
}

export const changeClauseVariationsHelper = ({
    answer,
    fieldId,
    question,
    value,
    contentIds,
    clauseId
}: Params): QuestionnaireItem | undefined => {
    if (question.id === fieldId && question.variationsField) {
        return {
            ...question,
            variationsField: [
                [
                    {
                        ...question.variationsField[0][0],
                        value: question.variationsField[0][0].value.map(item =>
                            item.text === value ? { ...item, isDefault: true } : { ...item, isDefault: false }
                        )
                    }
                ]
            ]
        };
    }

    // This logic determines whether or not to show all fields inside this block based on the value and 'show' answer are present.
    if (
        question.questionType === DraggableTypesEnum.Field &&
        contentIds &&
        question.contentId &&
        contentIds.includes(question.contentId)
    ) {
        return {
            ...question,
            isShown: value && answer === 'show' ? true : false
        };
    } else if (question.questionType === DraggableTypesEnum.Field && question.clauseId === clauseId) {
        return {
            ...question,
            isShown: false
        };
    }
};

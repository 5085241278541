import { v4 as generateId } from 'uuid';

import { DraggableTypesEnum } from 'constants/builder/enums';
import type { QuestionnaireItems } from '../types';

export const addParagraphHelper = (
    questions: QuestionnaireItems,
    paragraphs: any[],
    clauseId: string,
    blockId: string,
    contentId: string,
    isShown?: boolean
) => {
    const fields = paragraphs
        .flat()
        .flatMap(item => item.modifiedData)
        .filter(item => item.id);

    if (!fields.length) {
        const itemsForDeleting = questions.filter(
            item =>
                item.clauseId === clauseId &&
                item.contentId === contentId &&
                item.questionType === DraggableTypesEnum.Field
        );

        return questions.filter(item => !itemsForDeleting.find(question => question.id === item.id));
    }

    const allFieldQuestions = questions.filter(item => item.questionType === DraggableTypesEnum.Field);

    questions.forEach((item, index) => {
        fields.forEach(field => {
            if (field.fieldId === item.fieldId) {
                questions.splice(index, 1);
                questions.splice(index, 0, {
                    title: field.id,
                    clauseId,
                    type: field.type,
                    id: generateId(),
                    fieldId: field.fieldId,
                    subtitle: Array.isArray(field.value) ? field.title : field.question,
                    questionType: DraggableTypesEnum.Field,
                    blockId,
                    description: field.description,
                    value: field.value,
                    contentId,
                    isShown
                });
            }
        });
    });

    fields.forEach(item => {
        if (!allFieldQuestions.find(field => field.fieldId === item.fieldId)) {
            questions.push({
                contentId,
                title: item.id,
                subtitle: Array.isArray(item.value) ? item.title : item.question,
                clauseId,
                type: item.type,
                fieldId: item.fieldId,
                id: generateId(),
                blockId,
                questionType: DraggableTypesEnum.Field,
                description: item.description,
                value: item.value,
                isShown
            });
        }
    });

    return questions;
};

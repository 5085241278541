import bluePlusIcon from './static_assets/blue_plus_icon.svg';
import whitePlusIcon from './static_assets/white_plus_icon.svg';
import {
    buttonBorderRadius,
    buttonFontFontFamily,
    buttonInnerTextFontSize,
    buttonInnerTextFontWeight,
    buttonPadding
} from 'components/common/buttons/SimpleButton/constants';
import type { SimpleButtonProps } from 'components/common/buttons/SimpleButton/types';
import { primaryBackgroundColor, primaryGrey, secondaryTextColor } from 'constants/styles/colors';
import { disableDefaultButtonStyleMixin } from 'constants/styles/mixins';
import styled, { css } from 'styled-components';
import media from 'utils/media-query';

// TODO: make this govno with css classes
export const generateHoverMixin = (color: string) => css`
    &:hover {
        background-color: white;
        color: ${color};
        border: 1px solid ${color};
    }
`;

export const generateClickedMixin = (color: string) => css`
    &:active {
        background-color: ${color};
    }
`;

export const supportHoverMixin = css`
    &:hover {
        background-color: ${secondaryTextColor};
        color: white;
        border: 1px solid white;
    }
`;

// TODO: !important
export const defaultDisabledMixin = css`
    color: white !important;
    background-color: ${primaryGrey} !important;
    border-color: ${primaryGrey} !important;
    cursor: default;
`;

export const StyledSimpleButton = styled.button<SimpleButtonProps>`
    ${disableDefaultButtonStyleMixin};
    ${({ width }) => width && `width: ${width}`};
    ${({ minWidth }) => minWidth && `min-width: ${minWidth}`};
    ${({ height }) => height && `height: ${height}`};
    ${({ marginRight }) => marginRight && `margin-right: ${marginRight}`};
    font-family: ${({ fontFamily }) => fontFamily || buttonFontFontFamily};
    font-size: ${({ fontSize }) => fontSize || buttonInnerTextFontSize};
    font-weight: ${({ fontWeight }) => fontWeight || buttonInnerTextFontWeight};
    color: ${({ color }) => color || 'white'};
    background-color: ${({ backgroundColor }) => backgroundColor || secondaryTextColor};
    border-radius: ${({ borderRadius }) => borderRadius || buttonBorderRadius};
    border-style: solid;
    border-width: 1px;
    border-color: ${({ backgroundColor }) => backgroundColor || secondaryTextColor};
    ${({ border }) => border && `border: ${border}`};
    padding: ${({ padding }) => padding || buttonPadding};
    ${({ paddingLeft }) => paddingLeft && `padding-left: ${paddingLeft}`};
    ${({ paddingRight }) => paddingRight && `padding-right: ${paddingRight}`};
    ${({ disabled }) => disabled && defaultDisabledMixin};
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    white-space: nowrap;
    transition-duration: 0.2s;
    ${({ hideSvgOnHover }) =>
        hideSvgOnHover &&
        `
            &:hover {
                img {
                    visibility: hidden;
                }
            }
        `}

    ${({ styleType }) => {
        switch (styleType) {
            case 'primary':
                return css`
                    ${generateHoverMixin(secondaryTextColor)};
                    ${generateClickedMixin('#380782')};
                `;

            case 'secondary':
                return css`
                    ${generateHoverMixin(primaryBackgroundColor)};
                    ${generateClickedMixin('#D15F2E')};
                `;

            case 'support':
                return css`
                    ${supportHoverMixin};
                    ${generateClickedMixin('#380782')};
                `;

            case 'add':
                return css`
                    background-image: url(${whitePlusIcon});
                    background-position: center;
                    background-repeat: no-repeat;

                    &:hover {
                        background-image: url(${bluePlusIcon});
                    }

                    &:active {
                        background-image: url(${whitePlusIcon});
                    }
                `;
        }
    }}

    ${media.desktopLg`
        font-size: 16px;
    `}

    ${media.desktop`
        font-size: 14px;
    `}

    ${media.tablet`
        font-size: 12px;
    `}
`;

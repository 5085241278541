import { Outlet } from 'react-router-dom';
import { CustomImage } from 'components/common/imgComponents/CustomImg/styles';
import { DashboardLayout } from 'components/layouts/DashboardLayout';
import { AbsoluteWrapper } from 'components/wrappers/grid/AbsoluteWrapper';
import chatIconSource from './static_assets/chat_icon.svg';
import { Content } from './styles';
import { Header } from './Header';
import type { ReactFCC } from 'types/react';

export const Dashboard: ReactFCC = () => (
    <DashboardLayout>
        <Header />
        <Content>
            <Outlet />
        </Content>
        <AbsoluteWrapper bottom="24px" right="24px">
            <CustomImage pointer src={chatIconSource} />
        </AbsoluteWrapper>
    </DashboardLayout>
);

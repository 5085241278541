import styled, { css } from 'styled-components';
import type { ButtonVariant } from './index';

const disabledStyles = css`
    pointer-events: none;
    opacity: 0.5;
    cursor: default;
`;

const primaryStyles = css`
    background-color: var(--purple-60);
    color: white;

    &:hover {
        background-color: var(--purple-80);
    }

    &:disabled {
        ${disabledStyles}
        color: var(--gray-60);
    }
`;

const secondaryStyles = css`
    background-color: white;
    border: 1px solid var(--purple-60);
    color: var(--purple-60);

    &:hover {
        background-color: var(--purple-40);
    }

    &:disabled {
        ${disabledStyles}
        background-color: white;
    }
`;

export const StyledButton = styled.button<{ variant: ButtonVariant }>`
    cursor: pointer;
    border: none;
    border-radius: 8px;
    padding: 12px 30px;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    white-space: nowrap;

    ${({ variant }) => variant === 'primary' && primaryStyles}
    ${({ variant }) => variant === 'secondary' && secondaryStyles}
`;

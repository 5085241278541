import styled, { css } from 'styled-components';

interface Props {
    disabled?: boolean;
}

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 16px;
    gap: 8px;
`;

export const Content = styled.div<Props>`
    display: flex;
    align-items: center;
    gap: 16px;

    & > div {
        width: max-content;
    }

    & input {
        width: 250px;
    }

    ${({ disabled }) =>
        disabled &&
        css`
            pointer-events: none;

            & input {
                background-color: var(--gray-20);
                color: var(--gray-80);
            }
        `}
`;

export const Title = styled.span`
    display: inline-block;
    font-weight: 400;
    font-size: 14px;
    color: var(--gray-100);
    margin-bottom: -4px;
`;

export const Button = styled.button`
    background-color: var(--gray-20);
    border: none;
    padding: 0;
    width: 36px;
    height: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    color: var(--purple-60);
    cursor: pointer;

    &:hover {
        background-color: var(--purple-60);
        color: white;
    }
`;

export const ButtonsContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
`;

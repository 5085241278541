import { requiredSetMessage } from 'constants/messages';
import { createRule, yupDefault, yupEmail } from 'constants/yupFields';
import { createForm } from 'effector-forms';
import { userStores } from 'stores/user';
import * as Yup from 'yup';

const { user } = userStores;
const { user: userState } = user.getState();

Yup.addMethod(Yup.array, 'unique', function (message, mapper = (a: unknown) => a) {
    return this.test('unique', message, function (list) {
        return list?.length === new Set(list?.map(mapper)).size;
    });
});

export const form = createForm({
    fields: {
        emails: {
            init: [userState?.email || ''],
            rules: [
                createRule<string[]>({
                    name: 'emails',
                    schema: Yup.array()
                        .of(yupEmail)
                        // ! used prototype, ts does not see it
                        // @ts-ignore
                        .unique('Emails should to be different')
                        .required(requiredSetMessage)
                })
            ]
        },
        documentName: {
            init: '',
            rules: [
                createRule<string>({
                    name: 'documentName',
                    schema: yupDefault
                })
            ]
        },
        message: {
            init: '',
            rules: [
                createRule<string>({
                    name: 'message',
                    schema: yupDefault
                })
            ]
        }
    },
    validateOn: ['change', 'submit']
});

user.updates.watch(({ user }) => form.setForm({ emails: [user?.email || ''], message: '' }));

export const signDraftFormStores = { form };

import { type InputHTMLAttributes, type RefObject, useEffect, useState } from 'react';
import { useToggle } from 'react-use';

import { HidePasswordIcon, ShowPasswordIcon } from './auth';
import { Column } from 'components/wrappers/grid/FlexWrapper';
import { InputTitle } from 'components/common/typography/Span';
import { RelativeWrapper } from 'components/wrappers/RelativeWrapper';
import { ClickableWrapper } from 'components/wrappers/ClicableWrapper';
import { InformationInputWrapper, StyledInputWrapper } from './styles';
import { DescriptionBadge } from 'components/common/DescriptionBadge';

export interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
    value?: string;
    refik?: RefObject<any>;
}

export interface InputDecorationProps {
    type?: InputProps['type'];
    description?: string;
    subtitle?: string;
    title?: string;
    untouchedWarning?: string;
    placeholder?: string;
    defaultValue?: string;
}

export interface StyledTextInputProps
    extends Omit<InputProps, 'defaultValue' | 'defaultChecked'>,
        InputDecorationProps {
    error?: string;
    value?: string;
    autoComplete?: string;
    titleColor?: string;
    disabled?: boolean;
    isTouched?: boolean;
}

export const StyledTextInput = ({
    error,
    isTouched,
    title,
    type,
    refik,
    titleColor,
    disabled,
    autoComplete,
    description,
    ...inputProps
}: StyledTextInputProps) => {
    const [inputType, setInputType] = useState(type);
    const [showPassword, setShowPassword] = useToggle(false);
    const isPasswordType = type === 'password';

    const onChangePasswordView = () => {
        setInputType(showPassword ? 'password' : 'text');
        setShowPassword();
    };

    useEffect(() => setInputType(type), [type]);

    return (
        <Column noWrap width="100%">
            {(error || title) && (
                <InputTitle color={titleColor} isError={!!error}>
                    {error || title || ''}
                    {description && <DescriptionBadge description={description} />}
                </InputTitle>
            )}
            <RelativeWrapper>
                <StyledInputWrapper
                    ref={refik}
                    autoComplete={autoComplete}
                    disabled={disabled}
                    error={error}
                    touched={isTouched}
                    type={inputType}
                    {...inputProps}
                />
                {isPasswordType && (
                    <InformationInputWrapper onClick={onChangePasswordView}>
                        <ClickableWrapper type="button">
                            {showPassword ? <ShowPasswordIcon /> : <HidePasswordIcon />}
                        </ClickableWrapper>
                    </InformationInputWrapper>
                )}
            </RelativeWrapper>
        </Column>
    );
};

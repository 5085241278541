import { PrimaryButton, SecondaryButton } from 'components/common/buttons/SimpleButton';
import { P } from 'components/common/typography/titles/P';
import { AdaptiveGrid } from 'components/wrappers/grid/GridWrapper';
import { MarginWrapper } from 'components/wrappers/MarginWrapper';
import { Modal } from 'ui-kit/components/Modal';
import { AUTH_LINK, REGISTER_LINK } from 'constants/routes';
import { hoveredTextColor } from 'constants/styles/colors';
import { useTranslation } from 'react-i18next';
import { notAuthorizedModalStore } from 'stores/modals';
import { logInButtonWidth, singUpButtonWidth } from './constants';
import { useNavigate } from 'react-router-dom';
import type { ReactFCC } from 'types/react';

export const NotAuthorizedModal: ReactFCC = () => {
    const { closeModal } = notAuthorizedModalStore;
    const navigate = useNavigate();
    const { t } = useTranslation();

    const handleClose = () => {
        closeModal();
    };

    const goToLogin = () => {
        handleClose();
        navigate(AUTH_LINK);
    };
    const goToRegister = () => {
        handleClose();
        navigate(REGISTER_LINK);
    };

    return (
        <Modal visible title={t('notAuthorizedModal.title') as string} width="332px" onClose={handleClose}>
            {/* TODO: improve */}
            <span>{t('notAuthorizedModal.subtitle')}</span>

            <MarginWrapper marginBottom="30px" />

            <AdaptiveGrid alignItems="center" xxl="1fr auto auto ">
                <PrimaryButton width={logInButtonWidth} onClick={goToLogin}>
                    {t('notAuthorizedModal.primaryButton')}
                </PrimaryButton>

                <P color={hoveredTextColor}>{t('or')}</P>

                <SecondaryButton width={singUpButtonWidth} onClick={goToRegister}>
                    {t('notAuthorizedModal.secondaryButton')}
                </SecondaryButton>
            </AdaptiveGrid>
        </Modal>
    );
};

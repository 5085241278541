import { useEffect, useState } from 'react';
import { useStore } from 'effector-react';
import type { ReactFCC } from 'types/react';
import { i18n } from 'i18n';

import { PublicRoute } from 'routes/PublicRoute';
import { PrivateRoute } from 'routes/PrivateRoute';

import { GlobalStyle } from 'ui-kit/GlobalStyle/GlobalStyle';
import { Preloader } from 'ui-kit/components/Preloader';

import { AppLayout } from 'components/layouts/AppLayout';
import { CookieBanner } from 'components/modals/CookiesModal/CookieBanner';

import { getCookieConsentValue } from 'react-cookie-consent';
import { I18nextProvider, useTranslation } from 'react-i18next';
import { Routes, Route } from 'react-router-dom';
import { Error404 } from 'pages/404';
import { Dashboard } from 'pages/Dashboard/Dashboard';

import {
    AUTH_LINK,
    FORGOT_PASSWORD_LINK,
    HOME_LINK,
    JOIN_TO_COMPANY_LINK,
    PRICES_LINK,
    PRIVACY_POLICY_LINK,
    REGISTER_LINK,
    SOCIAL_MEDIA_LINK,
    VERIFY_ACCOUNT_LINK,
    CHECK_EMAIL_LINK,
    BUILDER_LINK,
    CONSTRUCTOR_LINK,
    DOCUMENTS_LINK,
    DRAFTS_LINK,
    SETTINGS_LINK,
    KIARA_TEMPLATES_LINK,
    TERM_OF_USE_LINK,
    THANK_YOU_CONTACT_LINK,
    THANK_YOU_REGISTRATION_LINK,
    TEMPLATES_LINK,
    PASSWORD_RESET,
    PASSWORD_CHANGED,
    RESET_PASSWORD_LINK,
    MAGIC_LINK,
    MOLLIE_SUCCESS
} from 'constants/routes';

import { languageStores } from 'stores/language';

import { Modal } from 'components/modals/Modal';
import { loadingStores } from 'stores/loading/init';
import {
    LazyLoadedMain,
    LazyLoadedLogin,
    LazyLoadedMagic,
    LazyLoadedDrafts,
    LazyLoadedPrices,
    LazyLoadedBuilder,
    LazyLoadedRegister,
    LazyLoadedSettings,
    LazyLoadedDocuments,
    LazyLoadedTemplates,
    LazyLoadedCheckEmail,
    LazyLoadedTermsOfUse,
    LazyLoadedConstructor,
    LazyLoadedSocialMedia,
    LazyLoadedResetPassword,
    LazyLoadedPasswordReset,
    LazyLoadedJoinToCompany,
    LazyLoadedPrivacyPolicy,
    LazyLoadedVerifyAccount,
    LazyLoadedForgotPassword,
    LazyLoadedKiaraTemplates,
    LazyLoadedPasswordChanged,
    LazyLoadedThankYouContact,
    LazyLoadedThankYouRegistration,
    LazyLoadedMollieSuccess
} from 'pages';
import SuspenseWrapper from 'components/wrappers/SuspenseWrapper';

import 'stores/init';

export const App: ReactFCC = () => {
    const currentLanguageCode = useStore(languageStores.currentLanguageCode);
    const isLoading = useStore(loadingStores.isLoading);

    const [showCookieBanner, setShowCookieBanner] = useState(false);
    const { i18n: translator } = useTranslation();

    useEffect(() => {
        if (!getCookieConsentValue()) {
            setShowCookieBanner(true);
        }
    }, []);

    useEffect(() => {
        translator.changeLanguage(currentLanguageCode || 'du');
    }, [currentLanguageCode, translator]);

    return (
        <I18nextProvider i18n={i18n}>
            <GlobalStyle />
            <Preloader isLoading={isLoading} />
            <AppLayout>
                <Modal />
                <Routes>
                    {/* * Auth */}

                    <Route
                        element={
                            <PublicRoute>
                                <SuspenseWrapper>
                                    <LazyLoadedRegister />
                                </SuspenseWrapper>
                            </PublicRoute>
                        }
                        path={REGISTER_LINK}
                    />
                    <Route
                        element={
                            <PublicRoute>
                                <SuspenseWrapper>
                                    <LazyLoadedLogin />
                                </SuspenseWrapper>
                            </PublicRoute>
                        }
                        path={AUTH_LINK}
                    />
                    <Route
                        element={
                            <PublicRoute>
                                <SuspenseWrapper>
                                    <LazyLoadedForgotPassword />
                                </SuspenseWrapper>
                            </PublicRoute>
                        }
                        path={FORGOT_PASSWORD_LINK}
                    />
                    <Route
                        element={
                            <PublicRoute>
                                <SuspenseWrapper>
                                    <LazyLoadedJoinToCompany />
                                </SuspenseWrapper>
                            </PublicRoute>
                        }
                        path={JOIN_TO_COMPANY_LINK}
                    />
                    <Route
                        element={
                            <PublicRoute>
                                <SuspenseWrapper>
                                    <LazyLoadedCheckEmail />
                                </SuspenseWrapper>
                            </PublicRoute>
                        }
                        path={CHECK_EMAIL_LINK}
                    />

                    <Route
                        element={
                            <PublicRoute>
                                <SuspenseWrapper>
                                    <LazyLoadedResetPassword />
                                </SuspenseWrapper>
                            </PublicRoute>
                        }
                        path={RESET_PASSWORD_LINK}
                    />

                    {/* * public */}
                    <Route
                        element={
                            <SuspenseWrapper>
                                <LazyLoadedMain />
                            </SuspenseWrapper>
                        }
                        path={HOME_LINK}
                    />
                    <Route
                        element={
                            <SuspenseWrapper>
                                <LazyLoadedSocialMedia />
                            </SuspenseWrapper>
                        }
                        path={SOCIAL_MEDIA_LINK}
                    />
                    <Route
                        element={
                            <SuspenseWrapper>
                                <LazyLoadedPrices />
                            </SuspenseWrapper>
                        }
                        path={PRICES_LINK}
                    />
                    <Route
                        element={
                            <SuspenseWrapper>
                                <LazyLoadedKiaraTemplates />
                            </SuspenseWrapper>
                        }
                        path={KIARA_TEMPLATES_LINK}
                    />
                    <Route
                        element={
                            <SuspenseWrapper>
                                <LazyLoadedPrivacyPolicy />
                            </SuspenseWrapper>
                        }
                        path={PRIVACY_POLICY_LINK}
                    />
                    <Route
                        element={
                            <SuspenseWrapper>
                                <LazyLoadedTermsOfUse />
                            </SuspenseWrapper>
                        }
                        path={TERM_OF_USE_LINK}
                    />
                    <Route
                        element={
                            <SuspenseWrapper>
                                <LazyLoadedConstructor />
                            </SuspenseWrapper>
                        }
                        path={CONSTRUCTOR_LINK}
                    />

                    {/* * ThankYou */}
                    <Route
                        element={
                            <SuspenseWrapper>
                                <LazyLoadedThankYouContact />
                            </SuspenseWrapper>
                        }
                        path={THANK_YOU_CONTACT_LINK}
                    />
                    <Route
                        element={
                            <SuspenseWrapper>
                                <LazyLoadedThankYouRegistration />
                            </SuspenseWrapper>
                        }
                        path={THANK_YOU_REGISTRATION_LINK}
                    />
                    <Route
                        element={
                            <SuspenseWrapper>
                                <LazyLoadedVerifyAccount />
                            </SuspenseWrapper>
                        }
                        path={VERIFY_ACCOUNT_LINK}
                    />
                    <Route
                        element={
                            <SuspenseWrapper>
                                <LazyLoadedPasswordReset />
                            </SuspenseWrapper>
                        }
                        path={PASSWORD_RESET}
                    />
                    <Route
                        element={
                            <SuspenseWrapper>
                                <LazyLoadedPasswordChanged />
                            </SuspenseWrapper>
                        }
                        path={PASSWORD_CHANGED}
                    />
                    <Route
                        element={
                            <SuspenseWrapper>
                                <LazyLoadedMollieSuccess />
                            </SuspenseWrapper>
                        }
                        path={MOLLIE_SUCCESS}
                    />

                    {/* * private */}

                    <Route
                        element={
                            <PrivateRoute>
                                <Dashboard />
                            </PrivateRoute>
                        }
                        path="/dashboard"
                    >
                        <Route
                            element={
                                <SuspenseWrapper>
                                    <LazyLoadedDocuments />
                                </SuspenseWrapper>
                            }
                            path={DOCUMENTS_LINK}
                        />
                        <Route
                            element={
                                <SuspenseWrapper>
                                    <LazyLoadedDrafts />
                                </SuspenseWrapper>
                            }
                            path={DRAFTS_LINK}
                        />
                        <Route
                            element={
                                <SuspenseWrapper>
                                    <LazyLoadedTemplates />
                                </SuspenseWrapper>
                            }
                            path={TEMPLATES_LINK}
                        />
                        <Route
                            element={
                                <SuspenseWrapper>
                                    <LazyLoadedSettings />
                                </SuspenseWrapper>
                            }
                            path={SETTINGS_LINK}
                        />
                    </Route>
                    <Route
                        element={
                            <PrivateRoute>
                                <SuspenseWrapper>
                                    <LazyLoadedBuilder />
                                </SuspenseWrapper>
                            </PrivateRoute>
                        }
                        path={BUILDER_LINK}
                    />
                    <Route
                        element={
                            <PrivateRoute>
                                <SuspenseWrapper>
                                    <LazyLoadedMagic />
                                </SuspenseWrapper>
                            </PrivateRoute>
                        }
                        path={MAGIC_LINK}
                    />
                    {/* * error */}
                    <Route element={<Error404 />} path="*" />
                </Routes>
                {showCookieBanner && <CookieBanner />}
            </AppLayout>
        </I18nextProvider>
    );
};

import axios from 'api/axios';
import type Stripe from 'stripe';

interface MockStripeResponse {
    client_secret: string;
    message: string;
}

export const payWithStripe = (data: API.CreateOrderDto) =>
    axios<MockStripeResponse>({
        url: '/stripe/create-intent',
        data
    });

export const createPaymentMethod = (data: API.CreatePaymentMethodDto) =>
    axios<API.SuccessAnswer>({
        url: '/stripe/payment-method',
        data
    });

export const updateUserDefaultPaymentMethod = (data: API.UpdateCustomerDto, id?: string) =>
    axios<Stripe.ApiListPromise<Stripe.PaymentMethod>>({
        method: 'PUT',
        url: `/stripe/customers/${id}`,
        data
    });

export const getProductsPrice = () =>
    axios<API.ApiProductsList>({
        method: 'GET',
        url: '/stripe/products-price'
    });

export const getProSubscriptionInfo = () =>
    axios<API.SubscriptionInfo[]>({
        method: 'GET',
        url: '/stripe/subscription-types?productName=professional'
    });

export const createSubscription = (data: API.CreateSubscriptionDto) =>
    axios<API.SuccessAnswer>({
        url: '/stripe/create-subscription',
        data
    });

export const updateSubscription = (id: string, data: API.UpdateSubscriptionDto) =>
    axios<API.SuccessAnswer>({
        method: 'PATCH',
        url: `/stripe/subscriptions/${id}`,
        data
    });

export const deleteSubscription = (id: string) =>
    axios<API.SuccessAnswer>({
        method: 'DELETE',
        url: `/stripe/subscriptions/${id}`
    });

export const createContractWithSubscription = (data: API.CreateContractWithSubscriptionDto) =>
    axios<API.SuccessAnswer>({
        url: '/stripe/subscriptions/create-contract',
        data
    });

export const getPaymentMethods = (id: string) =>
    axios<Stripe.ApiListPromise<Stripe.PaymentMethod>>({
        method: 'GET',
        url: `/stripe/${id}/payment-method`
    });

export const deletePaymentMethod = (id: string) =>
    axios<API.SuccessAnswer>({
        method: 'DELETE',
        url: `/stripe/payment-method/${id}`
    });

export const getStripeCustomer = (id?: string) =>
    axios<API.StripeCustomer>({
        method: 'GET',
        url: `stripe/customers/${id}`
    });

import {
    inputHeight,
    inputTextHorizontalPadding,
    inputTextVerticalPadding
} from 'components/common/inputs/StyledInput/constants';
import { errorColor, inputFocusColor } from 'constants/styles/colors';
import { headingsFontFamilyMixin } from 'constants/styles/fonts';
import { disableDefaultInputStyleMixin, flexCenter } from 'constants/styles/mixins';
import styled, { css } from 'styled-components';
import media from 'utils/media-query';

export interface StyledInputProps {
    error?: string;
    touched?: boolean;
    value?: string;
    disabled?: boolean;
}

export const styledInputMixin = css<StyledInputProps>`
    ${disableDefaultInputStyleMixin};
    width: 100%;
    height: ${inputHeight};
    border-radius: 10px;
    border: 1px solid var(--gray-60);
    padding: ${inputTextVerticalPadding} ${inputTextHorizontalPadding};

    :focus {
        border-color: ${({ error }) => (error ? errorColor : inputFocusColor)};
    }

    &::placeholder {
        color: #cdd0d9;
    }

    ${({ touched, error }) => touched && error && `border-color: ${errorColor}`};
`;

export const textInputMixin = css`
    ${headingsFontFamilyMixin};
    font-size: 16px;
    font-style: normal;
    font-weight: 400;

    ${media.tabletSmall`
        font-size: 12px;
    `}
`;

export const StyledInputWrapper = styled.input<StyledInputProps>`
    ${styledInputMixin};
    ${textInputMixin};
    ${({ disabled }) => disabled && 'pointer-events: none'};
    ::-webkit-outer-spin-button,
    ::-webkit-inner-spin-button {
        -webkit-appearance: none;
    }
`;

export const InformationInputWrapper = styled.div`
    position: absolute;
    top: 0;
    right: 16px;
    ${flexCenter};
    height: 100%;
`;

// import { Elements } from '@stripe/react-stripe-js';
import { useTranslation } from 'react-i18next';
import { useStore } from 'effector-react';

import idealIconSrc from './static_assets/ideal_icon.png';
import visaIconSrc1 from './static_assets/visa_icon_1.png';
import visaIconSrc2 from './static_assets/visa_icon_2.png';

import { type ReactFCC } from 'types/react';
import { buttonPaddingLeft } from './constants';
import { Modal } from 'ui-kit/components/Modal';
// import { stripePromise } from 'constants/payment';
import { InfoLabel, InfoText, Price } from './styles';
import { paymentModalStore, stripeModalStore } from 'stores/modals';
import { AdaptiveGrid } from 'components/wrappers/grid/GridWrapper';
import { RelativeWrapper } from 'components/wrappers/RelativeWrapper';
import { AbsoluteWrapper } from 'components/wrappers/grid/AbsoluteWrapper';
import { Column, Row, Section } from 'components/wrappers/grid/FlexWrapper';
import { CustomImage } from 'components/common/imgComponents/CustomImg/styles';
import { PrimaryButton, SecondaryButton } from 'components/common/buttons/SimpleButton';

export const PaymentModal: ReactFCC = () => {
    const { closeModal, modal } = paymentModalStore;
    const { t } = useTranslation();
    const [_, { contractName, price, firstName, lastName, date, isSign, templateId, previewUrl }] = useStore(modal);

    const handlePayWithVisaCard = () => {
        stripeModalStore.openModal({ isIdeal: false, isSign, templateId, contractName, price, previewUrl });
    };

    const handlePayWithIdeal = () => {
        stripeModalStore.openModal({ isIdeal: true, isSign, contractName, templateId, price, previewUrl });
    };

    return (
        // <Elements stripe={stripePromise}>
        <>
            <Modal visible title={`${t('paymentModal.title')} ${contractName}`} width="350px" onClose={closeModal}>
                <Price>{price} €</Price>
                <Row justifyBetween marginBottom="30px" marginTop="10px">
                    <Column>
                        <InfoLabel>{t('firstName')}</InfoLabel>
                        <InfoText>{firstName}</InfoText>
                    </Column>
                    <Column>
                        <InfoLabel>{t('lastName')}</InfoLabel>
                        <InfoText>{lastName}</InfoText>
                    </Column>
                    <Column>
                        <InfoLabel>{t('date')}</InfoLabel>
                        <InfoText>{date}</InfoText>
                    </Column>
                </Row>
                <AdaptiveGrid gap="15px" xxl="1fr">
                    <SecondaryButton hideSvgOnHover paddingLeft={buttonPaddingLeft} onClick={handlePayWithIdeal}>
                        <RelativeWrapper>
                            <AbsoluteWrapper left="-6px" top="-6px">
                                <CustomImage height="36px" src={idealIconSrc} width="36px" />
                            </AbsoluteWrapper>
                            {t('paymentModal.payIdeal')}
                        </RelativeWrapper>
                    </SecondaryButton>
                    <PrimaryButton hideSvgOnHover onClick={handlePayWithVisaCard}>
                        <RelativeWrapper>
                            <AbsoluteWrapper left="-18px">
                                <Section alignCenter>
                                    <CustomImage height="16px" src={visaIconSrc1} width="46px" />
                                    <CustomImage height="22px" src={visaIconSrc2} width="30px" />
                                </Section>
                            </AbsoluteWrapper>
                            {t('paymentModal.payCard')}
                        </RelativeWrapper>
                    </PrimaryButton>
                </AdaptiveGrid>
            </Modal>
        </>
        // </Elements>
    );
};

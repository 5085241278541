import { type ChangeEvent, type Dispatch, type SetStateAction, useMemo } from 'react';

import { Logic } from '../../tabs/Logic';
import { type ReactFCC } from 'types/react';
import { MinusIconV2, PlusIcon } from 'ui-kit/assets';
import { type Fields } from 'types/builder/interface';
import { InputWithTooltip } from './InputWithTooltip';
import { type FormFieldsErrors, type VariationItems } from '../../types';
import { Button, ButtonsContainer, Container, Content, Title } from '../styles';

const portal = document.createElement('div');
if (!document.body) {
    throw new Error('body not ready for portal creation!');
}
document.body.appendChild(portal);

export interface AddOrRemoveButtonProps {
    type?: 'add' | 'remove';
    itemType?: string;
    index: number;
}

interface Props {
    clause?: Fields;
    blockId?: string;
    selectedValue: string;
    isShownLogicTab: boolean;
    errors: FormFieldsErrors[];
    isDisabledEditInput: boolean;
    variationItems: VariationItems[];
    formValues?: { [key: string]: string };
    setVariationItems: Dispatch<SetStateAction<VariationItems[]>>;
    handleAddOrRemoveRadioOrMultiSelect: (props: AddOrRemoveButtonProps) => void;
}

export const VariationsEditor: ReactFCC<Props> = ({
    variationItems,
    isDisabledEditInput,
    handleAddOrRemoveRadioOrMultiSelect,
    isShownLogicTab,
    errors,
    formValues,
    blockId,
    selectedValue,
    setVariationItems,
    clause
}) => {
    const filteredErrors = useMemo(() => errors.filter(item => item.name !== 'value'), [errors]);
    const handleChange = (event: ChangeEvent<HTMLInputElement>, index: number) => {
        const { value } = event.target;

        const newState = variationItems.map((item, i) => (i === index ? { ...item, text: value } : item));

        setVariationItems(newState);
    };

    return (
        <>
            <Container>
                <Title>Answers</Title>
                {!!variationItems.length &&
                    variationItems.map(({ text, id }, index) => {
                        const error = filteredErrors.find(item => item.name.includes(String(index + 1)))?.error;
                        return (
                            <Content key={id}>
                                <InputWithTooltip
                                    error={error}
                                    handleChange={handleChange}
                                    index={index}
                                    isDisabledEditInput={isDisabledEditInput}
                                    name={`radio_${index + 1}_text`}
                                    text={text}
                                />
                                {!isDisabledEditInput && (
                                    <ButtonsContainer>
                                        <Button
                                            type="button"
                                            onClick={() => {
                                                handleAddOrRemoveRadioOrMultiSelect({
                                                    type: 'add',
                                                    index
                                                });
                                            }}
                                        >
                                            <PlusIcon height={12} width={12} />
                                        </Button>
                                        <Button
                                            type="button"
                                            onClick={() => {
                                                handleAddOrRemoveRadioOrMultiSelect({
                                                    type: 'remove',
                                                    index
                                                });
                                            }}
                                        >
                                            <MinusIconV2 height={2} width={12} />
                                        </Button>
                                    </ButtonsContainer>
                                )}
                            </Content>
                        );
                    })}
            </Container>
            {isShownLogicTab && (
                <Logic
                    blockId={blockId}
                    clause={clause}
                    formValues={formValues}
                    selectedValue={selectedValue}
                    variationItems={variationItems}
                />
            )}
        </>
    );
};

import { languages } from 'components/grid/Header/constants';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-use';
import { Container, ButtonsContainer, SelectContainer } from './styles';
import { useLanguage } from 'hooks/useLanguage';
import { Select } from 'ui-kit/components/Select/Select';
import { Button } from 'ui-kit/components/buttons/Button';
import { H1 } from 'ui-kit/components/typography/H1';
import { BUILDER_LINK } from 'constants/routes';
import type { ReactFCC } from 'types/react';
import { useNavigate } from 'react-router-dom';
import { blockEvents } from 'stores/builder/block';
import { questionnaireEvents } from 'stores/builder/questionnaire';

export const Header: ReactFCC = () => {
    const navigate = useNavigate();
    const { pathname = '/dashboard', search } = useLocation();
    const { t } = useTranslation();
    const { language, setLanguage } = useLanguage();

    const title = useMemo(() => {
        const splitPathname = pathname.split('/');

        return splitPathname[splitPathname.length - 1];
    }, [pathname]);

    const goToTheBuilder = () => {
        sessionStorage.setItem('linkToDashBoard', `${pathname}${search}`);
        blockEvents.resetBlocksEvent();
        questionnaireEvents.resetQuestionnaireEvent();
        navigate(BUILDER_LINK);
    };

    return (
        <Container>
            <H1>{t(`dashboard.aside.${title}`)}</H1>
            <ButtonsContainer>
                <SelectContainer>
                    <Select
                        defaultValue={language}
                        isSearchable={false}
                        options={languages}
                        onChange={({ value }: any) => setLanguage(value)}
                    />
                </SelectContainer>
                <Button variant="primary" onClick={goToTheBuilder}>
                    {t('dashboard.createANewTemplate')}
                </Button>
            </ButtonsContainer>
        </Container>
    );
};

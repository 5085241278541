import { createEvent, createStore } from 'effector';
import type { ClauseInterface } from 'types/builder/interface';
import type { AddClauseEventProps, ChangeClauseTitleEventProps, DeleteClauseEventProps } from './types';

// * events
const addClauseEvent = createEvent<AddClauseEventProps>();
const deleteClauseEvent = createEvent<DeleteClauseEventProps>();
const changeClauseTitleEvent = createEvent<ChangeClauseTitleEventProps>();
const toggleNumberingEvent = createEvent();
const addVariant = createEvent<{
    blockId: string;
    clauseId: string;
}>();
const deleteVariant = createEvent<{
    blockId: string;
    clauseId: string;
    contentId: string;
}>();
const makeIsDefaultEvent = createEvent<{
    blockId: string;
    clauseId: string;
    contentId: string;
}>();

// * stores
const clauses = createStore<ClauseInterface[]>([]);

// * exports
export const clauseEvents = {
    addClauseEvent,
    deleteClauseEvent,
    changeClauseTitleEvent,
    toggleNumberingEvent,
    addVariant,
    deleteVariant,
    makeIsDefaultEvent
};

export const clauseStores = {
    clauses
};

import { sample } from 'effector';
import { pdfEffects, pdfEvents, pdfStores } from './index';
import { signDraftFormStores } from 'stores/forms/signDraftForm';
import { documentsEffects } from 'stores/documents';
import { draftsStores } from 'stores/drafts';

pdfEffects.convertToPdfAndCreateContract.failData.watch(() => {
    pdfEvents.togglePdfGenerationProcessVisible();
});

pdfEffects.convertToPdfAndCreateContract.doneData.watch(() => {
    pdfEvents.togglePdfGenerationProcessVisible();
});

sample({
    clock: pdfEffects.convertToPdfAndCreateContract.doneData,
    source: {
        pdfData: pdfStores.pdfData,
        fileId: pdfStores.fileId,
        documentName: signDraftFormStores.form.fields.documentName.$field,
        emails: signDraftFormStores.form.fields.emails.$field,
        message: signDraftFormStores.form.fields.message.$field,
        draft: draftsStores.draft
    },
    fn: async ({ pdfData, fileId, documentName, message, draft }) => {
        if (pdfData && fileId && draft) {
            const { pdf } = pdfData;

            try {
                await documentsEffects.createDocument({
                    description: '',
                    draft: draft.id,
                    massage: message.value,
                    name: documentName.value,
                    pdf: fileId
                });
                pdf.save(documentName.value);
                // TODO: catch
            } catch {}
        }
    }
});

import { headingsFontFamilyMixin } from 'constants/styles/fonts';
import { disableDefaultHStyleMixin } from 'constants/styles/mixins';
import styled from 'styled-components';

export const Text = styled.p`
    ${headingsFontFamilyMixin}
    font-size: 16px;
    line-height: 20px;
    color: var(--gray-80);
    margin-bottom: 28px;
`;

export const ButtonsContainer = styled.div`
    display: flex;
    justify-content: space-between;
    min-width: 200px;
`;

export const BodyContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const Title = styled.h3`
    ${disableDefaultHStyleMixin};
    ${headingsFontFamilyMixin};
    margin-top: 26px;
    margin-bottom: 4px;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
`;

import { useEffect, useRef, useState } from 'react';
import { v4 } from 'uuid';

import { type ReactFCC } from 'types/react';
import { Tooltip } from 'ui-kit/components/Tooltip';
import { Input } from 'ui-kit/components/inputs/Input';
import { isEllipsisActive } from 'utils/is-ellipsis-active';

interface Props {
    text?: string;
}

export const Answer: ReactFCC<Props> = ({ text = '' }) => {
    const ref = useRef<HTMLInputElement>(null);
    const id = v4();
    const [shouldShowTooltip, setShouldShowTooltip] = useState(false);

    useEffect(() => {
        if (ref.current) {
            setShouldShowTooltip(ref.current && isEllipsisActive(ref.current));
        }
    }, [ref]);

    return (
        <div data-tooltip-id={id} style={{ width: '100%' }}>
            <Input disabled isEllipsis defaultValue={text} reference={ref} />
            {shouldShowTooltip && <Tooltip id={id} text={text} />}
        </div>
    );
};

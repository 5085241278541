import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useStore } from 'effector-react';
import classNames from 'classnames/bind';
import { useClickAway } from 'react-use';

import { type ReactFCC } from 'types/react';
import { Modal } from 'ui-kit/components/Modal';
import styles from './ColorPalletModal.module.scss';
import { colorPalletModalStore } from 'stores/modals';
import { getColorChoosingText, isActive } from './utils';
import { Button } from 'ui-kit/components/buttons/Button';
import { ColorPicker } from 'ui-kit/components/ColorPicker';

const cx = classNames.bind(styles);

export interface Pallet {
    first: string;
    fourth: string;
    second: string;
    third: string;
}

export const ColorPalletModal: ReactFCC = () => {
    const { closeModal, modal } = colorPalletModalStore;
    const [_, { handleAddPallet, setSelectedPalletIndex, handleUpdatePallet, palletToUpdate }] = useStore(modal);
    const [pallet, setPallet] = useState<Pallet>({
        first: '',
        fourth: '',
        second: '',
        third: ''
    });

    const [isOpenColorPicker, setIsOpenColorPicker] = useState({
        from: '',
        isOpen: false,
        color: 'black'
    });

    const ref = useRef<HTMLDivElement>(null);

    const handleSetColor = useCallback(
        (key: string, color: string) => {
            setPallet({ ...pallet, [key]: color });
        },
        [pallet]
    );

    useEffect(() => {
        if (palletToUpdate) {
            setPallet(palletToUpdate);
            setIsOpenColorPicker({ from: 'first', isOpen: true, color: palletToUpdate.first });
        }
    }, [palletToUpdate]);

    useEffect(() => {
        if (!palletToUpdate) {
            setIsOpenColorPicker({ from: 'first', isOpen: true, color: 'black' });
        }
    }, [palletToUpdate]);

    const handleOpenColorPicker = useCallback((from: string, color: string) => {
        setIsOpenColorPicker({ from, isOpen: true, color });
    }, []);

    const handleCloseColorPicker = useCallback(() => {
        setIsOpenColorPicker({ from: '', isOpen: false, color: 'black' });
    }, []);

    useClickAway(ref, handleCloseColorPicker);

    const { first, fourth, second, third } = pallet;

    const handleClose = useCallback(() => {
        closeModal();
    }, [closeModal]);

    const colorChoosingText = useMemo(() => getColorChoosingText(isOpenColorPicker.from), [isOpenColorPicker]);

    return (
        <Modal
            visible
            customFooter={
                <div className={cx('footer')}>
                    <Button variant="secondary" onClick={handleClose}>
                        Cancel
                    </Button>
                    <Button
                        variant="primary"
                        onClick={() => {
                            handleAddPallet({ first, fourth, second, third });
                            handleUpdatePallet({ first, fourth, second, third });
                            setSelectedPalletIndex();
                            handleClose();
                        }}
                    >
                        Save
                    </Button>
                </div>
            }
            title="Create a custom color palette"
            onClose={handleClose}
        >
            <div className={cx('container')}>
                <div className={cx('text')}>
                    <span>{colorChoosingText}</span>
                </div>
                <div ref={ref} className={cx('pallets', { custom: true })}>
                    <div className={cx('pallet')}>
                        <div
                            className={cx('first')}
                            style={{
                                backgroundColor: first || 'white',
                                border:
                                    pallet.first.length < 1
                                        ? `1px solid ${isActive('first', isOpenColorPicker) ? '#233353' : '#d0d3d9'}`
                                        : undefined
                            }}
                            onClick={() => handleOpenColorPicker('first', first)}
                        />
                        <div
                            className={cx('second')}
                            style={{
                                backgroundColor: second || 'white',
                                border:
                                    pallet.second.length < 1
                                        ? `1px solid ${isActive('second', isOpenColorPicker) ? '#233353' : '#d0d3d9'}`
                                        : undefined
                            }}
                            onClick={() => handleOpenColorPicker('second', second)}
                        />
                        <div
                            className={cx('third')}
                            style={{
                                backgroundColor: third || 'white',
                                border:
                                    pallet.third.length < 1
                                        ? `1px solid ${isActive('third', isOpenColorPicker) ? '#233353' : '#d0d3d9'}`
                                        : undefined
                            }}
                            onClick={() => handleOpenColorPicker('third', third)}
                        />
                        <div
                            className={cx('fourth')}
                            style={{
                                backgroundColor: fourth || 'white',
                                border:
                                    pallet.fourth.length < 1
                                        ? `1px solid ${isActive('fourth', isOpenColorPicker) ? '#233353' : '#d0d3d9'}`
                                        : undefined
                            }}
                            onClick={() => handleOpenColorPicker('fourth', fourth)}
                        />
                        {isOpenColorPicker.isOpen && (
                            <div className={cx('color-picker')}>
                                <ColorPicker
                                    defaultColor={isOpenColorPicker.color}
                                    onChange={color => {
                                        handleSetColor(isOpenColorPicker.from, color);
                                    }}
                                />
                                <div className={cx('color-picker__input__container')}>
                                    <input
                                        className={cx('color-picker__input')}
                                        defaultValue={pallet[isOpenColorPicker.from as keyof typeof pallet]}
                                        onChange={event => {
                                            if (/^#([0-9a-f]{6}|[0-9a-f]{3})$/i.test(event.target.value)) {
                                                handleSetColor(isOpenColorPicker.from, event.target.value);
                                            }
                                        }}
                                    />
                                    <div
                                        className={cx('color-picker__color')}
                                        style={{
                                            backgroundColor:
                                                pallet[isOpenColorPicker.from as keyof typeof pallet] || 'white'
                                        }}
                                    />
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </Modal>
    );
};

import { Container } from './styles';
import { type ReactFCC } from 'types/react';
import { Button } from 'ui-kit/components/buttons/Button';

interface Props {
    activeTab: string;
    isAddingBlockVariations: boolean;
    isAddingClauseVariations: boolean;
    onTabChange: (tab: string) => void;
}

export const Footer: ReactFCC<Props> = ({
    activeTab,
    onTabChange,
    isAddingBlockVariations,
    isAddingClauseVariations
}) => (
    <Container>
        {activeTab === 'logic' && (
            <Button
                type="button"
                variant="secondary"
                onClick={() => {
                    onTabChange('edit');
                }}
            >
                Back
            </Button>
        )}
        {(isAddingBlockVariations || isAddingClauseVariations) && activeTab !== 'logic' ? (
            <Button
                type="button"
                variant="primary"
                onClick={e => {
                    e.preventDefault();
                    onTabChange('logic');
                }}
            >
                Next
            </Button>
        ) : (
            <Button type="submit" variant="primary">
                Save
            </Button>
        )}
    </Container>
);

import styled from 'styled-components';
import type { AbsoluteLocation, Sizes, ZIndex } from 'types/styles';

interface Props extends Sizes, AbsoluteLocation, ZIndex {}

export const RelativeWrapper = styled.div<Props>`
    position: relative;
    width: ${({ width }) => width || '100%'};
    height: ${({ height }) => height || '100%'};
    ${({ left }) => left && `left: ${left}`};
    ${({ right }) => right && `right: ${right}`};
    ${({ bottom }) => bottom && `bottom: ${bottom}`};
    ${({ top }) => top && `top: ${top}`};
    ${({ zIndex }) => zIndex && `z-index: ${zIndex};`};
`;

import type { ButtonProps, SimpleButtonProps } from 'components/common/buttons/SimpleButton/types';
import { primaryBackgroundColor, secondaryTextColor } from 'constants/styles/colors';
import { StyledSimpleButton } from './styles';
import type { ReactFCC } from 'types/react';

export const SimpleButton: ReactFCC<SimpleButtonProps> = ({ disabled, children, styleType, ...props }) => (
    <StyledSimpleButton {...props} disabled={disabled} styleType={styleType}>
        {children}
    </StyledSimpleButton>
);

export const PrimaryButton: ReactFCC<ButtonProps> = props => (
    <SimpleButton {...props} backgroundColor={secondaryTextColor} height="48px" styleType="primary" />
);

export const SecondaryButton: ReactFCC<ButtonProps> = props => (
    <SimpleButton {...props} backgroundColor={primaryBackgroundColor} styleType="secondary" />
);

export const SupportButton: ReactFCC<ButtonProps> = props => (
    <SimpleButton
        {...props}
        backgroundColor="white"
        color={secondaryTextColor}
        fontSize="16px"
        height="48px"
        styleType="support"
    />
);

export const SupportButtonSecondary: ReactFCC<ButtonProps> = props => (
    <SimpleButton
        {...props}
        backgroundColor="white"
        color={primaryBackgroundColor}
        fontSize="16px"
        height="48px"
        styleType="support"
    />
);

import { createEvent, createStore } from 'effector';
import { v4 as generateId } from 'uuid';

import type {
    QuestionnaireItems,
    ChangeSubjectTitleEventProps,
    ReorderEventProps,
    DeleteSubjectEventProps
} from './types';
import type { QuestionnaireValues } from 'types/questionnare';
import { DraggableTypesEnum } from 'constants/builder/enums';

import { addQuestionnaireValuesReducer } from './reducers/add-questionnaire-values-reducer';

// * events
const reorderEvent = createEvent<ReorderEventProps>();
const changeSubjectTitleEvent = createEvent<ChangeSubjectTitleEventProps>();
const addNewSubjectEvent = createEvent();
const deleteSubjectEvent = createEvent<DeleteSubjectEventProps>();
const initializeQuestionnaireEvent = createEvent();
const resetQuestionnaireEvent = createEvent();
const addQuestionnaireValues = createEvent<QuestionnaireValues>();

// * stores
const questionnaireItems = createStore<{ questions: QuestionnaireItems }>({ questions: [] });

questionnaireItems
    .on(addQuestionnaireValues, addQuestionnaireValuesReducer)
    .on(reorderEvent, (state, { questions }) => ({
        ...state,
        questions
    }))
    .on(initializeQuestionnaireEvent, state => ({
        ...state,
        questions: []
    }))
    .on(changeSubjectTitleEvent, (state, { subjectId, title }) => ({
        ...state,
        questions: state.questions.map(question => (question.id === subjectId ? { ...question, title } : question))
    }))
    .on(addNewSubjectEvent, state => ({
        ...state,
        questions: [
            ...state.questions,
            {
                id: generateId(),
                questionType: DraggableTypesEnum.Subject,
                title: '',
                blockId: ''
            }
        ]
    }))
    .on(deleteSubjectEvent, (state, { subjectId }) => ({
        ...state,
        questions: state.questions.filter(({ id }) => subjectId !== id)
    }))
    .on(resetQuestionnaireEvent, () => ({ questions: [] }));

// * exports
export const questionnaireEvents = {
    reorderEvent,
    changeSubjectTitleEvent,
    addNewSubjectEvent,
    deleteSubjectEvent,
    initializeQuestionnaireEvent,
    resetQuestionnaireEvent,
    addQuestionnaireValues
};

export const questionnaireStores = {
    questionnaireItems
};

import { API } from 'api';
import { createEffect, createStore } from 'effector';
import { createNotifyingErrorEffect } from 'utils/store';

// * effects
const createDocument = createEffect({
    handler: async (data: KIARA.DocumentRequest) => await API.documents.createDocument(data)
});

const getDocuments = createNotifyingErrorEffect({ handler: async () => API.documents.getDocuments() });

const getSingleDocument = createNotifyingErrorEffect({
    handler: async (id: string) => API.documents.getSingleDocument(id)
});

const deleteDocument = createNotifyingErrorEffect({
    handler: async (id: string) => await API.documents.deleteDocument(id)
});

// * stores
const documents = createStore<KIARA.DocumentResponse[]>([]);
const singleDocument = createStore<KIARA.DocumentResponse | null>(null);

documents.on(getDocuments.doneData, (_, payload) => payload);
singleDocument.on(getSingleDocument.doneData, (_, payload) => payload);

// * exports
export const documentsEffects = {
    getDocuments,
    getSingleDocument,
    createDocument,
    deleteDocument
};

export const documentsStores = {
    documents,
    singleDocument
};

import styled from 'styled-components';

export const TextInputWrap = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    justify-content: space-between;
    margin-bottom: 10px;
`;

export const DateInputWrap = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    gap: 16px;
    justify-content: space-between;
    margin-bottom: 8px;
`;

export const FooterButtonWrap = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
`;

export const PlusIconWrap = styled.div`
    width: 36px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-radius: 50%;
    background-color: var(--gray-40);
`;

export const Subtitle = styled.h3`
    font-family: 'Quicksand';
    font-style: normal;
    display: inline-block;
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
    color: var(--gray-100);
    margin-bottom: 6px;
`;

export const SaveButton = styled.div`
    width: 98px;
    height: 38px;
    display: flex;
    align-items: center;
    justify-content: center;

    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #ffffff;
    cursor: pointer;
    border-radius: 8px;
    background-color: var(--purple-60);
`;

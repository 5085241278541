import { v4 as generateId } from 'uuid';
import type { BlockState } from '../types';
import type { Fields } from 'types/builder/interface';

export const addSigningBlockRowReducer = (
    state: BlockState,
    {
        blockId,
        clause,
        component
    }: {
        blockId: string;
        clause: Fields;
        component: string;
    }
) => ({
    ...state,
    blocks: state.blocks.map(block =>
        block.id === blockId
            ? {
                  ...block,
                  groups:
                      component === 'UPFRONT SUMMARY'
                          ? [
                                ...block.groups,
                                {
                                    fields: [
                                        {
                                            ...clause
                                        },
                                        {
                                            type: 'EMPTY',
                                            id: generateId()
                                        },
                                        {
                                            type: 'EMPTY',
                                            id: generateId()
                                        }
                                    ],
                                    id: generateId()
                                }
                            ].filter(group => !group.fields.every(field => field.type === 'EMPTY'))
                          : [
                                ...block.groups,
                                {
                                    fields: [
                                        {
                                            ...clause
                                        },
                                        {
                                            type: 'EMPTY',
                                            id: generateId()
                                        }
                                    ],
                                    id: generateId()
                                }
                            ].filter(group => !group.fields.every(field => field.type === 'EMPTY'))
              }
            : block
    )
});

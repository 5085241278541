import styled, { css } from 'styled-components';
import type { Color } from 'types/styles';
import { stripeButtonBackgroundColor } from './constants';

const boxShadowMixin = css`
    box-shadow: rgba(50, 50, 93, 0.14902) 0 1px 3px, rgba(0, 0, 0, 0.0196078) 0 1px 0;
`;

const focusBoxShadowMixin = css`
    box-shadow: 0 1px 3px 0 #cfd7df;
`;

const stripeElementFocusMixin = css`
    ${boxShadowMixin};
    transition: all 150ms ease;
`;

export const IdealForm = styled.form`
    width: 30vw;

    input,
    .StripeElement {
        color: #32325d;
        border-radius: 4px;
        transition: box-shadow 150ms ease;
        display: block;
        margin: 10px 0 20px 0;
        max-width: 500px;
        padding: 10px 14px;
        font-size: 1em;
        font-family: 'Source Code Pro', monospace;
        border: 0;
        outline: 0;
        background: white;
        ${boxShadowMixin};

        .IdealBankElement,
        .FpxBankElement,
        .PaymentRequestButton {
            padding: 0;
        }

        :focus {
            ${stripeElementFocusMixin};
        }
    }

    input {
        padding: 10px 12px;

        :focus {
            ${focusBoxShadowMixin};
        }

        ::placeholder {
            color: #aab7c4;
        }
    }

    .StripeElement--focus {
        ${focusBoxShadowMixin};
    }

    label {
        color: #6b7c93;
        font-weight: 300;
        letter-spacing: 0.025em;
    }
`;

export const VisaCardForm = styled.form`
    width: 30vw;

    #card-element {
        border-radius: 4px 4px 0 0;
        padding: 12px;
        border: 1px solid rgba(50, 50, 93, 0.1);
        max-height: 44px;
        width: 100%;
        background: white;
        box-sizing: border-box;
    }

    input {
        border-radius: 6px;
        margin-bottom: 6px;
        padding: 12px;
        border: 1px solid rgba(50, 50, 93, 0.1);
        max-height: 44px;
        font-size: 16px;
        width: 100%;
        background: white;
        box-sizing: border-box;
    }
`;

export const StripeButton = styled.button`
    background: ${stripeButtonBackgroundColor};
    font-family: Arial, sans-serif;
    color: white;
    border-radius: 0 0 4px 4px;
    border: 0;
    padding: 12px 16px;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
    display: block;
    transition: all 0.2s ease;
    box-shadow: 0 4px 5.5px 0 rgba(0, 0, 0, 0.07);
    width: 100%;

    :hover {
        filter: contrast(115%);
    }

    :disabled {
        opacity: 0.5;
        cursor: default;
    }
`;

export const Span = styled.span<Color>`
    color: ${({ color }) => (color ? color : 'inherit')};
`;
export const Label = styled.label``;
export const Input = styled.input``;

import { type ChangeEvent, type Dispatch, type SetStateAction, useRef } from 'react';

import { type ReactFCC } from 'types/react';
import { type FieldItem } from '../../types';
import { MinusIconV2, PlusIcon } from 'ui-kit/assets';
import { Button, Container, Content } from '../styles';
import { Input } from 'ui-kit/components/inputs/Input';
import { isEllipsisActive } from 'utils/is-ellipsis-active';
import { type AddOrRemoveButtonProps } from '../VariationsEditor';
import { Tooltip } from 'ui-kit/components/Tooltip';

interface Props {
    items: FieldItem[];
    handleAddOrRemoveRadioOrMultiSelect: (props: AddOrRemoveButtonProps) => void;
    itemType: string;
    fieldType: string;
    setItems: Dispatch<SetStateAction<FieldItem[]>>;
}

export const SelectMultiSelectRadioEditor: ReactFCC<Props> = ({
    items,
    handleAddOrRemoveRadioOrMultiSelect,
    itemType,
    fieldType,
    setItems
}) => {
    const ref = useRef<HTMLInputElement>(null);

    const handleOnChange = (event: ChangeEvent<HTMLInputElement>, index: number) => {
        const { value } = event.target;

        const newState = items.map((item, i) => (index === i ? { ...item, text: value, value } : item));

        setItems(newState);
    };

    return (
        <Container>
            {items.map(({ id, value }, index) => (
                <Content key={id}>
                    <Input
                        isEllipsis
                        data-tooltip-id={`${itemType}_${index}_value`}
                        name={`${itemType}_${index}_value`}
                        placeholder="Enter a possible answer here"
                        reference={ref}
                        type="text"
                        value={value || ''}
                        onChange={e => handleOnChange(e, index)}
                    />
                    {ref.current && isEllipsisActive(ref.current) && (
                        <Tooltip id={`${itemType}_${index}_value`} text={value} />
                    )}
                    <Button
                        type="button"
                        onClick={() => {
                            handleAddOrRemoveRadioOrMultiSelect({
                                type: 'add',
                                index,
                                itemType: fieldType
                            });
                        }}
                    >
                        <PlusIcon height={12} width={12} />
                    </Button>
                    <Button
                        type="button"
                        onClick={() => {
                            handleAddOrRemoveRadioOrMultiSelect({
                                type: 'remove',
                                index,
                                itemType: fieldType
                            });
                        }}
                    >
                        <MinusIconV2 height={2} width={12} />
                    </Button>
                </Content>
            ))}
        </Container>
    );
};

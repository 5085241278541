import { createEvent } from 'effector';

// * events
const setIsLoading = createEvent();
const resetLoading = createEvent();

// * exports
export const loadingEvents = {
    setIsLoading,
    resetLoading
};

import { FieldTypesEnum } from 'constants/builder/enums';
import type { QuestionnaireItem } from '../types';

export const changeFieldsHelper = (question: QuestionnaireItem, value: string | string[]): QuestionnaireItem => {
    switch (question.type) {
        case FieldTypesEnum.Select:
        case FieldTypesEnum.Radio:
            return { ...question, defaultValue: value as string };
        case FieldTypesEnum.MultiSelect:
            return {
                ...question,
                value: (value as string[]).map(item => ({
                    text: item,
                    value: item,
                    type: 'Text'
                }))
            };
        default:
            return { ...question, value: value as string };
    }
};

import styled, { css } from 'styled-components';

export const Content = styled.div`
    background-color: var(--purple-40);
    padding: 24px;
    max-height: calc(100% - 77px);
    overflow-y: auto;
    padding-bottom: 100px;
`;

const iconContainerMixin = css`
    background-color: inherit;
    border: none;
    color: var(--gray-60);
    cursor: pointer;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: 4px;
    width: 24px;
    height: 24px;
    border-radius: 4px;

    :hover {
        background-color: rgba(201, 36, 0, 0.05);
    }
`;

export const IconContainer = styled.button`
    ${iconContainerMixin}
    :hover {
        color: var(--purple-60);
    }
`;

export const GoToQuestionnaireIconContainer = styled.button`
    background-color: inherit;
    border: none;
    color: var(--gray-60);
    cursor: pointer;
    display: inline-flex;
    justify-content: center;
    align-items: center;

    :hover {
        color: var(--purple-60);
    }
`;

export const IconsContainer = styled.div`
    display: inline-flex;
    gap: 16px;
`;

export const TrashIconContainer = styled.button`
    ${iconContainerMixin}
    :hover {
        color: var(--danger);
    }
`;

export const DocumentNameContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 20px;
`;

export const DocumentName = styled.p`
    cursor: pointer;
    text-decoration: underline;
`;

export const InfoIconContainer = styled.button`
    ${iconContainerMixin}
    margin-left: auto;
    :hover {
        color: var(--purple-60);
    }
`;

import { type ReactNode } from 'react';
import { TemplateIcon, DocumentIcon, DraftIcon, SettingIcon } from './static_assets';
import { DOCUMENTS_LINK, DRAFTS_LINK, SETTINGS_LINK, TEMPLATES_LINK } from 'constants/routes';

export interface LinkProps {
    text: string;
    href: string;
    icon: ReactNode;
    disabled?: boolean;
}

export const createLinks = (t: any): LinkProps[] => [
    { text: t('dashboard.aside.documents'), href: DOCUMENTS_LINK, icon: <DocumentIcon /> },
    { text: t('dashboard.aside.templates'), href: TEMPLATES_LINK, icon: <TemplateIcon /> },
    { text: t('dashboard.aside.drafts'), href: DRAFTS_LINK, icon: <DraftIcon /> },
    { text: t('dashboard.aside.settings'), href: SETTINGS_LINK, icon: <SettingIcon /> }
];

export const getActiveClassName = ({ isActive }: { isActive: boolean }) => (isActive ? 'active' : '');

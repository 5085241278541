import { dataPickerStyles } from 'ui-kit/components/DatePicker/styles';
import { createGlobalStyle, css } from 'styled-components';

const root = css`
    :root {
        --purple-100: #160135;
        --purple-80: #380782;
        --purple-60: #6807f9;
        --purple-40: #f1ecf8;
        --purple-20: #f0f1f8;
        --gray-100: #233353;
        --gray-80: #7d828e;
        --gray-60: #d0d3d9;
        --gray-40: #e9ebee;
        --gray-20: #f3f3f3;
        --orange: #ff8049;
        --danger: #c92400;
        --blue: #65a1df;
    }
`;

export const GlobalStyle = createGlobalStyle`
    * {
        box-sizing: border-box;
    }

    body,
    html,
    #root {
        height: 100%;
    }

    body {
        margin: 0;
        font-family: 'Montserrat', sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }

    html,
    body,
    div,
    span,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p,
    a,
    em,
    img,
    strong,
    ol,
    ul,
    li,
    form,
    label,
    legend,
    table,
    tbody,
    tfoot,
    thead,
    tr,
    th,
    td,
    article,
    aside,
    figure,
    figcaption,
    footer,
    header,
    nav,
    section {
        margin: 0;
        padding: 0;
        border: 0;
    }

    ol,
    ul {
        list-style: none;
    }

    a {
        text-decoration: none;
        color: inherit;
    }

    *,
    *::after,
    *::before {
        font-family: inherit;
        box-sizing: border-box;
    }

    @-moz-document url-prefix() {
        /* Disable scrollbar Firefox */
        html.scroll-hide {
            scrollbar-width: none;
        }
    }

    html.scroll-hide body {
        margin: 0; /* remove default margin */
        scrollbar-width: none; /* Also needed to disable scrollbar Firefox */
        -ms-overflow-style: none; /* Disable scrollbar IE 10+ */
        overflow-y: scroll;
    }

    html.scroll-hide body::-webkit-scrollbar {
        width: 0;
    }

    ::-webkit-scrollbar-track {
        background-color: transparent;
    }

    ::-webkit-scrollbar {
        width: 6px;
        height: 10px;
    }

    ::-webkit-scrollbar-thumb {
        background-color: var(--gray-60);
        border-radius: 4px;
    }

    ${root}
    ${dataPickerStyles};
`;

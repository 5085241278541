import { useStore } from 'effector-react';
import { useState } from 'react';

import { ButtonsWrapper } from './styles';
import { type ReactFCC } from 'types/react';
import { useTranslation } from 'react-i18next';
import { Modal } from 'ui-kit/components/Modal';
import { companyEffects } from 'stores/company';
import { companyTemplateNamingModalStore } from 'stores/modals';
import { StyledTextInput } from 'components/common/inputs/StyledInput';
import { PrimaryButton, SecondaryButton } from 'components/common/buttons/SimpleButton';

export const CompanyTemplateNamingModal: ReactFCC = () => {
    const { modal, closeModal } = companyTemplateNamingModalStore;
    const { t } = useTranslation();
    const [_, { createCompanyTemplatePayload }] = useStore(modal);
    const [name, setName] = useState('');

    const onSubmit = () => {
        if (createCompanyTemplatePayload) {
            const payload = {
                ...createCompanyTemplatePayload,
                name
            };
            companyEffects.createCompanyTemplate(payload);
        }
    };

    return (
        <Modal visible title={t('companyTemplateNamingModal.title') as string} width="350px" onClose={closeModal}>
            <StyledTextInput
                placeholder="Template Name"
                onChange={e => {
                    setName(e.target.value);
                }}
            />
            <ButtonsWrapper>
                <PrimaryButton onClick={() => closeModal()}>{t('cancel')}</PrimaryButton>
                <SecondaryButton onClick={onSubmit}>{t('submit')}</SecondaryButton>
            </ButtonsWrapper>
        </Modal>
    );
};

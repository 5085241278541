import { useStore } from 'effector-react';

import { Container } from './styles';
import { type ReactFCC } from 'types/react';
import { Modal } from 'ui-kit/components/Modal';
import { addPaymentCardModalStore } from 'stores/modals';
import { Row } from 'components/wrappers/grid/FlexWrapper';
import { StyledTextInput } from 'components/common/inputs/StyledInput';
import { PrimaryButton } from 'components/common/buttons/SimpleButton';

export const AddPaymentCardModal: ReactFCC = () => {
    const { modal, closeModal } = addPaymentCardModalStore;
    const [_, { title }] = useStore(modal);

    const handleClose = () => {
        closeModal();
    };

    return (
        <Modal visible title={title} onClose={handleClose}>
            <Container>
                <StyledTextInput placeholder="Cardholder’s name *" title="Cardholder’s name *" />
                <StyledTextInput placeholder="Card number *" title="Card number *" />
                <StyledTextInput placeholder="Expiration date *" title="Expiration date *" />
                <StyledTextInput placeholder="CVV *" title="CVV *" />
                <Row justifyEnd gap="16px">
                    <PrimaryButton>Save</PrimaryButton>
                </Row>
            </Container>
        </Modal>
    );
};
